// @mui
import {
  Box,
  Link,
  Stack,
  Button,
  AppBar,
  Toolbar,
  Container,
  Typography,
  IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';

// hooks
// utils
// routes
// config
// components

// ----------------------------------------------------------------------

export default function BannerText({label,link} : any) {
  /* eslint-disable */
  const [Open, setOpen] = useState(true)
  const isMdUp = useResponsive('up', 'lg');

  return (
    <div
    style={{
      display: Open ? 'flex' : "none",
      justifyContent: 'center',
      position: 'fixed',
      bottom: '1%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 9999,
    }}
  >
    <div
          style={{ width: isMdUp ? '1400px' : "100%", display: 'flex', justifyContent: 'flex-end', marginBottom: '4px' }}
        >
          <IconButton
            sx={{ width: '24px', height: '24px', border: '1px solid #A67BF7' }}
         
            onClick={()=>{
              setOpen(false)
              sessionStorage.setItem("bannerShow" , "false")
            }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // position: 'fixed',
          // bottom: '8%',
          width: isMdUp ? '1200px' : "98%",
          height: isMdUp ? '50px' : "62px",
          backgroundColor: '#FF0014',
          borderRadius: '8px',
          zIndex: 9999,
          boxShadow:'0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
          '&:hover': {
            bgcolor: 'error.dark',
          },
          gap: 1,
        }}
      >
          <Typography fontWeight={'700'} color={'#FFFFFF'} fontSize={isMdUp ? '20px' : "15px"} lineHeight={isMdUp ? '30px' : "15px"}>
          {label}
        </Typography>
        
        <Button variant="contained" color="inherit" size={isMdUp ? "small" : "small"} sx={{ fontSize: isMdUp ?'20px' : "13px" ,padding: isMdUp ?'18px' : "0px" }}
          onClick={()=>{
            window.open(link,"_blank")
          }}
          >
            이동하기
          </Button>
      </Box>
    </div>
  );
}
