// @mui
import styled from "styled-components";
// import { Link as RouterLink } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { Box, Link, Stack, Button, AppBar, Toolbar, Container,Typography } from '@mui/material';
// hooks

import useOffSetTop from 'src/hooks/useOffSetTop';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { bgBlur } from 'src/utils/cssStyles';
// routes
import { paths } from 'src/routes/paths';
// config
import { HEADER } from 'src/config-global';

// components
import Logo from 'src/components/logo';
import Label from 'src/components/label';
import SettingsDrawer from 'src/components/settings/drawer';
import { useMutation } from "@tanstack/react-query";
import { useNavigate,useLocation,Link as RouterLink} from "react-router-dom";
//
import {BsPerson,BsCart,BsFillCartFill} from 'react-icons/bs';
import { useEffect,useState } from 'react';
import { NavMobile, NavDesktop, navConfig } from '../nav';
import Searchbar from '../../components/Searchbar';
import HeaderShadow from '../../components/HeaderShadow';
import { SigninByEmailDto } from "../../../generated";
import { publicApi,privateApi } from "../../../react-query";

// ----------------------------------------------------------------------

type Props = {
  headerOnDark: boolean;
};
const fetcherSignin = (params: SigninByEmailDto) => publicApi.vod.usersControllerStudentSignin(params);
const fetcherMyInfo = () => privateApi.vod.wpStudentControllerWpMyData();


export default function Header({ headerOnDark }: Props) {
  /* eslint-disable */

  const [CheckLogin, setCheckLogin] = useState("Login");
  const [MyName, setMyName] = useState("NotLoggedin");

  const theme = useTheme();
  const navigate = useNavigate();
  
  const isMdUp = useResponsive('up', 'lg');
  const isXsUp = useResponsive('down', 'sm');

  const isOffset = useOffSetTop();
  const [HomeVisble, setHomeVisble] = useState(false)
  const [WorkVisble, setWorkVisble] = useState(false)
  const [CategoryVisible, setCategoryVisible] = useState(false)
  const [PostVisible, setPostVisible] = useState(false)
  const [CourseVisible, setCourseVisible] = useState(false)
  const [TeacherVisible, setTeacherVisible] = useState(false)
  const [DownVisible, setDownVisible] = useState(false)
  const [ReviewVisible, setReiviewVisible] = useState(false)
  const [HomeHeader, setHomeHeader] = useState("")
  const [WorkHeader, setWorkHeader] = useState("")
  const [CategoryHeader, setCategoryHeader] = useState("")
  const [PostHeader, setPostHeader] = useState("")
  const [CourseHeader, setCourseHeader] = useState("")
  const [TeacherHeader, setTeacherHeader] = useState("")
  const [DownHeader, setDownHeader] = useState("")
  const [ReviewHeader, setReiviewHeader] = useState("")

  useEffect(() => {
    console.log(window.location)
    setHomeVisble(sessionStorage.getItem("homeVisible") === "1" ? true :false)
    setWorkVisble(sessionStorage.getItem("workVisible") === "1" ? true :false)
    setCategoryVisible(sessionStorage.getItem("categoryVisible")=== "1" ? true :false)
    setPostVisible(sessionStorage.getItem("postVisible")=== "1" ? true :false)
    setCourseVisible(sessionStorage.getItem("courseVisible")=== "1" ? true :false)
    setTeacherVisible(sessionStorage.getItem("teacherVisible")=== "1" ? true :false)
    setDownVisible(sessionStorage.getItem("downVisible")=== "1" ? true :false)
    setReiviewVisible(sessionStorage.getItem("reiviewVisible")=== "1" ? true :false)
    setHomeHeader(sessionStorage.getItem("homeHeader") as string)
    setWorkHeader(sessionStorage.getItem("workHeader") as string)
    setCategoryHeader(sessionStorage.getItem("categoryHeader") as string)
    setPostHeader(sessionStorage.getItem("postHeader") as string)
    setCourseHeader(sessionStorage.getItem("courseHeader") as string)
    setTeacherHeader(sessionStorage.getItem("teacherHeader") as string)
    setDownHeader(sessionStorage.getItem("downHeader") as string)
    setReiviewHeader(sessionStorage.getItem("reviewHeader") as string)
    if(window.sessionStorage.getItem("x-at") !== null ){
      
      setCheckLogin("로그아웃")
    }
    else
    setCheckLogin("로그인")
  }, [])
  useEffect(() => {
    if(CheckLogin==="로그아웃"){
      mutateMyInfo()
    }
   
  }, [CheckLogin])
  
  // const { isLoading: loadingSignin, mutate: mutateSignin } = useMutation((params: SigninForTeacherDto) => fetcherSignin(params), {
  //   onSuccess: (e) => {
  //     if (e.data.header.isSuccess) {
  //       console.log(e)
  //       // const accessToken = e.data.body.data.token;
  //       // authStore.onSignin(accessToken);
  //       // window.sessionStorage.setItem("x-at", accessToken);

  //     } else {
  //       // message.error(e.data.header.resultMessage);
  //     }
  //   },
  //   onError: (e) => {
  //     console.log("login onError : ", e);
  //   },
  // });
  const {mutate: mutateMyInfo } = useMutation(() => fetcherMyInfo(), {
    onSuccess: (e) => {
      if (e.data.header.isSuccess) {
        console.log(e)
        setMyName(e.data.body.data.studentName)
        // const accessToken = e.data.body.data.token;
        // authStore.onSignin(accessToken);
        // window.sessionStorage.setItem("x-at", accessToken);

      } else {
        if(e.data.header.resultCode == 403){
        
          navigate("/auth/duplicated")
        }
        // message.error(e.data.header.resultMessage);
      }
    },
    onError: (e) => {
      console.log("login onError : ", e);
    },
  });

  return (
    <AppBar color="transparent" sx={{ boxShadow: 'none' }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP-16,
            whiteSpace:"nowrap",
            // md: 50,
           

          },
          // background : "white",
          ...bgBlur({ color: theme.palette.background.default }),
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: 'common.white',
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            color: 'text.primary',
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center',maxWidth : "1600px !important" }}>
          <Box sx={{ lineHeight: 0, position: 'relative'}}>
            <Logo />
            {/* <Link href="https://zone-docs.vercel.app/changelog" target="_blank" rel="noopener">
              <Label
                color="info"
                sx={{
                  ml: 0.5,
                  px: 0.5,
                  top: -14,
                  left: 60,
                  height: 20,
                  fontSize: 11,
                  cursor: 'pointer',
                  position: 'absolute',
                }}
              >
                v2.0
              </Label>
            </Link> */}
          </Box>

          {isMdUp && window.location.pathname === "/e-learning/landing"  &&
            <div style={{display :"flex" ,gap : 50 ,justifyContent:"flex-start" ,width :"100%", marginLeft: 70}}>
                {HomeVisble && 
      <HeaderMenu 
      role="presentation"
      onClick={(e)=>{
        const testdiv : any = document.getElementById("home")?.getBoundingClientRect().top
        const top2 = window.pageYOffset + testdiv
          window.scrollTo({
            top: top2,
            behavior: 'smooth',
         });
      }}>{HomeHeader}</HeaderMenu>

      }
                {WorkVisble && 
      <HeaderMenu 
      role="presentation"
      onClick={(e)=>{
        const testdiv : any = document.getElementById("work")?.getBoundingClientRect().top
        const top2 = window.pageYOffset + testdiv
          window.scrollTo({
            top: top2,
            behavior: 'smooth',
         });
      }}>{WorkHeader}</HeaderMenu>

      }
           {/* <HeaderMenu 
          role="presentation"
          onClick={(e)=>{
            const testdiv : any = document.getElementById("work_with")?.getBoundingClientRect().top
            const top2 = window.pageYOffset + testdiv
              window.scrollTo({
                top: top2,
                behavior: 'smooth',
             });
          }}>Work With</HeaderMenu> */}
                     {CategoryVisible && 
     <HeaderMenu 
     role="presentation"
     onClick={(e)=>{
       const testdiv : any = document.getElementById("category")?.getBoundingClientRect().top
       const top2 = window.pageYOffset + testdiv
         window.scrollTo({
           top: top2,
           behavior: 'smooth',
        });
     }}>{CategoryHeader}</HeaderMenu>

      }
                     {CourseVisible && 
    <HeaderMenu 
    role="presentation"
    onClick={(e)=>{
      const testdiv : any = document.getElementById("course")?.getBoundingClientRect().top
      const top2 = window.pageYOffset + testdiv
        window.scrollTo({
          top: top2,
          behavior: 'smooth',
       });
    }}>{CourseHeader}</HeaderMenu>

      }
                     {TeacherVisible && 
     <HeaderMenu 
     role="presentation"
     onClick={(e)=>{
       const testdiv : any = document.getElementById("teacher")?.getBoundingClientRect().top
       const top2 = window.pageYOffset + testdiv
         window.scrollTo({
           top: top2,
           behavior: 'smooth',
        });
     }}>{TeacherHeader}</HeaderMenu>

      }
                     {ReviewVisible && 
     <HeaderMenu 
     role="presentation"
     onClick={(e)=>{
       const testdiv : any = document.getElementById("review")?.getBoundingClientRect().top
       const top2 = window.pageYOffset + testdiv
         window.scrollTo({
           top: top2,
           behavior: 'smooth',
        });
     }}>{ReviewHeader}</HeaderMenu>

      }
                     {PostVisible && 
    
    <HeaderMenu 
    role="presentation"
    onClick={(e)=>{
      const testdiv : any = document.getElementById("post")?.getBoundingClientRect().top
      const top2 = window.pageYOffset + testdiv
        window.scrollTo({
          top: top2,
          behavior: 'smooth',
       });
    }}>{PostHeader}</HeaderMenu>

      }
                     {DownVisible && 
    
    <HeaderMenu 
    role="presentation"
    onClick={(e)=>{
      const testdiv : any = document.getElementById("download")?.getBoundingClientRect().top
      const top2 = window.pageYOffset + testdiv
        window.scrollTo({
          top: top2,
          behavior: 'smooth',
       });
    }}>{DownHeader}</HeaderMenu>

      }
          </div>
}
          <Stack
            spacing={1.5}
            flexGrow={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
                 {!isXsUp && (
            <Stack spacing={1} direction="row" alignItems="center" sx={CheckLogin === "로그아웃" ? {fontSize:"10px",  whiteSpace:"nowrap"} : {display :"none"}}>
             
              {window.location.pathname === "/e-commerce/account/wishlist" ?   
                <BsFillCartFill size={18}
              style={{marginRight:0}}
              onClick={()=>{
                
                  navigate("/e-commerce/account/wishlist")
          
              }}
              />
            :
            <BsCart size={18}
            style={{marginRight:0}}
            onClick={()=>{
              
                navigate("/e-commerce/account/wishlist")
        
            }}
            />
            }
          
              {/* <SettingsDrawer /> */}
            </Stack>
            )}
           
            <Stack spacing={2} direction="row" alignItems="center" sx={{fontSize:"10px",  whiteSpace:"nowrap"}}>
              {/* <Searchbar /> */}
              {/* <BsPerson size={20}
              style={{marginRight:10}}
              onClick={()=>{
                if(CheckLogin === "Login"){
                  sessionStorage.setItem("Check", "true")
                  navigate("/auth/login-cover")
                }
                else {
                  
                  navigate("/e-commerce/account/mylist")

                }
                // mutateSignin({
                //   teacherId :"lydian",
                //   password : "1234"
                // })
          
              }}
              />  */}
          
              {/* <Link  component={RouterLink} to={paths.eCommerce.account.mylist} color="inherit">
              <Typography sx={{fontSize : {md : 14 ,xs : 12} ,mt: {md : 0.5 },fontWeight : 800} }>
              {MyName === "" ? "" : `내 강의`} 
              </Typography>
              </Link> */}
               {!isXsUp ? 
              <Link  component={RouterLink} to={paths.eCommerce.account.personal} color="inherit">
              <Typography sx={{fontSize : {md : 14 ,xs : 12} ,mt: {md : 0.5 },fontWeight : 800} }>
              {/* {MyName === "" ? "" : `${MyName} 님`}  */}
              {MyName === "NotLoggedin" ? "" : `${MyName}님`} 
              </Typography>
              </Link>
              :
              ""
              }
             
              <Button variant="soft" color='warning' sx={{display: MyName === "NotLoggedin" ? "none" :"inline-block"  ,minWidth : {xs : 40 , sm : 90}}}
              onClick={()=>{
               

                  navigate("/e-commerce/account/mylist")
                
              
              }}
              >
                {/* {MyName} */}
                강의실
              </Button>
              
             
            </Stack>
        
       

            {isMdUp && (
              <Button variant="contained" color='inherit' sx={{display:"inline-block" ,minWidth : 90}}
              onClick={()=>{
                if(CheckLogin === "로그인"){
                  // console.log(window.location.pathname)
                  sessionStorage.setItem("history", window.location.pathname)
                  navigate("/auth/login-cover")
                }
                else {
                      window.sessionStorage.removeItem("x-at");
                      window.sessionStorage.removeItem("MyIdx");
                      navigate("/e-learning/landing")
                      window.location.reload()

                      // setCheckLogin("Login")

                }
                // mutateSignin({
                //   teacherId :"lydian",
                //   password : "1234"
                // })
              }}
              >
                {CheckLogin}
                {/* {window.sessionStorage.getItem("x-at") !== null? "Logout" : "Login"} */}
              </Button>
            )}
              {isMdUp && (
            <Button variant="outlined" color='inherit' sx={CheckLogin !== "로그인" ? {display :"none"}:{ whiteSpace:"nowrap"}}
            onClick={()=>{
              navigate("/auth/register-cover")
            }}
            >
              가입하기
            </Button>
              )}
          </Stack>

          {!isMdUp && <NavMobile data={MyName} />}
        </Container>
      </Toolbar>

      {isOffset && <HeaderShadow />}
    </AppBar>
  );
}

const HeaderMenu = styled.div`
 font-size : 15px;
 font-weight : 700;
 cursor: pointer;
 &:hover {
  color: #006C9C;
  // background-color: white;
  // opacity: 0.9;
}
`;