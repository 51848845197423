/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BaseResponseHeader {
  isSuccess: true | false;
  resultCode: any;
  resultMessage: string;
  timestamp: string;
}

export interface BaseResponseDto {
  header: BaseResponseHeader;
}

export interface DesignDto {
  /** @example demo-vod.learnex.kr */
  sKey?: string;
}

export interface SignupDto {
  /** 희망도메인 */
  dns: string;
  /** 회사명 */
  companyName: string;
  /** 연락가능한 연락처 */
  companyPhone1: string;
  /** 대표이메일 */
  id: string;
  /** 패스워드 */
  password: string;
  /**
   * 대표자명
   * @example 홍길동
   */
  teacherName: string;
}

export interface SigninByEmailDto {
  /** 아이디 */
  id: string;
  /** 패스워드 */
  password: string;
  /** @example test-vod.learnex.kr/admin */
  tKey?: string;
  /** @example test-vod.learnex.kr */
  sKey?: string;
}

export interface SigninResponseData {
  /**
   * Access Token
   * @example eyJhbGciOiJIUzI1NiIsInR5cCI6
   */
  token: string;
}

export interface SigninResponseBody {
  data: SigninResponseData;
}

export interface SigninResponseDto {
  header: BaseResponseHeader;
  body: SigninResponseBody;
}

export interface SigninByKakaoDto {
  /** 카카오 로그인 후, 콜백에서 받은 code 값  */
  code: string;
  /** @example demo-vod.learnex.kr/admin */
  tKey?: string;
  /** @example demo-vod.learnex.kr */
  sKey?: string;
}

export interface SigninByNaverDto {
  /** 네이버 로그인 후, 콜백에서 받은 access_token 값  */
  access_token: string;
  /** 네이버 로그인 시, 입력한 state 값  */
  state: string;
  /** @example demo-vod.learnex.kr/admin */
  tKey?: string;
  /** @example demo-vod.learnex.kr */
  sKey?: string;
}

export interface TeacherChangePasswordMailDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /** @example demo-vod.learnex.kr/admin */
  tKey: string;
}

export interface TeacherChangingPasswordDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
  /** @example demo-vod.learnex.kr/admin */
  tKey: string;
}

export interface StudentVerificationPhoneDto {
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface VerificationPhoneResponseData {
  /**
   * 6자리 난수
   * @example 000000
   */
  code: string;
}

export interface VerificationPhoneResponseBody {
  data: VerificationPhoneResponseData;
}

export interface VerificationPhoneResponseDto {
  header: BaseResponseHeader;
  body: VerificationPhoneResponseBody;
}

export interface StudentSignupDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /**
   * 전화번호2
   * @example 01012345678
   */
  studentPhone2?: string;
  /** 커스텀정보1 */
  customInfo1?: string;
  /** 커스텀정보2 */
  customInfo2?: string;
  /** 커스텀정보3 */
  customInfo3?: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface StudentCheckMailDto {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface StudentSignupSocialDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /**
   * 전화번호2
   * @example 01012345678
   */
  studentPhone2?: string;
  /** 커스텀정보1 */
  customInfo1?: string;
  /** 커스텀정보2 */
  customInfo2?: string;
  /** 커스텀정보3 */
  customInfo3?: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
  /** 소셜 타입 */
  socialType: 'naver' | 'kakao';
  /** 소셜로그인후 얻은 아이디값 */
  socialId?: string;
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface StudentChangePasswordMailDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface StudentCheckChangePasswordMailResponseData {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
}

export interface StudentCheckChangePasswordMailResponseBody {
  data: StudentCheckChangePasswordMailResponseData;
}

export interface StudentCheckChangePasswordMailResponseDto {
  header: BaseResponseHeader;
  body: StudentCheckChangePasswordMailResponseBody;
}

export interface StudentChangingPasswordDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface Actor {
  /** mbox */
  mbox: string;
  /** name */
  name: string;
}

export interface Obj {
  /** objectType */
  objectType: string;
  /** id */
  id: string;
  /** definition */
  definition: object;
}

export interface StatementDto {
  actor: Actor;
  /** verb */
  verb: string;
  object: Obj;
  /** context */
  context: object;
}

export interface BillingProductEntity {
  /** INDEX */
  billingProductIdx: number;
  /** 상품명 */
  billingProductName: string;
  /** 월가격 */
  monthlyPrice: number;
  /** 년가격 */
  yearlyPrice: number;
  /** 트랜스코딩 건당가격 */
  transcodingPrice: number;
  /** 결제 수수료율 */
  paymentFee: string;
  /** 전송량(byte) */
  maxTraffic: number;
  /** 용량(byte) */
  maxSpace: number;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
}

export interface SystemEntity {
  /** INDEX */
  systemIdx: number;
  /** constructed: 구축, subscription: 구독 */
  systemType?: 'constructed' | 'subscription';
  /** 관리자 Key */
  tKey: string;
  /** 학생페이지 Key */
  sKey: string;
  /** 학생 가입시 수동승인여부 0:안씀, 1:씀 */
  isConfirmJoinStudent: 0 | 1;
  /** 학생 joinPath에 따른 상품 할당 0:안씀, 1:씀 */
  joinpathWithVodProductName: 0 | 1;
  /** 회사명 */
  companyName: string;
  /**
   * 담당자 전화번호1
   * @example 01012345678
   */
  companyPhone1: string;
  /**
   * 담당자 이메일
   * @example lydian@lydianlabs.kr
   */
  companyEmail: string;
  /** 폴더명/콜러스채널명, 중복되지않게, 특수문자없게 */
  kollus_channel: string;
  /** 콜러스채널키 */
  kollus_channelkey: string;
  /** 워터마크여부 0:안씀, 1:씀 */
  isWatermark: 0 | 1;
  /** 센딧 접근키 */
  senditAccesskey: string;
  /** 센딧 발신번호 */
  senditSendno: string;
  /** 센딧 프로필 */
  senditProfile: string;
  /** 사용가능여부 1:가능, 0불가 */
  isPossible: 0 | 1;
  /**
   * 결제 시작일
   * @format date-time
   * @example 2023-03-01
   */
  billingStartDate: string;
  /**
   * 결제 만료일
   * @format date-time
   * @example 2023-03-31
   */
  billingEndDate?: string;
  KAKAO_RESTAPI_APPKEY?: string;
  KAKAO_STUDENT_RETURN_URI1?: string;
  KAKAO_STUDENT_RETURN_URI2?: string;
  KAKAO_ADMIN_RETURN_URI1?: string;
  KAKAO_ADMIN_RETURN_URI2?: string;
  NAVER_CLIENT_ID?: string;
  NAVER_STUDENT_RETURN_URI1?: string;
  NAVER_STUDENT_RETURN_URI2?: string;
  NAVER_ADMIN_RETURN_URI1?: string;
  NAVER_ADMIN_RETURN_URI2?: string;
  LARAHV_URI?: string;
  PAYPLE_CST_ID?: string;
  PAYPLE_CUST_KEY?: string;
  PAYPLE_PCD_HTTP_REFERER?: string;
  PAYPLE_URL?: string;
  PAYPLE_REFUND_KEY?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  billingProductInformation: BillingProductEntity;
}

export interface TeachersData {
  /** INDEX */
  teacherIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  teacherName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  teacherPhone1: string;
  /**
   * 강사 성별(m:남자,f:여자)
   * @example m
   */
  teacherSex: 'm' | 'f';
  /** 강사 나이 */
  teacherAge?: number;
  /** 강사사진위치 */
  teacherPhotoFilePath?: string;
  /**
   * 강사상태 0:대기,8:정지, 9:퇴사
   * @example 0
   */
  teacherStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 강사권한 0:일반강사(소속수업만 보임), 9:관리자(모든센터)
   * @example 0
   */
  teacherRole?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 간단한 소개(text editor) */
  teacherComment: string;
  /** 자세한 소개(text editor) */
  teacherComment2?: string;
  /** 자격증(text editor) */
  teacherCertification?: string;
  /** 학력(text editor) */
  teacherDegree?: string;
  /** 경력(text editor) */
  teacherExperience: string;
  /** 카카오로그인아이디 */
  kakaoId?: string;
  /** 네이버로그인아이디 */
  naverId?: string;
  /**
   * 강사분류 0:vod, 1:live
   * @example 0
   */
  teacherType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 음성/화상 5분당 원가 */
  teacherLivePrice?: number;
  /** 음성/화상 5분당 판매가 */
  teacherLivePriceForStudent?: number;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** 로그인키 */
  teacherSignature?: string;
  systemInformation: SystemEntity;
}

export interface TeachersBody {
  total: number;
  data: TeachersData[];
}

export interface TeachersResponseDto {
  header: BaseResponseHeader;
  body: TeachersBody;
}

export interface TeacherPhotoFileUploadDto {
  /** @format binary */
  teacherPhotoFile?: File;
}

export interface TeacherFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface TeacherFileUploadResponseBody {
  data: TeacherFileUploadData;
}

export interface TeacherFileUploadResponseDto {
  header: BaseResponseHeader;
  body: TeacherFileUploadResponseBody;
}

export interface TeacherEvaluationStudentData {
  /** INDEX */
  studentIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
}

export interface TeacherEvaluationTeacherData {
  /** INDEX */
  teacherIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  teacherName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  teacherPhone1: string;
  /**
   * 강사 성별(m:남자,f:여자)
   * @example m
   */
  teacherSex: 'm' | 'f';
  /** 강사 나이 */
  teacherAge?: number;
  /** 강사사진위치 */
  teacherPhotoFilePath?: string;
}

export interface TeacherEvaluationData {
  /** INDEX */
  teacherEvaluationIdx: number;
  /**
   * 강사 평가 스코어
   * @example 5
   */
  teacherEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** 강사 평가(선택형) */
  teacherEvaluationTitle: string;
  /** 강사 평가(입력형) */
  teacherEvaluationComment: string;
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  studentInformation: TeacherEvaluationStudentData;
  teacherInformation: TeacherEvaluationTeacherData;
}

export interface TeacherEvaluationBody {
  total: number;
  data: TeacherEvaluationData;
}

export interface TeacherEvaluationResponseDto {
  header: BaseResponseHeader;
  body: TeacherEvaluationBody;
}

export interface UpdateTeacherEvaluationAnsDto {
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /** 어드민 답변 메시지 */
  answeredFromAdmin?: string;
}

export interface CreateTeacherCategoryDto {
  /** 카테고리명 */
  teacherCategoryName: string;
}

export interface UpdateTeacherCategoryDto {
  /** 카테고리명 */
  teacherCategoryName: string;
}

export interface BreakTimeList {
  /**
   * 휴식요일
   * @example all:전체, 0:일,1:월~6:토
   */
  teacherBreakTimeWeek: string;
  /**
   * 휴식 시간
   * @example all:전체, 11:00:00
   */
  teacherBreakTime: string;
}

export interface CreateTeacherBreaktimeDto {
  /** 강사 index */
  teacherIdx: number;
  breakTimeList: BreakTimeList[];
}

export interface CreateTeacherDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  teacherName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  teacherPhone1: string;
  /**
   * 강사 성별(m:남자,f:여자)
   * @example m
   */
  teacherSex: 'm' | 'f';
  /** 강사 나이 */
  teacherAge?: number;
  /** 강사사진위치 */
  teacherPhotoFilePath?: string;
  /**
   * 강사권한 0:일반강사(소속수업만 보임), 9:관리자(모든센터)
   * @example 0
   */
  teacherRole?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 간단한 소개(text editor) */
  teacherComment: string;
  /** 자세한 소개(text editor) */
  teacherComment2?: string;
  /** 자격증(text editor) */
  teacherCertification?: string;
  /** 학력(text editor) */
  teacherDegree?: string;
  /** 경력(text editor) */
  teacherExperience: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
  teacherCategoryIndexes?: string;
}

export interface TeacherData {
  /** INDEX */
  teacherIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  teacherName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  teacherPhone1: string;
  /**
   * 강사 성별(m:남자,f:여자)
   * @example m
   */
  teacherSex: 'm' | 'f';
  /** 강사 나이 */
  teacherAge?: number;
  /** 강사사진위치 */
  teacherPhotoFilePath?: string;
  /**
   * 강사상태 0:대기,8:정지, 9:퇴사
   * @example 0
   */
  teacherStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 강사권한 0:일반강사(소속수업만 보임), 9:관리자(모든센터)
   * @example 0
   */
  teacherRole?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 간단한 소개(text editor) */
  teacherComment: string;
  /** 자세한 소개(text editor) */
  teacherComment2?: string;
  /** 자격증(text editor) */
  teacherCertification?: string;
  /** 학력(text editor) */
  teacherDegree?: string;
  /** 경력(text editor) */
  teacherExperience: string;
  /** 카카오로그인아이디 */
  kakaoId?: string;
  /** 네이버로그인아이디 */
  naverId?: string;
  /**
   * 강사분류 0:vod, 1:live
   * @example 0
   */
  teacherType?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 음성/화상 5분당 원가 */
  teacherLivePrice?: number;
  /** 음성/화상 5분당 판매가 */
  teacherLivePriceForStudent?: number;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** 로그인키 */
  teacherSignature?: string;
  systemInformation: SystemEntity;
}

export interface TeacherBody {
  total: number;
  data: TeacherData[];
}

export interface TeacherResponseDto {
  header: BaseResponseHeader;
  body: TeacherBody;
}

export interface UpdateTeacherDto {
  /**
   * 강사명
   * @example 홍길동
   */
  teacherName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  teacherPhone1: string;
  /**
   * 강사 성별(m:남자,f:여자)
   * @example m
   */
  teacherSex: 'm' | 'f';
  /** 강사 나이 */
  teacherAge?: number;
  /** 강사사진위치 */
  teacherPhotoFilePath?: string;
  /**
   * 강사상태 0:대기,8:정지, 9:퇴사
   * @example 0
   */
  teacherStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 강사권한 0:일반강사(소속수업만 보임), 9:관리자(모든센터)
   * @example 0
   */
  teacherRole?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 간단한 소개(text editor) */
  teacherComment: string;
  /** 자세한 소개(text editor) */
  teacherComment2?: string;
  /** 자격증(text editor) */
  teacherCertification?: string;
  /** 학력(text editor) */
  teacherDegree?: string;
  /** 경력(text editor) */
  teacherExperience: string;
  /**
   * 패스워드
   * @example 1234
   */
  password?: string;
  teacherCategoryIndexes?: string;
}

export interface CreateStudentEvaluationDto {
  /**
   * 평가 스코어
   * @example 5
   */
  studentEvaluationScore: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 강사 평가(선택형) */
  studentEvaluationTitle: string;
  /** 학생에 대한 평가 */
  studentEvaluationComment?: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
  /** 라이브 수업일때 보낸다. */
  vodScheduleIdx: number;
}

export interface UpdateStudentEvaluationDto {
  /**
   * 평가 스코어
   * @example 5
   */
  studentEvaluationScore: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 강사 평가(선택형) */
  studentEvaluationTitle: string;
  /** 학생에 대한 평가 */
  studentEvaluationComment?: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
  /** 어드민 답변 메시지 */
  answeredFromAdmin?: string;
  /** 학생 평가 INDEX */
  studentEvaluationIdx: number;
}

export interface TestFileUploadDto {
  /** @format binary */
  testFile?: File;
}

export interface StudentPhotoFileUploadDto {
  /** @format binary */
  studentPhotoFile?: File;
}

export interface StudentFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface StudentFileUploadResponseBody {
  data: StudentFileUploadData;
}

export interface StudentFileUploadResponseDto {
  header: BaseResponseHeader;
  body: StudentFileUploadResponseBody;
}

export interface CreateStudentDto {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /**
   * 전화번호2
   * @example 01012345678
   */
  studentPhone2?: string;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
  /** 커스텀정보1 */
  customInfo1?: string;
  /** 커스텀정보2 */
  customInfo2?: string;
  /** 커스텀정보3 */
  customInfo3?: string;
  /** 메모 */
  studentMemo?: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
  /**
   * 패스워드
   * @example 1234
   */
  password: string;
}

export interface StudentsData {
  /** INDEX */
  studentIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /** 전화번호1 학습진도율 수신여부 0:안받음,1:받음 */
  isSendProgressRate1?: 0 | 1;
  /**
   * 전화번호2
   * @example 01012345678
   */
  studentPhone2?: string;
  /** 전화번호1 학습진도율 수신여부 0:안받음,1:받음 */
  isSendProgressRate2?: 0 | 1;
  /** 전화번호 광고수신여부 0:안받음,1:받음 */
  studentPhoneAd?: 0 | 1;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
  /** 커스텀정보1 */
  customInfo1?: string;
  /** 커스텀정보2 */
  customInfo2?: string;
  /** 커스텀정보3 */
  customInfo3?: string;
  /**
   * 회원상태 0:대기,8:정지,9:탈퇴
   * @example 1
   */
  studentStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 탈퇴사유 */
  deleteReason?: string;
  /** 메모 */
  studentMemo?: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
  /** 카카오로그인아이디 */
  kakaoId?: string;
  /** 네이버로그인아이디 */
  naverId?: string;
  /** 패스워드 변경 키 */
  passwordChangeKey?: string;
  /** 패스워드 변경 키 만료시간 */
  passwordChangeKeyExpiryDate?: string;
  /** 로그인키 */
  studentSignature?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
  /** 소켓아이디 */
  socketId?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  systemInformation: SystemEntity;
}

export interface StudentsBody {
  total: number;
  data: StudentsData[];
}

export interface StudentsResponseDto {
  header: BaseResponseHeader;
  body: StudentsBody;
}

export interface OneStudentsData {
  /** INDEX */
  studentIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /** 전화번호1 학습진도율 수신여부 0:안받음,1:받음 */
  isSendProgressRate1?: 0 | 1;
  /**
   * 전화번호2
   * @example 01012345678
   */
  studentPhone2?: string;
  /** 전화번호1 학습진도율 수신여부 0:안받음,1:받음 */
  isSendProgressRate2?: 0 | 1;
  /** 전화번호 광고수신여부 0:안받음,1:받음 */
  studentPhoneAd?: 0 | 1;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
  /** 커스텀정보1 */
  customInfo1?: string;
  /** 커스텀정보2 */
  customInfo2?: string;
  /** 커스텀정보3 */
  customInfo3?: string;
  /**
   * 회원상태 0:대기,8:정지,9:탈퇴
   * @example 1
   */
  studentStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 탈퇴사유 */
  deleteReason?: string;
  /** 메모 */
  studentMemo?: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
  /** 카카오로그인아이디 */
  kakaoId?: string;
  /** 네이버로그인아이디 */
  naverId?: string;
  /** 패스워드 변경 키 */
  passwordChangeKey?: string;
  /** 패스워드 변경 키 만료시간 */
  passwordChangeKeyExpiryDate?: string;
  /** 로그인키 */
  studentSignature?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
  /** 소켓아이디 */
  socketId?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  systemInformation: SystemEntity;
}

export interface OneStudentBody {
  total: number;
  data: OneStudentsData[];
}

export interface OneStudentResponseDto {
  header: BaseResponseHeader;
  body: OneStudentBody;
}

export interface UpdateStudentDto {
  /**
   * 패스워드
   * @example 1234
   */
  password?: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /** 전화번호1 학습진도율 수신여부 0:안받음,1:받음 */
  isSendProgressRate1?: 0 | 1;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone2?: string;
  /** 전화번호1 학습진도율 수신여부 0:안받음,1:받음 */
  isSendProgressRate2?: 0 | 1;
  /** 커스텀정보1 */
  customInfo1?: string;
  /** 커스텀정보2 */
  customInfo2?: string;
  /** 커스텀정보3 */
  customInfo3?: string;
  /** 전화번호 광고수신여부 0:안받음,1:받음 */
  studentPhoneAd?: 0 | 1;
  /**
   * 회원상태 0:대기,8:정지,9:탈퇴
   * @example 1
   */
  studentStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 메모 */
  studentMemo?: string;
  /** 가입경로 */
  joinPath?: string;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
}

export type KollusCallbackUploadDto = object;

export type KollusCallbackDto = object;

export type VodUploadUrlResult = object;

export interface VodUploadUrlBody {
  data: VodUploadUrlResult;
}

export interface VodUploadUrlResponseDto {
  header: BaseResponseHeader;
  body: VodUploadUrlBody;
}

export interface AttendeeList {
  /** 학생명 */
  attendeeName?: string;
  /** 학생번호 */
  attendeePhone?: string;
}

export interface CreateRoomDto {
  /**
   * 라이브 1:라이브,2:하이플렉스
   * @example 0
   */
  isLive: 1 | 2;
  /**
   * 플래쉬 0:아님,1:맞음
   * @example 0
   */
  isFlash: 0 | 1;
  /** 플래쉬:입력값_HF_202402110915 이렇게 보내줄것. , 예정수업은 리턴값 그대로 */
  title: string;
  /** 녹화후 수업VOD자동등록 0:비활성,1:활성 */
  hyflexRecordingToKollus: 0 | 1;
  /** 암호화 업로드 여부 0: false, 1: true */
  is_encryption_upload: '0' | '1';
  /** 강사명 */
  moderatorName: string;
  attendeeList: AttendeeList[];
  /** 고유방제(플래쉬:보내지말것) */
  createmeetingID?: string;
  /** 최초입장시 강의교제 URL */
  initDocsUrl?: string;
  /** 방종료후 이동되는 URL */
  logoutURL: string;
  /**
   * 녹화자동시작
   * @example true
   */
  autoStartRecording: string;
  /**
   * 음소거 입장
   * @example true
   */
  muteOnStart: string;
  /**
   * 참가자간 목록을 확인할 수 없음
   * @example true
   */
  lockSettingsHideUserList: string;
  /**
   * ALWAYS_ACCEPT:허용,ASK_MODERATOR: 노크
   * @example ALWAYS_ACCEPT
   */
  guestPolicy: string;
  /**
   * 중재자만 웹캡을 볼수 있음.
   * @example true
   */
  webcamsOnlyForModerator: string;
  /** 수업시간(안보내면 65분) */
  duration?: string;
}

export interface GetLarahvContentDto {
  /**
   * 검색 시작일
   * @example YYYY-MM-DD
   */
  startDate: string;
  /**
   * 검색 종료일
   * @example YYYY-MM-DD
   */
  endDate: string;
  /** 스케쥴 INDEX */
  vodScheduleIdx?: number;
}

export interface LarahvRecordingFile {
  /** 레코딩 URL */
  url: string;
  /** 레코딩 시간 */
  processingTime: number;
  /** 레코딩 파일사이즈 */
  size: number;
}

export interface LarahvRecordingMetadata {
  moderatorname: string;
  attendeename: string;
  attendeephone: string;
}

export interface LarahvRecordingData {
  /** 인덱스 */
  recordID: string;
  /** 학생별 고유 방제 */
  meetingID: string;
  /** 시작시각 */
  startTime: string;
  /** 종료시각 */
  endTime: string;
  /** 참가자수 */
  participants: number;
  playback: LarahvRecordingFile;
  metadata: LarahvRecordingMetadata;
}

export interface LarahvRecordResponseBody {
  total: number;
  data: LarahvRecordingData[];
}

export interface LarahvRecordResponseDto {
  header: BaseResponseHeader;
  body: LarahvRecordResponseBody;
}

export interface LarahvToKollusDto {
  /** 입력한 제목을 컨텐츠의 제목으로 강제지정합니다. 이 값을 보내지 않거나 빈 값으로 보내면 기본적으로 파일명이 제목으로 사용됩니다. */
  title: string;
  /** 암호화 업로드 여부 0: false, 1: true */
  is_encryption_upload: '0' | '1';
  /** larahv recordID */
  recordID: string;
}

export interface VodKollusContentData {
  /** INDEX */
  vodHistoryFileIdx: number;
  /** 원본파일명 */
  original_file_name: string;
  /** kollus is_encryption_upload */
  kollus_is_encryption_upload?: string;
  /** kollus mckey */
  kollus_mckey: string;
  /** kollus upload_file_key */
  kollus_ufkey: string;
  /** kollus 미디어 사이즈(PC) */
  kollus_file_size_pc: string;
  /** kollus 미디어 사이즈(태블릿) */
  kollus_file_size_tab: string;
  /** kollus 미디어 사이즈(모바일) */
  kollus_file_size_mob: string;
  /** kollus 미디어 길이 */
  kollus_duration: string;
  /** 트랜스코딩 여부: 0:실패, 1:성공 */
  isTranscoded?: 0 | 1;
}

export interface VodKollusContentBody {
  total: number;
  data: VodKollusContentData[];
}

export interface VodKollusContentResponseDto {
  header: BaseResponseHeader;
  body: VodKollusContentBody;
}

export interface DeleteKollusContentDto {
  /** vodHistoryFileIdexes: 1,2,3,4, */
  vodHistoryFileIdexes: string;
}

export interface CategoryFileUploadDto {
  /** @format binary */
  categoryPhotoFile?: File;
}

export interface CategoryFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface CategoryFileUploadResponseBody {
  data: CategoryFileUploadData;
}

export interface CategoryFileUploadResponseDto {
  header: BaseResponseHeader;
  body: CategoryFileUploadResponseBody;
}

export interface CreateReferenceDto {
  /** 자료명 */
  vodProductFileName: string;
  /** 교재,자료,과제 택1 */
  vodProductFileCategory?: string;
  /** 자료 */
  vodProductFilePath: string;
}

export interface ReadReferenceData {
  /** vodProductFile IDX */
  vodProductFileIdx: number;
  /** 자료명 */
  vodProductFileName: string;
  /** 자료 */
  vodProductFilePath: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
}

export interface ReadReferenceBody {
  total: number;
  data: ReadReferenceData[];
}

export interface ReadReferenceResponseDto {
  header: BaseResponseHeader;
  body: ReadReferenceBody;
}

export interface UpdateReferenceConnection1Dto {
  /** vodProductFileIdexes: 1,2,3,4, */
  vodProductFileIdexes: string;
  /** vodContentIdx */
  vodContentIdx: string;
}

export interface UpdateReferenceConnection2Dto {
  /** vodProductFileIdexes: 1,2,3,4, */
  vodProductFileIdexes: string;
  /** vodCategoryCodeL2 */
  vodCategoryCode: string;
}

export interface UpdateReferenceConnection3Dto {
  /** vodProductFileIdexes: 1,2,3,4, */
  vodProductFileIdexes: string;
  /** vodContentIdx */
  vodContentIdx?: string;
  /** vodCategoryCodeL2 */
  vodCategoryCode: boolean;
}

export interface DeleteReferenceDto {
  /** vodProductFileIdexes: 1,2,3,4, */
  vodProductFileIdexes: string;
}

export interface CreateVodCategoryDto {
  /** VOD프로그램 (1단: 강좌명, 2단계: 챕터명) */
  vodCategoryName: string;
  /**
   * 챕터인 경우에만 사용 1일경우 LIVE
   * @example 0
   */
  isLive?: 0 | 1;
  /** 라이브선택시: 회차 */
  liveCount?: number;
  /** 라이브선택시: 요일(0:일~6:토) */
  liveWeek?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDuration?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimit?: string;
  /**
   * 1:10분전,3:30분전,6:1시간전,18:3시간전,36:6시간전,72:12시간전,144:24시간전
   * @example 1:3
   */
  notification?: string;
  /** 카테고리 레벨 */
  categoryLevel: 1 | 2;
  /** 카테고리 코드 */
  categoryCode?: string;
  /** 강사 or 강사풀 */
  teacherIdx?: number;
  /** 강사 or 강사풀 */
  teacherCategoryIdx?: number;
}

export interface VodCategoryL1Data {
  /**
   * VOD프로그램 카테고리 코드(자동생성)
   * @example 000000001
   */
  vodCategoryCode: string;
  /** VOD프로그램 (1단: 강좌명, 2단계: 챕터명) */
  vodCategoryName: string;
  /** 총 컨텐츠수 */
  mediaCount: string;
  /** 총 재생시간 */
  mediaDuration: string;
  /** 총 사이즈(PC) */
  mediaBytePc: string;
  /** 총 사이즈(Tab) */
  mediaByteTab: string;
  /** 총 사이즈(Mob) */
  mediaByteMob: string;
  /** 강의자료명 */
  vodProductFileName: string;
  /** 강의자료경로 */
  vodProductFilePath: string;
}

export interface VodCategoryL1Body {
  total: number;
  data: VodCategoryL1Data[];
}

export interface VodCategoryL1ResponseDto {
  header: BaseResponseHeader;
  body: VodCategoryL1Body;
}

export interface ReferenceData {
  /** 자료명 */
  vodProductFileName: string;
  /** 자료 */
  vodProductFilePath: string;
}

export interface VodCategoryData {
  /**
   * VOD프로그램 카테고리 코드(자동생성)
   * @example 000000001
   */
  vodCategoryCode: string;
  /** VOD프로그램 (1단: 강좌명, 2단계: 챕터명) */
  vodCategoryName: string;
  vodProductFileInformation: ReferenceData;
}

export interface VodCategoryBody {
  total: number;
  data: VodCategoryData[];
}

export interface VodCategoryResponseDto {
  header: BaseResponseHeader;
  body: VodCategoryBody;
}

export interface UpdateVodCategoryDto {
  /** VOD프로그램 (1단: 강좌명, 2단계: 챕터명) */
  vodCategoryName: string;
  /**
   * 챕터인 경우에만 사용 1일경우 LIVE
   * @example 0
   */
  isLive?: 0 | 1;
  /** 라이브선택시: 회차 */
  liveCount?: number;
  /** 라이브선택시: 요일(0:일~6:토) */
  liveWeek?: string;
  /** 라이브선택시: 회당강의분수 */
  liveDuration?: string;
  /** 그룹수업제한인원: 1: 1:1, 2: 1:2 */
  liveLimit?: string;
  /**
   * 1:10분전,3:30분전,6:1시간전,18:3시간전,36:6시간전,72:12시간전,144:24시간전
   * @example 1:3
   */
  notification?: string;
  /** 강사 or 강사풀 */
  teacherIdx?: number;
  /** 강사 or 강사풀 */
  teacherCategoryIdx?: number;
}

export interface CreateVodContentDto {
  /** 강의명 */
  vodContentTitle: string;
  /**
   * 수업타입
   * @example VOD
   */
  vodContentType?: 'VOD' | 'HyFlex' | 'TEST';
  /**
   * 수업공개일(하이플렉스일 경우 수업예정일)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  releaseDate?: string;
  /**
   * 출석인정마감일(이후 지각)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  attendanceDeadline?: string;
  /**
   * 지각인정마감일(이후 결석)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  tardyDeadline?: string;
  /**
   * 수업 시작 시간
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  hyflexStartDate?: string;
  /**
   * 녹화기능 1:활성,0:비활성
   * @example 1
   */
  hyflexRecording?: 0 | 1;
  /**
   * 시작시 음소거 1:활성,0:비활성
   * @example 1
   */
  hyflexMuteStart?: 0 | 1;
  /**
   * 강사만 접속자목록확인 1:활성,0:비활성
   * @example 1
   */
  hyflexHideUser?: 0 | 1;
  /**
   * 강사만 접속자웹캠확인 1:활성,0:비활성
   * @example 1
   */
  hyflexHideWebcam?: 0 | 1;
  /**
   * 0:그냥접속,1:강사에 노크
   * @example 1
   */
  hyflexGuestPolicy?: 0 | 1;
  /**
   * 녹화후 수업VOD자동등록 0:비활성,1:활성
   * @example 0
   */
  hyflexRecordingToKollus?: 0 | 1;
  /**
   * 콜러스 암호화 업로드 0:안됨,1:됨
   * @example 0
   */
  hyflexRecordingToKollusEncryption?: 0 | 1;
  /** 파일 INDEX */
  vodHistoryFileIdx?: number;
  /** 카테고리 코드 */
  categoryCode: string;
  /** 블록여부 0:넌블락/1:블락 (상품 할당 및 구매시 block상태에 따라 스케쥴이 할당됨. 단! 실제 영상을 시청하는데는 스케쥴 하나의 block상태만 따짐) */
  isContentBlock?: 0 | 1;
  /** 시험지 INDEX */
  questionsIdx?: number;
}

export interface UpdateNumberingDto {
  /** vodContentIdexes: 1,2,3,4,5 */
  vodContentIdexes?: string;
}

export interface UpdateBlockingDto {
  /** vodContentIdx */
  vodContentIdx: number;
  /** 블록여부 0:넌블락/1:블락 (상품 할당 및 구매시 block상태에 따라 스케쥴이 할당됨. 단! 실제 영상을 시청하는데는 스케쥴 하나의 block상태만 따짐) */
  isContentBlock: 0 | 1;
}

export interface VodContentData {
  /** INDEX */
  vodContentIdx: number;
  /** 강의명 */
  vodContentTitle: string;
  /**
   * 상태 0:활성,9:비활성
   * @example 0
   */
  vodContentStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 블록여부 0:넌블락/1:블락 (상품 할당 및 구매시 block상태에 따라 스케쥴이 할당됨. 단! 실제 영상을 시청하는데는 스케쥴 하나의 block상태만 따짐) */
  isContentBlock?: 0 | 1;
  /**
   * 수업타입
   * @example VOD
   */
  vodContentType?: 'VOD' | 'HyFlex' | 'TEST';
  /**
   * 수업공개일(하이플렉스일 경우 수업예정일)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  releaseDate?: string;
  /**
   * 출석완료인정기준
   * @example 80
   */
  attendanceRate?: string;
  /** 출석데드라인 사용여부 0:미사용, 1사용 */
  isDeadline?: 0 | 1;
  /**
   * 출석인정마감일(이후 지각)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  attendanceDeadline?: string;
  /**
   * 지각인정마감일(이후 결석)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  tardyDeadline?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /**
   * 수업 시작 시간
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  hyflexStartDate?: string;
  /**
   * 녹화기능 1:활성,0:비활성
   * @example 1
   */
  hyflexRecording?: 0 | 1;
  /**
   * 시작시 음소거 1:활성,0:비활성
   * @example 1
   */
  hyflexMuteStart?: 0 | 1;
  /**
   * 강사만 접속자목록확인 1:활성,0:비활성
   * @example 1
   */
  hyflexHideUser?: 0 | 1;
  /**
   * 강사만 접속자웹캠확인 1:활성,0:비활성
   * @example 1
   */
  hyflexHideWebcam?: 0 | 1;
  /**
   * 0:그냥접속,1:강사에 노크
   * @example 1
   */
  hyflexGuestPolicy?: 0 | 1;
  /**
   * 녹화후 수업VOD자동등록 0:비활성,1:활성
   * @example 0
   */
  hyflexRecordingToKollus?: 0 | 1;
  /**
   * 콜러스 암호화 업로드 0:안됨,1:됨
   * @example 0
   */
  hyflexRecordingToKollusEncryption?: 0 | 1;
  vodCategoryL1Information: VodCategoryData;
  vodCategoryL2Information: VodCategoryData;
  vodHistoryFileInformation: VodKollusContentData;
}

export interface VodContentBody {
  total: number;
  data: VodContentData[];
}

export interface VodContentResponseDto {
  header: BaseResponseHeader;
  body: VodContentBody;
}

export interface VodMediaPlayingUrlBody {
  /** 미디어재생URL */
  data: string;
}

export interface VodMediaPlayingUrlResponseDto {
  header: BaseResponseHeader;
  body: VodMediaPlayingUrlBody;
}

export interface UpdateVodContentDto {
  /** 강의명 */
  vodContentTitle: string;
  /** 수업분수 */
  vodContentDuration?: number;
  /**
   * 상태 0:활성,9:비활성
   * @example 0
   */
  vodContentStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 수업타입
   * @example VOD
   */
  vodContentType?: 'VOD' | 'HyFlex' | 'TEST';
  /**
   * 수업공개일(하이플렉스일 경우 수업예정일)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  releaseDate?: string;
  /**
   * 출석완료인정기준
   * @example 80
   */
  attendanceRate?: string;
  /** 출석데드라인 사용여부 0:미사용, 1사용 */
  isDeadline?: 0 | 1;
  /**
   * 출석인정마감일(이후 지각)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  attendanceDeadline?: string;
  /**
   * 지각인정마감일(이후 결석)
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  tardyDeadline?: string;
  /**
   * 수업 시작 시간
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  hyflexStartDate?: string;
  /**
   * 녹화기능 1:활성,0:비활성
   * @example 1
   */
  hyflexRecording?: 0 | 1;
  /**
   * 시작시 음소거 1:활성,0:비활성
   * @example 1
   */
  hyflexMuteStart?: 0 | 1;
  /**
   * 강사만 접속자목록확인 1:활성,0:비활성
   * @example 1
   */
  hyflexHideUser?: 0 | 1;
  /**
   * 강사만 접속자웹캠확인 1:활성,0:비활성
   * @example 1
   */
  hyflexHideWebcam?: 0 | 1;
  /**
   * 0:그냥접속,1:강사에 노크
   * @example 1
   */
  hyflexGuestPolicy?: 0 | 1;
  /**
   * 녹화후 수업VOD자동등록 0:비활성,1:활성
   * @example 0
   */
  hyflexRecordingToKollus?: 0 | 1;
  /**
   * 콜러스 암호화 업로드 0:안됨,1:됨
   * @example 0
   */
  hyflexRecordingToKollusEncryption?: 0 | 1;
  /**
   * 0:시험결과 비공개, 0:시험결과 공개
   * @example 0
   */
  isQuestionsResultPublic?: 0 | 1;
  /**
   * 0:재시험 불가, 0:재시험 가능
   * @example 0
   */
  isRetryQuestions?: 0 | 1;
  /**
   * 0:힌트 미사용, 0:힌트 사용
   * @example 0
   */
  useQuestionHint?: 0 | 1;
  /**
   * 0:해설 미사용, 0:해설 사용
   * @example 0
   */
  useQuestionAnswerDesc?: 0 | 1;
  /** 파일 INDEX */
  vodHistoryFileIdx?: number;
  /** 카테고리 코드 */
  categoryCode: string;
  /** 시험지 INDEX */
  questionsIdx?: number;
}

export interface CreateVodProductCategoryDto {
  /** VOD상품 카테고리 */
  vodProductCategory: string;
  /** VOD상품 카테고리 사진 */
  vodProductCategoryPhotoFilePath?: string;
}

export interface VodProductPhotoFileUploadDto {
  /** @format binary */
  vodProductPhotoFile?: File;
}

export interface VodProductFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface VodProductFileUploadResponseBody {
  data: VodProductFileUploadData;
}

export interface VodProductFileUploadResponseDto {
  header: BaseResponseHeader;
  body: VodProductFileUploadResponseBody;
}

export interface CreateVodProductDto {
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /**
   * 수강기간: 0:무제한,1개월 ...
   * @example 1
   */
  vodProductPeriod:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /**
   * 타이머 상태 0:비활성,1:활성
   * @example 0
   */
  isVodProductTimer?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 타이머
   * @format date-time
   * @example 2023-03-01
   */
  vodProductTimer?: string;
  /** 강사 INDEX */
  teacherIdx: number;
  /** 카테고리 코드 */
  vodCategoryCodeL1?: string;
  /** 상품카테고리IDX */
  vodProductCategoryIdx: number;
}

export interface VodProductsTeacher {
  /** INDEX */
  teacherIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /**
   * 강사명
   * @example 홍길동
   */
  teacherName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  teacherPhone1: string;
  /**
   * 강사 성별(m:남자,f:여자)
   * @example m
   */
  teacherSex: 'm' | 'f';
  /** 강사 나이 */
  teacherAge?: number;
  /** 강사사진위치 */
  teacherPhotoFilePath?: string;
}

export interface VodProductsCategory {
  /** vodProductCategory IDX */
  vodProductCategoryIdx: number;
  /** VOD상품 카테고리 */
  vodProductCategory: string;
  /** VOD상품 카테고리 사진 */
  vodProductCategoryPhotoFilePath?: string;
}

export interface VodProductsData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /**
   * 수강기간: 0:무제한,1개월 ...
   * @example 1
   */
  vodProductPeriod:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  teacherInformation: VodProductsTeacher;
  vodCategoryL1Information: VodCategoryData;
  vodProductCategoryInformation: VodProductsCategory;
}

export interface VodProductsBody {
  total: number;
  data: VodProductsData[];
}

export interface VodProductsResponseDto {
  header: BaseResponseHeader;
  body: VodProductsBody;
}

export interface VodProductData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /**
   * 수강기간: 0:무제한,1개월 ...
   * @example 1
   */
  vodProductPeriod:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  teacherInformation: VodProductsTeacher;
  vodCategoryL1Information: VodCategoryData;
  vodProductCategoryInformation: VodProductsCategory;
  contents: VodContentData[];
}

export interface VodProductBody {
  total: number;
  data: VodProductData[];
}

export interface VodProductResponseDto {
  header: BaseResponseHeader;
  body: VodProductBody;
}

export interface UpdateVodProductDto {
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /**
   * 수강기간: 0:무제한,1개월 ...
   * @example 1
   */
  vodProductPeriod:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 타이머 상태 0:비활성,1:활성
   * @example 0
   */
  isVodProductTimer?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 타이머
   * @format date-time
   * @example 2023-03-01
   */
  vodProductTimer?: string;
  /** 카테고리 코드 */
  vodCategoryCodeL1?: string;
  /** 상품카테고리 IDX */
  vodProductCategoryIdx: string;
}

export interface CreateVodClassDto {
  /**
   * 분반명
   * @example A반
   */
  vodClassName: string;
  /** 상품 IDX */
  vodProductIdx: string;
}

export interface UpdateVodClassDto {
  /**
   * 분반명
   * @example A반
   */
  vodClassName: string;
}

export interface CreateVodLessonDto {
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  startDate: string;
  /**
   * 종강일
   * @format date-time
   * @example 2023-03-31
   */
  endDate?: string;
  /** 레슨가격 */
  totalPrice: number;
  /** VOD수업 INDEX */
  vodProductIdx: number;
  /** 학생 INDEX */
  studentIdx: number;
}

export interface VodLessonProductData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  teacherInformation: VodProductsTeacher;
  vodProductCategoryInformation: VodProductsCategory;
}

export interface VodLessonStudenttData {
  /** INDEX */
  studentIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
}

export interface VodLessonsData {
  /** INDEX */
  vodLessonIdx: number;
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  startDate: string;
  /**
   * 종강일
   * @format date-time
   * @example 2023-03-31
   */
  endDate?: string;
  /** 레슨가격 */
  totalPrice: number;
  /**
   * 레슨상태 0:수강,1:수강취소,8:휴강,9:종강
   * @example 1
   */
  vodLessonStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  vodProductInformation: VodLessonProductData;
  studentInformation: VodLessonStudenttData;
}

export interface VodLessonsBody {
  total: number;
  data: VodLessonsData[];
}

export interface VodLessonsResponseDto {
  header: BaseResponseHeader;
  body: VodLessonsBody;
}

export interface VodLessonPaymentData {
  /** 결제IDX */
  paymentIdx: number;
  /**
   * 지불수단
   * @example card
   */
  payMethod?: 'card' | 'phone' | 'vbank';
  /**
   * 페이플 리턴 결제 시간
   * @format date-time
   * @example 2023-03-31
   */
  paidDate?: string;
  /**
   * 실결제금액(부가세 포함)
   * @example 1100
   */
  paidAmount: number;
}

export interface ReadLessonVodContentData {
  /** INDEX */
  vodContentIdx: number;
  /** 강의명 */
  vodContentTitle: string;
  /**
   * 상태 0:활성,9:비활성
   * @example 0
   */
  vodContentStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 블록여부 0:넌블락/1:블락 (상품 할당 및 구매시 block상태에 따라 스케쥴이 할당됨. 단! 실제 영상을 시청하는데는 스케쥴 하나의 block상태만 따짐) */
  isContentBlock?: 0 | 1;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  vodCategoryL1Information: VodCategoryData;
  vodCategoryL2Information: VodCategoryData;
  vodHistoryFileInformation: VodKollusContentData;
  /** INDEX */
  vodScheduleIdx: number;
  /** 80프로 이상 시청: 0 미시청, 1 시청완료 */
  isCompleted?: 0 | 1;
  /**
   * 시청횟수
   * @example 시청횟수
   */
  showTime: string;
  /**
   * 학습진도율
   * @example 학습진도율
   */
  progressRate: string;
  /** 블록여부 0:넌블락/1:블락 */
  isBlock?: 0 | 1;
}

export interface ReadLessonVodProductData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /**
   * 상품상태 0:활성,9:비활성
   * @example 0
   */
  vodProductStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  teacherInformation: VodProductsTeacher;
  vodProductCategoryInformation: VodProductsCategory;
  vodCategoryL1Information: VodCategoryData;
  contents: ReadLessonVodContentData[];
}

export interface VodLessonData {
  /** INDEX */
  vodLessonIdx: number;
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  startDate: string;
  /**
   * 종강일
   * @format date-time
   * @example 2023-03-31
   */
  endDate?: string;
  /** 레슨가격 */
  totalPrice: number;
  /**
   * 레슨상태 0:수강,1:수강취소,8:휴강,9:종강
   * @example 1
   */
  vodLessonStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  studentInformation: VodLessonStudenttData;
  paymentInformation: VodLessonPaymentData;
  product: ReadLessonVodProductData;
}

export interface VodLessonBody {
  total: number;
  data: VodLessonData;
}

export interface VodLessonResponseDto {
  header: BaseResponseHeader;
  body: VodLessonBody;
}

export interface UpdateLessonClassDto {
  /**
   * idx 리스트
   * @example 1,2,3,4
   */
  vodLessonIdx: string;
  /** 분반 IDX(안보내면, 분반지정안함으로 변경) */
  vodClassIdx?: number;
}

export interface UpdateAttendanceDto {
  /** 출석상태 0:나띵,1:출석,2:출석완료,3:출석조건미완,8:지각,9:결석 */
  scheduleStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 이의결과
   * @example {title:,content:,file:}
   */
  objectionResult?: string;
}

export interface CheckLiveDto {
  /** 상품 idx */
  vodProductIdx: number;
  /** 강사 IDX */
  teacherIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
  /** 학생 IDX */
  studentIndexes: string;
}

export interface SetLiveDto {
  /** 상품 idx */
  vodProductIdx: number;
  /** 강사 IDX */
  teacherIdx?: number;
  /** 20240611 */
  startDate?: string;
  /** 1000 */
  time?: string;
  /** 학생 IDX */
  studentIndexes: string;
  /** checkLive 결과에서 group */
  liveGroup?: string;
}

export interface CheckAddLiveScheduleDto {
  /** vodLesson indexes */
  vodLessonIdx: string;
  /** 강사 IDX */
  teacherIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface AddLiveScheduleDto {
  /** vodLesson indexes */
  vodLessonIdx: string;
  /** 강사 IDX */
  teacherIdx: number;
  /** 20240611 */
  startDate: string;
  /** 1000 */
  time: string;
}

export interface CheckChangeScheduleDto {
  /** 스케쥴 idx */
  vodScheduleIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface RequestChangeScheduleDto {
  /** 스케쥴 idx */
  vodScheduleIdx: number;
  /** 20240611 */
  startDate: string;
  /** 1000 */
  time: string;
  /** 변경신청사유 */
  changedReason?: string;
}

export interface ConfirmChangeScheduleDto {
  /** vodScheduleChangeIdx */
  vodScheduleChangeIdx: number;
  /** 1:승인, 2:거부 */
  isConfirm: 1 | 2;
}

export interface SubLiveScheduleDto {
  /** vodScheduleIndexes */
  vodScheduleIndexes: string;
}

export interface UpdateVodLessonDto {
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  startDate: string;
  /**
   * 종강일
   * @format date-time
   * @example 2023-03-31
   */
  endDate?: string;
  /**
   * 레슨상태 0:수강,1:수강취소,8:휴강,9:종강
   * @example 1
   */
  vodLessonStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
}

export interface UpdateVodScheduleDto {
  /** 블록여부 0:넌블락/1:블락 */
  isBlock: 0 | 1;
}

export interface DeleteVodLessonDto {
  /**
   * idx 리스트
   * @example 1,2,3,4
   */
  vodLessonIdx: string;
}

export interface VodEvaluatioVodProductData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
}

export interface VodEvaluationData {
  /** INDEX */
  vodEvaluationIdx: number;
  /**
   * VOD 평가 스코어
   * @example 5
   */
  vodEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** VOD 평가(선택형) */
  vodEvaluationTitle: string;
  /** VOD 평가(입력형) */
  vodEvaluationComment: string;
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  studentInformation: VodLessonStudenttData;
  vodProductInformation: VodEvaluatioVodProductData;
}

export interface VodEvaluationBody {
  total: number;
  data: VodEvaluationData;
}

export interface VodEvaluationResponseDto {
  header: BaseResponseHeader;
  body: VodEvaluationBody;
}

export interface UpdateVodEvaluationDto {
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface InsertQuestionCategoryL1Dto {
  /** 대분류 */
  questionCategoryL1: string;
}

export interface InsertQuestionCategoryL2Dto {
  /** 소분류 */
  questionCategoryL2: string;
  /** 대분류 */
  questionCategoryL1Idx: number;
}

export interface UpdateQuestionCategoryL2Dto {
  /** 소분류 */
  questionCategoryL2: string;
}

export interface QuestionPhotoFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface QuestionFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface QuestionFileUploadResponseBody {
  data: QuestionFileUploadData;
}

export interface QuestionFileUploadResponseDto {
  header: BaseResponseHeader;
  body: QuestionFileUploadResponseBody;
}

export interface InsertQuestionDto {
  /** 문제명 */
  questionTilte?: string;
  /** 문제내용 */
  questionContent?: string;
  /** 참고자료위치 */
  questionFilePath?: string;
  /** 문제타입 */
  questionAnswerType?: '객관식' | '주관식';
  /**
   * 객관식내용
   * @example [{ "no": "1", "content": "이거다" },{ "no": "2", "content": "저거다" }]
   */
  questionAnswers?: string;
  /** 정답 */
  questionAnswer?: string;
  /** 문제해설 */
  questionAnswerDesc?: string;
  /** 힌트 */
  questionHint?: string;
  /**
   * 난이도
   * @example 0
   */
  questionDifficulty?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 학습자수준 */
  questionLevel?: '미설정' | '초등학교' | '중학교' | '고등학교' | '대학교' | '전문가';
  /** 문제풀이예상시간 */
  questionTimer?: string;
  /**
   * 태그목록
   * @example [{ "tag": "abc" },{ "tag": "cdf" }]
   */
  tags?: string;
  /** questionCategoryL1Idx 카테고리 L1은 필수임 (20240507 도매니저) */
  questionCategoryL1Idx: number;
  /** questionCategoryL2Idx 카테고리 L2은 선택임 (20240507 도매니저) */
  questionCategoryL2Indexes?: string;
}

export interface UpdateQuestionDto {
  /** 문제명 */
  questionTilte?: string;
  /** 문제내용 */
  questionContent?: string;
  /** 참고자료위치 */
  questionFilePath?: string;
  /** 문제타입 */
  questionAnswerType?: '객관식' | '주관식';
  /**
   * 객관식내용
   * @example [{ "no": "1", "content": "이거다" },{ "no": "2", "content": "저거다" }]
   */
  questionAnswers?: string;
  /** 정답 */
  questionAnswer?: string;
  /** 문제해설 */
  questionAnswerDesc?: string;
  /** 힌트 */
  questionHint?: string;
  /**
   * 난이도
   * @example 0
   */
  questionDifficulty?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  /** 학습자수준 */
  questionLevel?: '미설정' | '초등학교' | '중학교' | '고등학교' | '대학교' | '전문가';
  /** 문제풀이예상시간 */
  questionTimer?: string;
  /**
   * 태그목록
   * @example [{ "tag": "abc" },{ "tag": "cdf" }]
   */
  tags?: string;
  /** @example 1,2,3,4~ */
  questionIndexes: string;
  /** questionCategoryL1Idx 카테고리 L1은 필수임 (20240507 도매니저) */
  questionCategoryL1Idx?: number;
  /** questionCategoryL2Idx 카테고리 L2은 선택임 (20240507 도매니저) */
  questionCategoryL2Indexes?: string;
}

export interface DeleteQuestionDto {
  /** @example 1,2,3,4~ */
  questionIndexes: string;
}

export interface GetAutoQuestionsDto {
  /** 소분류 */
  questionCategoryL2Indexes: string;
  /**
   * 난이도
   * @example 1,2,3,4~
   */
  questionDifficulties: string;
  /**
   * 퀴즈수로 생성
   * @example 10
   */
  questionCount?: string;
  /**
   * 점수로 생성
   * @example 10
   */
  questionScore?: string;
}

export interface InsertQuestionsDto {
  /** 문제지명 */
  questionsTilte?: string;
  /** @example 1,2,3,4~ */
  questionIndexes?: string;
}

export interface UpdateQuestionsDto {
  /** 문제지명 */
  questionsTilte?: string;
  /** @example 1,2,3,4~ */
  questionsIndexes: string;
}

export interface UpdateQuestionsConnectionDto {
  /** @example 1,2,3,4~ */
  questionIndexes: string;
  /** questionsIdx */
  questionsIdx: string;
}

export interface ProductList {
  /** 상품 IDX */
  vodProductIdx: number;
  /** 쿠폰 IDX */
  couponIdx?: number;
}

export interface PaypleLinkDto {
  /** 학생 INDEX */
  studentIdx: number;
  /**
   * 상품타입
   * @example vod
   */
  productType: 'vod';
  productList: ProductList[];
}

export interface PaymentStudentData {
  /** INDEX */
  studentIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /**
   * 가입경로
   * @example schoola
   */
  joinPath?: string;
}

export interface PaymentVodProductData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(내부용)
   * @example 주니어 레벨1
   */
  vodProductName: string;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
}

export interface PaymentData {
  /** 결제IDX */
  paymentIdx: number;
  /** 결제대기 시간 */
  payExpiryDate: string;
  /**
   * 결제금액
   * @example 1000
   */
  payAmount: number;
  /** 페이플 검증키 */
  PCD_PAY_OID: string;
  /**
   * 지불수단
   * @example card
   */
  payMethod?: 'card' | 'phone' | 'vbank';
  /**
   * 페이플 리턴 결제 시간
   * @format date-time
   * @example 2023-03-31
   */
  paidDate?: string;
  /**
   * 실결제금액(부가세 포함)
   * @example 1100
   */
  paidAmount: number;
  /**
   * 환불 시간
   * @format date-time
   * @example 2023-03-31
   */
  refundedDate?: string;
  /**
   * 환불금액(부가세 포함)
   * @example 1100
   */
  refundedAmount: number;
  /**
   * 결제상태 0:결제창호출,1:결제완료,7:취소요청,8:부분취소,9:취소
   * @example 0
   */
  isPaid?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /**
   * 상품타입
   * @example vod
   */
  productType?: 'vod' | 'live';
  studentInformation: PaymentStudentData;
  vodProductInformation: PaymentVodProductData;
}

export interface PaymentBody {
  total: number;
  data: PaymentData[];
}

export interface PaymentResponseDto {
  header: BaseResponseHeader;
  body: PaymentBody;
}

export interface RefundDto {
  /** 스케쥴 INDEX 리스트(1,2,3,4,5) */
  scheduleIdexes?: string;
  /** 쿠폰 INDEX 리스트(1,2,3,4,5) */
  couponIdexes?: string;
  /**
   * 환불금액(부가세 포함)
   * @example 1100
   */
  refundedAmount: number;
}

export interface NoticePhotoFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface NoticeFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface NoticeFileUploadResponseBody {
  data: NoticeFileUploadData;
}

export interface NoticeFileUploadResponseDto {
  header: BaseResponseHeader;
  body: NoticeFileUploadResponseBody;
}

export interface CreateNoticeCategoryDto {
  /** 공지사항 카테고리 */
  noticeCategory: string;
  /** 공지사항 카테고리 사진 */
  noticeCategoryPhotoFilePath?: string;
}

export interface CreateNoticeDto {
  /** 타이틀 */
  noticeTitle: string;
  /** 내용 */
  noticeContent: string;
  /** 참고자료위치 */
  noticeFilePath?: string;
  /**
   * 팝업 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isPop?: 0 | 1;
  /** noticeCategoryIdx */
  noticeCategoryIdx: string;
}

export interface NoticeData {
  /** notice IDX */
  noticeIdx: number;
  /** 타이틀 */
  noticeTitle: string;
  /** 내용 */
  noticeContent: string;
  /** 참고자료위치 */
  noticeFilePath?: string;
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /**
   * 팝업 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isPop?: 0 | 1;
  /** 뷰 카운트 */
  vCount?: number;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** 공지 카테고리 IDX */
  noticeCategoryIdx: string;
  /** 공지 카테고리 */
  noticeCategory: string;
  /** 공지 카테고리 파일경로 */
  noticeCategoryPhotoFilePath: string;
}

export interface NoticesBody {
  total: number;
  data: NoticeData[];
}

export interface NoticesResponseDto {
  header: BaseResponseHeader;
  body: NoticesBody;
}

export interface NoticeBody {
  total: number;
  data: NoticeData;
}

export interface NoticeResponseDto {
  header: BaseResponseHeader;
  body: NoticeBody;
}

export interface UpdateNoticeDto {
  /** 타이틀 */
  noticeTitle: string;
  /** 내용 */
  noticeContent: string;
  /** 참고자료위치 */
  noticeFilePath?: string;
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /**
   * 팝업 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isPop?: 0 | 1;
  /** 공지카테고리 Idx */
  noticeCategoryIdx: string;
}

export interface FaqPhotoFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface FaqFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface FaqFileUploadResponseBody {
  data: FaqFileUploadData;
}

export interface FaqFileUploadResponseDto {
  header: BaseResponseHeader;
  body: FaqFileUploadResponseBody;
}

export interface CreateFaqCategoryDto {
  /** FAQ 카테고리 */
  faqCategory: string;
  /** FAQ 카테고리 사진 */
  faqCategoryPhotoFilePath?: string;
}

export interface CreateFaqDto {
  /** 타이틀 */
  faqTitle: string;
  /** 내용 */
  faqContent: string;
  /** faqCategoryIdx */
  faqCategoryIdx: string;
}

export interface FaqData {
  /** faq IDX */
  faqIdx: number;
  /** 타이틀 */
  faqTitle: string;
  /** 내용 */
  faqContent: string;
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** FAQ 카테고리 IDX */
  faqCategoryIdx: number;
  /** FAQ 카테고리 */
  faqCategory: string;
  /** FAQ 카테고리 파일경로 */
  faqCategoryPhotoFilePath: string;
}

export interface FaqsBody {
  total: number;
  data: FaqData[];
}

export interface FaqsResponseDto {
  header: BaseResponseHeader;
  body: FaqsBody;
}

export interface FaqBody {
  total: number;
  data: FaqData;
}

export interface FaqResponseDto {
  header: BaseResponseHeader;
  body: FaqBody;
}

export interface UpdateFaqDto {
  /** 타이틀 */
  faqTitle: string;
  /** 내용 */
  faqContent: string;
  /**
   * 학생페이지 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /** faqCategoryIdx */
  faqCategoryIdx: string;
}

export interface QnaPhotoFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface QnaFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface QnaFileUploadResponseBody {
  data: QnaFileUploadData;
}

export interface QnaFileUploadResponseDto {
  header: BaseResponseHeader;
  body: QnaFileUploadResponseBody;
}

export interface CreateQnaCategoryDto {
  /** QNA 카테고리 */
  qnaCategory: string;
  /** QNA 카테고리 사진 */
  qnaCategoryPhotoFilePath?: string;
}

export interface QnaCategoryData {
  /** qnaCategory IDX */
  qnaCategoryIdx: number;
  /** QNA 카테고리 */
  qnaCategory: string;
  /** QNA 카테고리 사진 */
  qnaCategoryPhotoFilePath?: string;
}

export interface QnaCategoryBody {
  total: number;
  data: QnaCategoryData[];
}

export interface QnaCategoryResponseDto {
  header: BaseResponseHeader;
  body: QnaCategoryBody;
}

export interface QnasData {
  /** qna IDX */
  qnaIdx: number;
  /** 질문 제목 */
  qnaTitle: string;
  /** 질문 내용 */
  qnaContent: string;
  /**
   * 답변 작성 시간
   * @format date-time
   * @example 2023-03-31
   */
  replyDate?: string;
  /**
   * 0:다들보임, 1:나만보임
   * @example 0
   */
  isSecret?: 0 | 1;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** QNA 카테고리 Idx */
  qnaCategoryIdx: string;
  /** QNA 카테고리 */
  qnaCategory: string;
  /** QNA 카테고리 파일경로 */
  qnaCategoryPhotoFilePath: string;
}

export interface QnasBody {
  total: number;
  data: QnasData[];
}

export interface QnasResponseDto {
  header: BaseResponseHeader;
  body: QnasBody;
}

export interface QnaData {
  /** qna IDX */
  qnaIdx: number;
  /** 질문 제목 */
  qnaTitle: string;
  /** 질문 내용 */
  qnaContent: string;
  /** 답변 내용 */
  replyContent?: string;
  /**
   * 답변 작성 시간
   * @format date-time
   * @example 2023-03-31
   */
  replyDate?: string;
  /**
   * 0:다들보임, 1:나만보임
   * @example 0
   */
  isSecret?: 0 | 1;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** QNA 카테고리 Idx */
  qnaCategoryIdx: string;
  /** QNA 카테고리 */
  qnaCategory: string;
  /** QNA 카테고리 파일경로 */
  qnaCategoryPhotoFilePath: string;
  /** 상품IDX */
  vodProductIdx: number;
  /** 상품명 */
  vodProductName: string;
  /** 상품명(학생용) */
  vodProductNameForStudents: string;
}

export interface QnaBody {
  total: number;
  data: QnaData;
}

export interface QnaResponseDto {
  header: BaseResponseHeader;
  body: QnaBody;
}

export interface UpdateQnaDto {
  /** 답변 내용 */
  replyContent?: string;
}

export interface CreateCouponDto {
  /** 쿠폰명 */
  couponName: string;
  /**
   * 쿠폰만료일
   * @format date-time
   * @example 2023-03-31
   */
  couponExpiredDate?: string;
  /**
   * 쿠폰타입 amount:금액, percent:비율
   * @example amount
   */
  couponType?: 'amount' | 'percent';
  /**
   * 쿠폰금액 amount:금액, percent: 1~100
   * @example 1100
   */
  couponValue: string;
  /** 발매 수량 */
  couponQuantity: number;
  /** 학생 INDEX */
  studentIdx?: number;
}

export interface CouponGroupData {
  /** 쿠폰 그룹 코드 */
  couponGroupCode: string;
  /** 쿠폰명 */
  couponName: string;
  /**
   * 쿠폰만료일
   * @format date-time
   * @example 2023-03-31
   */
  couponExpiredDate?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** 발행쿠폰 미사용수량 */
  notUsedCnt: string;
  /** 발행쿠폰 사용수량 */
  usedCnt: string;
}

export interface CouponGroupBody {
  total: number;
  data: CouponGroupData[];
}

export interface CouponGroupResponseDto {
  header: BaseResponseHeader;
  body: CouponGroupBody;
}

export interface CouponData {
  /** INDEX */
  couponIdx: number;
  /** 쿠폰 그룹 코드 */
  couponGroupCode: string;
  /** 쿠폰 코드 */
  couponCode: string;
  /** 쿠폰명 */
  couponName: string;
  /**
   * 쿠폰만료일
   * @format date-time
   * @example 2023-03-31
   */
  couponExpiredDate?: string;
  /**
   * 쿠폰타입 amount:금액, percent:비율
   * @example amount
   */
  couponType?: 'amount' | 'percent';
  /**
   * 쿠폰금액 amount:금액, percent: 1~100
   * @example 1100
   */
  couponValue: string;
  /**
   * 쿠폰사용일
   * @format date-time
   * @example 2023-03-31
   */
  couponUsedDate?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** 학생 IDX */
  studentIdx: number;
  /** 학생 ID */
  studentId: string;
  /** 학생명 */
  studentName: string;
  /** 학생번호 */
  studentPhone1: string;
  /** 학생가입경로 */
  joinPath: string;
}

export interface CouponBody {
  data: CouponData;
}

export interface CouponResponseDto {
  header: BaseResponseHeader;
  body: CouponBody;
}

export interface AddCouponDto {
  /** 발매 수량 */
  couponQuantity: number;
}

export interface UpdateCouponDto {
  /** INDEX */
  studentIdx: number;
}

export interface CreateCouponAutoDto {
  /** 쿠폰 그룹 코드 */
  couponGroupCode: string;
  /** 자동발송분류 */
  autoType: 'signup';
  /**
   * 자동발송 0:no, 1:yes
   * @example 0
   */
  isAuto: 0 | 1;
}

export interface UpdateCouponAutoDto {
  /** 쿠폰 그룹 코드 */
  couponGroupCode: string;
  /**
   * 자동발송 0:no, 1:yes
   * @example 0
   */
  isAuto: 0 | 1;
}

export type BbbHookDto = object;

export interface UpdateAccesskeyDto {
  accesskey: string;
}

export interface NotificaitonCertPhoneInformation {
  idx: number;
  phone: string;
  phoneCertFlag: number;
  phoneCertType: string;
  impName: string;
  impGender: string;
  impBirth: string;
  impUniqueKey: string;
  impUniqueInSite: string;
  employmentCertUrl: string;
  createAt: string;
}

export interface NotificaitonCertKakaoInformation {
  idx: number;
  plusFriendId: string;
  phoneNo: string;
  status: string;
  createAt: string;
}

export interface NotificaitonChargePlanInformation {
  chargePlanIdx: number;
  chargePlanCode: string;
  chargePlanName: string;
  chargeSms: string;
  chargeLms: string;
  chargeMms: string;
  chargeAlimtalk: string;
  chargeGroup: number;
}

export interface ReadProfileData {
  balance: string;
  certPhoneInformation: NotificaitonCertPhoneInformation[];
  certKakaoInformation: NotificaitonCertKakaoInformation[];
  chargePlanInformation: NotificaitonChargePlanInformation;
}

export interface ReadProfileBody {
  data: ReadProfileData;
}

export interface ReadProfileResponseDto {
  header: BaseResponseHeader;
  body: ReadProfileBody;
}

export interface UpdateProfileDto {
  profileType: 'phone' | 'profile';
  profileVal: string;
}

export interface MessageData {
  message_messageIdx: number;
  message_registerDate: string;
  message_requestDate: string;
  message_plusFriendId: string;
  message_templateCode: string;
  message_templateParameter: string;
  message_title: string;
  message_body: string;
  message_attachFileId: string;
  message_sendNo: string;
  message_recipientSeq: string;
  message_recipientNo: string;
  message_messageType: string;
  message_charging: string;
  message_requestId: string;
  message_nhnRequestId: string;
  message_nhnStatusCode: number;
  message_nhnResultCode: string;
  message_nhnSenderType: string;
  message_companyIdx: number;
  message_userIdx: number;
}

export interface ReadMessageBody {
  total: number;
  data: MessageData[];
}

export interface ReadMessageResponseDto {
  header: BaseResponseHeader;
  body: ReadMessageBody;
}

export interface RecipientsData {
  /** 메시지 타입 */
  messageType: 'sms' | 'lms';
  /** 수신 번호 */
  recipientNo: string;
  /**
   * 메시지 제목
   * @example test
   */
  title?: string;
  /**
   * 메시지 내용
   * @example test
   */
  body: string;
}

export interface SendMessageDto {
  recipientsData: RecipientsData[];
}

export interface CreateAlimtalkTemplateDto {
  /**
   * 템플릿 이름
   * @example 테스트 템플릿 이름
   */
  templateName: string;
  /**
   * 템플릿 본문
   * @example 테스트 템플릿 본문 #{의미없는숫자}
   */
  templateContent: string;
}

export interface TemplateData {
  templateIdx: number;
  plusFriendId: string;
  templateCode: string;
  templateName: string;
  templateContent: string;
  categoryCode: string;
  nhnResultCode: string;
  nhnHookTemplateStatus: string;
  nhnHookTemplateComment: string;
  createAt: string;
  updateAt: string;
  updateUserName: string;
}

export interface ReadAlimtalkTemplateBody {
  data: TemplateData[];
}

export interface ReadAlimtalkTemplateResponseDto {
  header: BaseResponseHeader;
  body: ReadAlimtalkTemplateBody;
}

export interface SaveMessageDto {
  /** 메시지 구분 */
  messageType: 'mms' | 'alimtalk';
  /** 템플릿 코드 */
  templateCode?: string;
  /** 템플릿 본문 */
  templateContent?: string;
  /**
   * 자동발송 0:no, 1:yes
   * @example 0
   */
  isAuto?: 0 | 1;
  /** 자동발송분류(progressRate1: 구매일로부터 전체영상 진도율이,progressRate2: 각 영상 공개일로부터 진도율이 */
  autoType: 'signup' | 'paid' | 'lessonExpired' | 'qnaAnswered' | 'progressRate1' | 'progressRate2';
  /** 7:50 7일째, 50%이하 */
  additionalCondition?: string;
  /** 메시지 IDX */
  messageIdx?: number;
}

export interface DesignPhotoFileUploadDto {
  /** @format binary */
  designPhotoFile?: File;
}

export interface DesignFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface DesignFileUploadResponseBody {
  data: DesignFileUploadData;
}

export interface DesignFileUploadResponseDto {
  header: BaseResponseHeader;
  body: DesignFileUploadResponseBody;
}

export interface SaveDesignDto {
  /** design IDX */
  designIdx: number;
  /** 디자인 타입 */
  designType: string;
  /** 파비콘 이미지 */
  faviconImg?: string;
  /** 타이틀 */
  webTitle: string;
  /** 로고(PC) 이미지 */
  logoPcImg?: string;
  /** 로고(모바일) 이미지 */
  logoMoImg?: string;
  /** 헤더명 */
  homeHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  homeVisible?: 0 | 1;
  /** 내용 */
  homeText?: string;
  /** 이미지 */
  homeImg?: string;
  /** 헤더명 */
  workHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  workVisible?: 0 | 1;
  /** 이미지 */
  workImg?: string;
  /** 헤더명 */
  courseHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  courseVisible?: 0 | 1;
  /** 헤더명 */
  categoryHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  categoryVisible?: 0 | 1;
  /** 헤더명 */
  teacherHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  teacherVisible?: 0 | 1;
  /** 헤더명 */
  postHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  postVisible?: 0 | 1;
  /** 헤더명 */
  downHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  downVisible?: 0 | 1;
  /** 헤더명 */
  reiviewHeader?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  reiviewVisible?: 0 | 1;
  /** footer에 회사 정보 */
  footerInfo: string;
  /** 배너1 유형 */
  banner1Type?: 'text' | 'image' | 'counter';
  /** 배너1 Contents */
  banner1Contents?: string;
  /** 배너1 url */
  banner1Url?: string;
  /** 배너1 만료시간 */
  banner1Counter?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  banner1Visible?: 0 | 1;
  /** 배너2 유형 */
  banner2Type?: 'text' | 'image' | 'counter';
  /** 배너2 Contents */
  banner2Contents?: string;
  /** 배너2 url */
  banner2Url?: string;
  /** 배너2 만료시간 */
  banner2Counter?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  banner2Visible?: 0 | 1;
  /** 배너3 유형 */
  banner3Type?: 'text' | 'image' | 'counter';
  /** 배너3 Contents */
  banner3Contents?: string;
  /** 배너3 url */
  banner3Url?: string;
  /** 배너3 만료시간 */
  banner3Counter?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  banner3Visible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  kakaoVisible?: 0 | 1;
  /** kakaoUrl */
  kakaoUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  facebookVisible?: 0 | 1;
  /** facebook */
  facebookUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  instarVisible?: 0 | 1;
  /** instar */
  instarUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  lineVisible?: 0 | 1;
  /** line */
  lineUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  bandVisible?: 0 | 1;
  /** band */
  bandUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  xVisible?: 0 | 1;
  /** x */
  xUrl?: string;
}

export interface DesignData {
  /** design IDX */
  designIdx: number;
  /** 디자인 타입 */
  designType: string;
  /** 파비콘 이미지 */
  faviconImg?: string;
  /** 타이틀 */
  webTitle: string;
  /** 로고(PC) 이미지 */
  logoPcImg?: string;
  /** 로고(모바일) 이미지 */
  logoMoImg?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  homeVisible?: 0 | 1;
  /** 내용 */
  homeText?: string;
  /** 이미지 */
  homeImg?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  workVisible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  courseVisible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  categoryVisible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  teacherVisible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  postVisible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  downVisible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  reiviewVisible?: 0 | 1;
  /** footer에 회사 정보 */
  footerInfo: string;
  /** 배너1 유형 */
  banner1Type?: 'text' | 'image' | 'counter';
  /** 배너1 Contents */
  banner1Contents?: string;
  /** 배너1 url */
  banner1Url?: string;
  /** 배너1 만료시간 */
  banner1Counter?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  banner1Visible?: 0 | 1;
  /** 배너2 유형 */
  banner2Type?: 'text' | 'image' | 'counter';
  /** 배너2 Contents */
  banner2Contents?: string;
  /** 배너2 url */
  banner2Url?: string;
  /** 배너2 만료시간 */
  banner2Counter?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  banner2Visible?: 0 | 1;
  /** 배너3 유형 */
  banner3Type?: 'text' | 'image' | 'counter';
  /** 배너3 Contents */
  banner3Contents?: string;
  /** 배너3 url */
  banner3Url?: string;
  /** 배너3 만료시간 */
  banner3Counter?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  banner3Visible?: 0 | 1;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  kakaoVisible?: 0 | 1;
  /** kakaoUrl */
  kakaoUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  facebookVisible?: 0 | 1;
  /** facebook */
  facebookUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  instarVisible?: 0 | 1;
  /** instar */
  instarUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  lineVisible?: 0 | 1;
  /** line */
  lineUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  bandVisible?: 0 | 1;
  /** band */
  bandUrl?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  xVisible?: 0 | 1;
  /** x */
  xUrl?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
}

export interface DesignResponseBody {
  data: DesignData;
}

export interface DesignResponseDto {
  header: BaseResponseHeader;
  body: DesignResponseBody;
}

export interface BoardFileUploadDto {
  /** @format binary */
  file?: File;
}

export interface BoardFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface BoardFileUploadResponseBody {
  data: BoardFileUploadData;
}

export interface BoardFileUploadResponseDto {
  header: BaseResponseHeader;
  body: BoardFileUploadResponseBody;
}

export interface CreateBoardClassDto {
  /** 게시판 타입 */
  boardClassType: 'notice' | 'comment' | 'score';
  /** 게시판 구분 */
  boardClassTitle: string;
}

export interface BoardClassData {
  /** board IDX */
  boardClassIdx: number;
  /** 게시판 구분 */
  boardClassTitle: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface BoardClassBody {
  total: number;
  data: BoardClassData[];
}

export interface BoardClassResponseDto {
  header: BaseResponseHeader;
  body: BoardClassBody;
}

export interface UpdateBoardClassDto {
  /** 게시판 타입 */
  boardClassType: 'notice' | 'comment' | 'score';
  /** 게시판 구분 */
  boardClassTitle: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateBaordCategoryDto {
  /** 카테고리 */
  boardCategory: string;
  /** 카테고리 사진 */
  boardCategoryPhotoFilePath?: string;
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface BoardCategoryData {
  /** boardCategory IDX */
  boardCategoryIdx: number;
  /** 카테고리 */
  boardCategory: string;
  /** 카테고리 사진 */
  boardCategoryPhotoFilePath?: string;
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface BoardCategoryBody {
  total: number;
  data: BoardCategoryData[];
}

export interface BoardCategoryResponseDto {
  header: BaseResponseHeader;
  body: BoardCategoryBody;
}

export interface UpdateBaordCategoryDto {
  /** 카테고리 */
  boardCategory: string;
  /** 카테고리 사진 */
  boardCategoryPhotoFilePath?: string;
}

export interface CreateBoardDto {
  /** 타이틀 */
  boardTitle: string;
  /** 내용 */
  boardContent: string;
  /**
   * 평가 스코어
   * @example 5
   */
  boardScore: 1 | 2 | 3 | 4 | 5;
  /** 첨부파일 */
  boardFilePath?: string;
  /** 상품에 대한 질문일 경우 */
  vodProductIdx?: number;
  /** boardClassIdx */
  boardClassIdx: number;
  /** boardCategoryIdx */
  boardCategoryIdx: number;
}

export interface BoardData {
  /** board IDX */
  boardIdx: number;
  /** 작성자 teacher:강사, student:학생, anonymous:비회원 */
  boardWriter: 'teacher' | 'student' | 'anonymous';
  /** 타이틀 */
  boardTitle: string;
  /** 첨부파일 */
  boardFilePath?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /** 뷰 카운트 */
  vCount?: number;
  /**
   * 비회원 학생명
   * @example 홍길동
   */
  anonymousName?: string;
  /**
   * 비회원 전화번호
   * @example 01012345678
   */
  anonymousPhone?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** studentIdx */
  studentIdx: string;
  /** studentName */
  studentName: string;
  /** studentPhone1 */
  studentPhone1: string;
  /** teacherIdx */
  teacherIdx: string;
  /** teacherName */
  teacherName: string;
  /** vodProductIdx */
  vodProductIdx: string;
  /** vodProductName */
  vodProductName: string;
  /** vodProductNameForStudents */
  vodProductNameForStudents: string;
  /** boardClassIdx */
  boardClassIdx: string;
  /** boardClassTitle */
  boardClassTitle: string;
  /** boardCategoryIdx */
  boardCategoryIdx: string;
  /** boardCategory */
  boardCategory: string;
  /** boardCategoryPhotoFilePath */
  boardCategoryPhotoFilePath: string;
}

export interface BoardBody {
  total: number;
  data: BoardData[];
}

export interface BoardResponseDto {
  header: BaseResponseHeader;
  body: BoardBody;
}

export interface UpdateBoardDto {
  /** 타이틀 */
  boardTitle: string;
  /** 내용 */
  boardContent: string;
  /**
   * 평가 스코어
   * @example 5
   */
  boardScore: 1 | 2 | 3 | 4 | 5;
  /** 첨부파일 */
  boardFilePath?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateCommentDto {
  /** 내용 */
  boardComment: string;
  /** boardIdx */
  boardIdx: number;
}

export interface UpdateCommentDto {
  /** 내용 */
  boardComment: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateCocommentDto {
  /** 내용 */
  boardCocomment: string;
  /** boardCommentIdx */
  boardCommentIdx: number;
}

export interface UpdateCocommentDto {
  /** 내용 */
  boardCocomment: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface WpMyData {
  /** INDEX */
  studentIdx: number;
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /** 전화번호 광고수신여부 0:안받음,1:받음 */
  studentPhoneAd?: 0 | 1;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
  /** 카카오아이디 설정여부 */
  kakaoId: boolean;
  /** 네이버아이디 설정여부 */
  naverId: boolean;
}

export interface WpMyDataResponseBody {
  data: WpMyData;
}

export interface WpMyDataResponseDto {
  header: BaseResponseHeader;
  body: WpMyDataResponseBody;
}

export interface WpAddNaverLoginDto {
  /** 네이버 로그인 후, 콜백에서 받은 access_token 값  */
  access_token: string;
  /** 네이버 로그인 시, 입력한 state 값  */
  state: string;
}

export interface WpAddKakaoLoginDto {
  /** 카카오 로그인 후, 콜백에서 받은 code 값  */
  code: string;
}

export interface WpChangePasswordDto {
  /**
   * 현재 패스워드
   * @example 1234
   */
  currentPassword: string;
  /**
   * 변경 패스워드
   * @example 1234
   */
  changePassword: string;
}

export interface WpPhotoFileUploadDto {
  /** @format binary */
  studentPhotoFile?: File;
}

export interface WpFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface WpFileUploadResponseBody {
  data: WpFileUploadData;
}

export interface WpFileUploadResponseDto {
  header: BaseResponseHeader;
  body: WpFileUploadResponseBody;
}

export interface WpUpdateMyDataDto {
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone1: string;
  /**
   * 전화번호1
   * @example 01012345678
   */
  studentPhone2?: string;
  /** 전화번호 광고수신여부 0:안받음,1:받음 */
  studentPhoneAd?: 0 | 1;
  /**
   * 회원상태 0:대기,8:정지,9:탈퇴
   * @example 1
   */
  studentStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
  /** 주소 */
  addr?: string;
  /** 상세주소 */
  addrDetail?: string;
  /** 커스텀정보1 */
  customInfo1?: string;
  /** 커스텀정보2 */
  customInfo2?: string;
  /** 커스텀정보3 */
  customInfo3?: string;
}

export interface WpDeleteDto {
  /** 탈퇴사유 */
  deleteReason?: string;
}

export interface WpCouponRegistrationDto {
  /** 쿠폰 코드 */
  couponCode: string;
}

export interface WpCouponData {
  /** INDEX */
  couponIdx: number;
  /** 쿠폰 코드 */
  couponCode: string;
  /** 쿠폰명 */
  couponName: string;
  /**
   * 쿠폰만료일
   * @format date-time
   * @example 2023-03-31
   */
  couponExpiredDate?: string;
  /**
   * 쿠폰타입 amount:금액, percent:비율
   * @example amount
   */
  couponType?: 'amount' | 'percent';
  /**
   * 쿠폰금액 amount:금액, percent: 1~100
   * @example 1100
   */
  couponValue: string;
  /**
   * 쿠폰사용일
   * @format date-time
   * @example 2023-03-31
   */
  couponUsedDate?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
}

export interface WpCouponBody {
  data: WpCouponData;
}

export interface WpCouponResponseDto {
  header: BaseResponseHeader;
  body: WpCouponBody;
}

export interface WpVodDashboardData {
  /** 총학생수 */
  studentCnt: string;
  /** 총강의수 */
  vodProductCnt: string;
  /** 수업개설수 */
  vodLessonCnt: string;
}

export interface WpVodDashboardBody {
  data: WpVodDashboardData;
}

export interface WpVodDashboardResponseDto {
  header: BaseResponseHeader;
  body: WpVodDashboardBody;
}

export interface WpVodCategoryReferenceData {
  /** 자료명 */
  vodProductFileName: string;
  /** 교재,자료,과제 택1 */
  vodProductFileCategory?: string;
  /** 자료 */
  vodProductFilePath: string;
}

export interface WpVodCategoryData {
  /**
   * VOD프로그램 카테고리 코드(자동생성)
   * @example 000000001
   */
  vodCategoryCode: string;
  /** VOD프로그램 (1단: 강좌명, 2단계: 챕터명) */
  vodCategoryName: string;
  vodProductFileInformation: WpVodCategoryReferenceData;
}

export interface WpVodCategoryBody {
  total: number;
  data: WpVodCategoryData[];
}

export interface WpVodCategoryResponseDto {
  header: BaseResponseHeader;
  body: WpVodCategoryBody;
}

export interface WpVodProductsData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /**
   * 수강기간: 0:무제한,1개월 ...
   * @example 1
   */
  vodProductPeriod:
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /** 별점 평균 */
  vodEvaluationScore: string;
  /** 별점 남긴 수 */
  vodEvaluationCount: string;
  /** 수강생 수 */
  studentCount: string;
  /** 강사사진 */
  teacherPhotoFilePath: string;
  /** 강사이름 */
  teacherName: string;
  /** 총시간 */
  vodDuration: string;
  /** 강사 개설 강의 수 */
  teacherProductCount: string;
  /** VOD상품 카테고리 Idx */
  vodProductCategoryIdx: string;
  /** VOD상품 카테고리 */
  vodProductCategory: string;
  /** VOD상품 카테고리 사진 */
  vodProductCategoryPhotoFilePath: string;
}

export interface WpVodProductsBody {
  data: WpVodProductsData[];
}

export interface WpVodProductsResponseDto {
  header: BaseResponseHeader;
  total: number;
  body: WpVodProductsBody;
}

export interface Floor2LessonsData {
  /** INDEX */
  vodContentIdx: number;
  /** 강의명 */
  vodContentTitle: string;
  /**
   * 수업타입
   * @example VOD
   */
  vodContentType?: 'VOD' | 'HyFlex' | 'TEST';
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /**
   * 수업 시작 시간
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  hyflexStartDate?: string;
  vodCategoryL1Information: WpVodCategoryData;
  vodCategoryL2Information: WpVodCategoryData;
  vodHistoryFileInformation: VodKollusContentData;
}

export interface WpVodProductData {
  floor1: WpVodProductsData;
  floor2Lessons: Floor2LessonsData[];
}

export interface WpVodProductBody {
  data: WpVodProductData;
}

export interface WpVodProductResponseDto {
  header: BaseResponseHeader;
  body: WpVodProductBody;
}

export interface WpVodReviewSummaryData {
  /** 별점 남긴 수 */
  vodEvaluationCount: string;
  /** 별점 평균 */
  vodEvaluationScore: string;
  /** 5성 */
  fiveStar: string;
  /** 4성 */
  fourStar: string;
  /** 3성 */
  threeStar: string;
  /** 2성 */
  twoStar: string;
  /** 1성 */
  oneStar: string;
}

export interface WpVodStudentData {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  studentId: string;
  /**
   * 학생명
   * @example 홍길동
   */
  studentName: string;
  /** 학생사진위치 */
  studentPhotoFilePath?: string;
}

export interface WpVodReviewDetailData {
  /**
   * VOD 평가 스코어
   * @example 5
   */
  vodEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** VOD 평가(선택형) */
  vodEvaluationTitle: string;
  /** VOD 평가(입력형) */
  vodEvaluationComment: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  studentInformation: WpVodStudentData;
}

export interface WpVodReviewData {
  summary: WpVodReviewSummaryData;
  detail: WpVodReviewDetailData[];
}

export interface WpVodReviewBody {
  data: WpVodReviewData;
}

export interface WpVodReviewResponseDto {
  header: BaseResponseHeader;
  body: WpVodReviewBody;
}

export interface WpVodCategoriesData {
  /** 카테고리명 IDX */
  vodProductCategoryIdx: string;
  /** 카테고리명 */
  vodProductCategory: string;
  /** 카테고리 이미지 */
  vodProductCategoryPhotoFilePath: string;
  /** 카테고리별 상품수 */
  vodProductCnt: string;
  /** 카테고리별 수강생수 */
  studentCnt: string;
}

export interface WpVodCategoriesBody {
  data: WpVodCategoriesData[];
}

export interface WpVodCategoriesResponseDto {
  header: BaseResponseHeader;
  body: WpVodCategoriesBody;
}

export interface WpVodTeachersData {
  /**
   * 강사명
   * @example 홍길동
   */
  teacherName: string;
  /** 강사사진위치 */
  teacherPhotoFilePath?: string;
  /** 간단한 소개(text editor) */
  teacherComment: string;
  /** 자세한 소개(text editor) */
  teacherComment2?: string;
  /** 자격증(text editor) */
  teacherCertification?: string;
  /** 학력(text editor) */
  teacherDegree?: string;
  /** 경력(text editor) */
  teacherExperience: string;
}

export interface WpVodTeachersBody {
  data: WpVodTeachersData[];
}

export interface WpVodTeachersResponseDto {
  header: BaseResponseHeader;
  body: WpVodTeachersBody;
}

export interface WpVodMyLessonsData {
  /** INDEX */
  vodLessonIdx: number;
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  startDate: string;
  /**
   * 종강일
   * @format date-time
   * @example 2023-03-31
   */
  endDate?: string;
  /**
   * 레슨상태 0:수강,1:수강취소,8:휴강,9:종강
   * @example 1
   */
  vodLessonStatus: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /** 상품IDX */
  vodProductIdx: number;
  /** 상품사진 */
  vodProductPhotoFilePath: string;
  /** 수업명(노출용) */
  vodProductNameForStudents: string;
  /** 강의수 */
  scheduleCount: string;
  /** 강의 완료수 */
  completedCount: string;
  /** 남은날짜 */
  remainingDuration: string;
}

export interface WpVodMyLessonsBody {
  data: WpVodMyLessonsData[];
}

export interface WpVodMyLessonsResponseDto {
  header: BaseResponseHeader;
  body: WpVodMyLessonsBody;
}

export interface WpVodMyLessonSummaryData {
  /** 강의수 */
  scheduleCount: string;
  /** 강의 완료수 */
  completedCount: string;
}

export interface WpVodMyLessonProductData {
  /** INDEX */
  vodProductIdx: number;
}

export interface WpVodContentData {
  /** INDEX */
  vodContentIdx: number;
  /** 강의명 */
  vodContentTitle: string;
  /**
   * 상태 0:활성,9:비활성
   * @example 0
   */
  vodContentStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 수업타입
   * @example VOD
   */
  vodContentType?: 'VOD' | 'HyFlex' | 'TEST';
  /**
   * 수업 시작 시간
   * @format date-time
   * @example 2023-03-01 11:00:00
   */
  hyflexStartDate?: string;
  vodCategoryL1Information: WpVodCategoryData;
  vodCategoryL2Information: WpVodCategoryData;
  vodHistoryFileInformation: VodKollusContentData;
}

export interface WpVodMyLessonContentData {
  /** INDEX */
  vodScheduleIdx: number;
  /** 80프로 이상 시청: 0 미시청, 1 시청완료 */
  isCompleted?: 0 | 1;
  /**
   * 시청시간
   * @example 시청시간
   */
  showTime: string;
  /**
   * 학습진도율
   * @example 학습진도율
   */
  progressRate: string;
  /** 마지막 시청 */
  isLast?: 0 | 1;
  /** 블록여부 0:넌블락/1:블락 */
  isBlock?: 0 | 1;
  lessonInformation: WpVodMyLessonProductData;
  vodContentInformation: WpVodContentData;
}

export interface WpVodMyLessonProductReviewData {
  /** INDEX */
  vodEvaluationIdx: number;
  /**
   * VOD 평가 스코어
   * @example 5
   */
  vodEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** VOD 평가(선택형) */
  vodEvaluationTitle: string;
  /** VOD 평가(입력형) */
  vodEvaluationComment: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
}

export interface WpVodMyLessonTeacherReviewData {
  /** INDEX */
  teacherEvaluationIdx: number;
  /**
   * 강사 평가 스코어
   * @example 5
   */
  teacherEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** 강사 평가(선택형) */
  teacherEvaluationTitle: string;
  /** 강사 평가(입력형) */
  teacherEvaluationComment: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
}

export interface WpVodMyLessonData {
  summary: WpVodMyLessonSummaryData;
  content: WpVodMyLessonContentData[];
  productReview: WpVodMyLessonProductReviewData;
  teacherReview: WpVodMyLessonTeacherReviewData;
}

export interface WpVodMyLessonBody {
  data: WpVodMyLessonData;
}

export interface WpVodMyLessonResponseDto {
  header: BaseResponseHeader;
  body: WpVodMyLessonBody;
}

export interface CreateVodEvaluationDto {
  /**
   * VOD 평가 스코어
   * @example 5
   */
  vodEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** VOD 평가(선택형) */
  vodEvaluationTitle: string;
  /** VOD 평가(입력형) */
  vodEvaluationComment: string;
  /** 강좌 INDEX */
  vodLessonIdx: string;
}

export interface CreateTeacherEvaluationDto {
  /**
   * 강사 평가 스코어
   * @example 5
   */
  teacherEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** 강사 평가(선택형) */
  teacherEvaluationTitle: string;
  /** 강사 평가(입력형) */
  teacherEvaluationComment: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
  /** 강좌 INDEX */
  vodLessonIdx: string;
  /** 라이브 수업일때 보낸다. */
  vodScheduleIdx?: number;
}

export interface UpdateTeacherEvaluationDto {
  /**
   * 강사 평가 스코어
   * @example 5
   */
  teacherEvaluationScore: 1 | 2 | 3 | 4 | 5;
  /** 강사 평가(선택형) */
  teacherEvaluationTitle: string;
  /** 강사 평가(입력형) */
  teacherEvaluationComment: string;
  /**
   * 어드민에 메시지: 0: 없음, 1:단순문의, 2:신고
   * @example 0
   */
  isMessageToAdmin?: 0 | 1 | 2;
  /** 어드민에 메시지 */
  messageToAdmin?: string;
  /** 강사 평가 INDEX */
  teacherEvaluationIdx: number;
}

export interface WpVodMyLessonUrlData {
  /** vod url */
  vodUrl: string;
}

export interface WpVodMyLessonUrlBody {
  data: WpVodMyLessonUrlData;
}

export interface WpVodMyLessonUrlResponseDto {
  header: BaseResponseHeader;
  body: WpVodMyLessonUrlBody;
}

export interface CreateWishlistDto {
  /** VOD상품 INDEX */
  vodProductIdx: number;
}

export interface WishlistData {
  /** INDEX */
  vodProductIdx: number;
  /**
   * 수업명(노출용)
   * @example 주니어 레벨1
   */
  vodProductNameForStudents: string;
  /**
   * 이 강의로 얻을수 있는 스킬
   * @example [ReactJS, NextJS]
   */
  vodProductSkills: string;
  /**
   * 강의목표
   * @example [react를 익힌다, next를 익힌다]
   */
  vodProductGoal: string;
  /** 강의소개 */
  vodProductInfo: string;
  /**
   * 상품 난이도 1:Beginner,2:Intermediate,3:Expert
   * @example 1
   */
  vodProductLevel: 1 | 2 | 3;
  /** 가격 */
  vodProductPrice: number;
  /** 상품사진 */
  vodProductPhotoFilePath?: string;
  /** 별점 남긴 수 */
  vodEvaluationCount: string;
  /** 별점 평균 */
  vodEvaluationScore: string;
  /** 수강생 수 */
  studentCount: string;
  /** 강사사진 */
  teacherPhotoFilePath: string;
  /** 강사이름 */
  teacherName: string;
  /** 강사 개설 강의 수 */
  teacherProductCount: string;
  /** 총시간 */
  vodDuration: string;
  /** VOD상품 카테고리 Idx */
  vodProductCategoryIdx: string;
  /** VOD상품 카테고리 */
  vodProductCategory: string;
  /** VOD상품 카테고리 사진 */
  vodProductCategoryPhotoFilePath: string;
}

export interface WishlistBody {
  total: number;
  data: WishlistData[];
}

export interface WishlistResponseDto {
  header: BaseResponseHeader;
  body: WishlistBody;
}

export interface LarahvContentDto {
  /** 스케쥴 INDEX */
  vodScheduleIdx: number;
}

export interface CheckChangeLiveDto {
  /** 레슨 idx */
  vodLessonIdx: number;
  /** 강사 IDX */
  teacherIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface ChangeLiveDto {
  /** 레슨 idx */
  vodLessonIdx: number;
  /** 강사 IDX */
  teacherIdx: number;
  /** 20240611 */
  startDate: string;
  /** 1000 */
  time: string;
}

export interface CheckProductList {
  /** 상품 IDX */
  vodProductIdx: number;
  /** 쿠폰 IDX */
  couponIdx?: number;
}

export interface WpCheckPaymentDto {
  /**
   * 상품타입
   * @example vod
   */
  productType: 'vod';
  productList: CheckProductList[];
}

export interface WpCheckPaymentSummaryData {
  /** 최종 구매상품명 */
  PCD_PAY_GOODS: string;
  /** 최종 결제금액 */
  PCD_PAY_TOTAL: number;
  /** 상품 금액 */
  totalProductPrice: number;
  /** 할인 금액 */
  totalDiscountPrice: number;
}

export interface WpCheckPaymentDetailData {
  /** 상품IDX */
  vodProductIdx: number;
  /** 상품 금액 */
  vodProductPrice: number;
  /** 상품명 */
  vodProductNameForStudents: string;
  /** 쿠폰IDX */
  couponIdx: number;
  /** 쿠폰 타입 */
  discountType: string;
  /** 쿠폰 할인 금액/비율 */
  discountAmount: number;
  /** 쿠폰 적용 불가 사유 */
  discountDesc: string;
  /** 할인후 금액 */
  resultAmount: number;
}

export interface WpCheckPaymentData {
  summary: WpCheckPaymentSummaryData;
  detail: WpCheckPaymentDetailData[];
}

export interface WpCheckPaymentBody {
  data: WpCheckPaymentData;
}

export interface WpCheckPaymentResponseDto {
  header: BaseResponseHeader;
  body: WpCheckPaymentBody;
}

export interface WpBeforeLiveDto {
  /** 상품 idx */
  vodProductIdx: number;
  /** 강사 IDX */
  teacherIdx: number;
  /** 20240611 */
  startDate: string;
  /** 10 */
  time: string;
}

export interface WpBeforePaymentDto {
  /**
   * 상품타입
   * @example vod
   */
  productType: 'vod';
  productList: ProductList[];
}

export interface WpCancelPaymentDto {
  /** 페이플 검증키 */
  PCD_PAY_OID: string;
}

export interface WpAfterPaymentDto {
  PCD_AUTH_KEY: string;
  PCD_PAY_REQKEY: string;
  PCD_PAYER_ID: string;
}

export interface WpPaymentData {
  /** 결제IDX */
  paymentIdx: number;
  /** 결제대기 시간 */
  payExpiryDate: string;
  /**
   * 결제금액
   * @example 1000
   */
  payAmount: number;
  /** 페이플 검증키 */
  PCD_PAY_OID: string;
  /**
   * 지불수단
   * @example card
   */
  payMethod?: 'card' | 'phone' | 'vbank';
  /**
   * 페이플 리턴 결제 시간
   * @format date-time
   * @example 2023-03-31
   */
  paidDate?: string;
  /**
   * 실결제금액(부가세 포함)
   * @example 1100
   */
  paidAmount: number;
  /**
   * 환불 시간
   * @format date-time
   * @example 2023-03-31
   */
  refundedDate?: string;
  /**
   * 환불금액(부가세 포함)
   * @example 1100
   */
  refundedAmount: number;
  /**
   * 결제상태 0:결제창호출,1:결제완료,7:취소요청,8:부분취소,9:취소
   * @example 0
   */
  isPaid?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /**
   * 상품타입
   * @example vod
   */
  productType?: 'vod' | 'live';
  /** 상품IDX */
  vodProductIdx: number;
  /** 상품명 */
  vodProductName: string;
  /** 상품명(학생용) */
  vodProductNameForStudents: string;
  /** 강의 완료수 */
  completedCount: string;
  /** 남은날짜 */
  remainingDuration: string;
}

export interface WpPaymentBody {
  total: number;
  data: WpPaymentData[];
}

export interface WpPaymentResponseDto {
  header: BaseResponseHeader;
  body: WpPaymentBody;
}

export interface WpRefundDto {
  /** 결제IDX */
  paymentIdx: number;
}

export interface WpNoticeData {
  /** notice IDX */
  noticeIdx: number;
  /** 타이틀 */
  noticeTitle: string;
  /** 내용 */
  noticeContent: string;
  /** 뷰 카운트 */
  vCount?: number;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** 공지사항 카테고리 Idx */
  noticeCategoryIdx: string;
  /** 공지사항 카테고리 */
  noticeCategory: string;
  /** 공지사항 카테고리 사진 */
  noticeCategoryPhotoFilePath: string;
}

export interface WpNoticesBody {
  total: number;
  data: WpNoticeData[];
}

export interface WpNoticesResponseDto {
  header: BaseResponseHeader;
  body: WpNoticesBody;
}

export interface WpNoticeBody {
  total: number;
  data: WpNoticeData;
}

export interface WpNoticeResponseDto {
  header: BaseResponseHeader;
  body: WpNoticeBody;
}

export interface WpFaqData {
  /** faq IDX */
  faqIdx: number;
  /** 타이틀 */
  faqTitle: string;
  /** 내용 */
  faqContent: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** FAQ 카테고리 IDX */
  faqCategoryIdx: number;
  /** FAQ 카테고리 */
  faqCategory: string;
  /** FAQ 카테고리 파일경로 */
  faqCategoryPhotoFilePath: string;
}

export interface WpFaqsBody {
  total: number;
  data: WpFaqData[];
}

export interface WpFaqsResponseDto {
  header: BaseResponseHeader;
  body: WpFaqsBody;
}

export interface WpFaqBody {
  total: number;
  data: WpFaqData;
}

export interface WpFaqResponseDto {
  header: BaseResponseHeader;
  body: WpFaqBody;
}

export interface WpQnaPhotoFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface WpQnaFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface WpQnaFileUploadResponseBody {
  data: WpQnaFileUploadData;
}

export interface WpQnaFileUploadResponseDto {
  header: BaseResponseHeader;
  body: WpQnaFileUploadResponseBody;
}

export interface WpQnaCategoryData {
  /** QNA 카테고리 */
  qnaCategory: string;
  /** QNA 카테고리 사진 */
  qnaCategoryPhotoFilePath?: string;
}

export interface WpQnaCategoryBody {
  total: number;
  data: WpQnaCategoryData[];
}

export interface WpQnaCategoryResponseDto {
  header: BaseResponseHeader;
  body: WpQnaCategoryBody;
}

export interface WpQnasData {
  /** qna IDX */
  qnaIdx: number;
  /** 질문 제목 */
  qnaTitle: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** QNA 카테고리 Idx */
  qnaCategoryIdx: number;
  /** QNA 카테고리 */
  qnaCategory: string;
  /** QNA 카테고리 파일경로 */
  qnaCategoryPhotoFilePath: string;
  /** 상품IDX */
  vodProductIdx: number;
  /** 상품명 */
  vodProductName: string;
  /** 상품명(학생용) */
  vodProductNameForStudents: string;
}

export interface WpQnasBody {
  total: number;
  data: WpQnasData[];
}

export interface WpQnasResponseDto {
  header: BaseResponseHeader;
  body: WpQnasBody;
}

export interface WpQnaData {
  /** qna IDX */
  qnaIdx: number;
  /** 질문 제목 */
  qnaTitle: string;
  /** 질문 내용 */
  qnaContent: string;
  /** 답변 내용 */
  replyContent?: string;
  /**
   * 답변 작성 시간
   * @format date-time
   * @example 2023-03-31
   */
  replyDate?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** QNA 카테고리 Idx */
  qnaCategoryIdx: number;
  /** QNA 카테고리 */
  qnaCategory: string;
  /** QNA 카테고리 파일경로 */
  qnaCategoryPhotoFilePath: string;
  /** 상품IDX */
  vodProductIdx: number;
  /** 상품명 */
  vodProductName: string;
  /** 상품명(학생용) */
  vodProductNameForStudents: string;
}

export interface WpQnaBody {
  total: number;
  data: WpQnaData;
}

export interface WpQnaResponseDto {
  header: BaseResponseHeader;
  body: WpQnaBody;
}

export interface CreateQnaDto {
  /** 질문 제목 */
  qnaTitle: string;
  /** 질문 내용 */
  qnaContent: string;
  /**
   * 0:다들보임, 1:나만보임
   * @example 0
   */
  isSecret?: 0 | 1;
  /** QNA 카테고리 Idx */
  qnaCategoryIdx: number;
  /** 상품IDX */
  vodProductIdx?: number;
}

export interface CreateQnaBeforeLoginDto {
  /** 질문 제목 */
  qnaTitle: string;
  /** 질문 내용 */
  qnaContent: string;
  /**
   * 비회원 학생명
   * @example 홍길동
   */
  beforeLoginStudentName?: string;
  /**
   * 비회원 전화번호1
   * @example 01012345678
   */
  beforeLoginStudentPhone1?: string;
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** QNA 카테고리 IDX */
  qnaCategoryIdx: number;
  /** 상품IDX */
  vodProductIdx?: number;
}

export interface WpBoardFileUploadDto {
  /** @format binary */
  photoFile?: File;
}

export interface WpBoardFileUploadData {
  /**
   * 파일경로
   * @example 1654664169682.jpg
   */
  filePath: string;
}

export interface WpBoardFileUploadResponseBody {
  data: WpBoardFileUploadData;
}

export interface WpBoardFileUploadResponseDto {
  header: BaseResponseHeader;
  body: WpBoardFileUploadResponseBody;
}

export interface WpBoardClassData {
  /** board IDX */
  boardClassIdx: number;
  /** 게시판 구분 */
  boardClassTitle: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface WpBoardClassBody {
  total: number;
  data: WpBoardClassData[];
}

export interface WpBoardClassResponseDto {
  header: BaseResponseHeader;
  body: WpBoardClassBody;
}

export interface WpBoardCategoryData {
  /** boardCategory IDX */
  boardCategoryIdx: number;
  /** 카테고리 */
  boardCategory: string;
  /** 카테고리 사진 */
  boardCategoryPhotoFilePath?: string;
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface WpBoardCategoryBody {
  total: number;
  data: WpBoardCategoryData[];
}

export interface WpBoardCategoryResponseDto {
  header: BaseResponseHeader;
  body: WpBoardCategoryBody;
}

export interface WpBoardData {
  /** board IDX */
  boardIdx: number;
  /** 작성자 teacher:강사, student:학생, anonymous:비회원 */
  boardWriter: 'teacher' | 'student' | 'anonymous';
  /** 타이틀 */
  boardTitle: string;
  /** 첨부파일 */
  boardFilePath?: string;
  /**
   * 노출여부 1:보임, 0:안보임
   * @example 0
   */
  isVisible?: 0 | 1;
  /**
   * 0:다들보임, 1:나만보임
   * @example 0
   */
  isSecret?: 0 | 1;
  /** 뷰 카운트 */
  vCount?: number;
  /**
   * 비회원 학생명
   * @example 홍길동
   */
  anonymousName?: string;
  /**
   * 비회원 전화번호
   * @example 01012345678
   */
  anonymousPhone?: string;
  /**
   * 생성일
   * @format date-time
   */
  createAt: string;
  /**
   * 수정일
   * @format date-time
   */
  updateAt: string;
  /** studentIdx */
  studentIdx: string;
  /** studentName */
  studentName: string;
  /** studentPhone1 */
  studentPhone1: string;
  /** teacherIdx */
  teacherIdx: string;
  /** teacherName */
  teacherName: string;
  /** vodProductIdx */
  vodProductIdx: string;
  /** vodProductName */
  vodProductName: string;
  /** vodProductNameForStudents */
  vodProductNameForStudents: string;
  /** boardClassIdx */
  boardClassIdx: string;
  /** boardClassTitle */
  boardClassTitle: string;
  /** boardCategoryIdx */
  boardCategoryIdx: string;
  /** boardCategory */
  boardCategory: string;
  /** boardCategoryPhotoFilePath */
  boardCategoryPhotoFilePath: string;
}

export interface WpBoardBody {
  total: number;
  data: WpBoardData[];
}

export interface WpBoardResponseDto {
  header: BaseResponseHeader;
  body: WpBoardBody;
}

export interface CreateWpBoardDto {
  /** 타이틀 */
  boardTitle: string;
  /** 내용 */
  boardContent: string;
  /** 첨부파일 */
  boardFilePath?: string;
  /**
   * 0:다들보임, 1:나만보임
   * @example 0
   */
  isSecret?: 0 | 1;
  /** 상품에 대한 질문일 경우 */
  vodProductIdx?: number;
  /** boardClassIdx */
  boardClassIdx: number;
  /** boardCategoryIdx */
  boardCategoryIdx: number;
}

export interface UpdateWpBoardDto {
  /** 타이틀 */
  boardTitle: string;
  /** 내용 */
  boardContent: string;
  /** 첨부파일 */
  boardFilePath?: string;
}

export interface CreateWpCommentDto {
  /** 내용 */
  boardComment: string;
  /** boardIdx */
  boardIdx: number;
}

export interface UpdateWpCommentDto {
  /** 내용 */
  boardComment: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateWpCocommentDto {
  /** 내용 */
  boardCocomment: string;
  /** boardCommentIdx */
  boardCommentIdx: number;
}

export interface UpdateWpCocommentDto {
  /** 내용 */
  boardCocomment: string;
  /**
   * 노출여부
   * @example 0
   */
  isVisible?: 0 | 1;
}

export interface CreateWpBoardBfDto {
  /** 타이틀 */
  boardTitle: string;
  /** 내용 */
  boardContent: string;
  /** 첨부파일 */
  boardFilePath?: string;
  /**
   * 비회원 학생명
   * @example 홍길동
   */
  anonymousName?: string;
  /**
   * 비회원 전화번호
   * @example 01012345678
   */
  anonymousPhone?: string;
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** 상품에 대한 질문일 경우 */
  vodProductIdx?: number;
  /** boardClassIdx */
  boardClassIdx: number;
  /** boardCategoryIdx */
  boardCategoryIdx: number;
}

export interface Answered {
  /** 문제 INDEX */
  questionIdx: number;
  /** 회원 입력값 */
  studentAnswered?: string;
  /** 문제풀이시간 */
  studentDuration: number;
}

export interface SetQuestionsDto {
  /** 스케쥴 INDEX */
  vodScheduleIdx: number;
  /** 시험지 INDEX */
  questionsIdx: number;
  answered: Answered[];
}

export interface UsersControllerTeacherCheckChangePasswordMailParams {
  /**
   * 아이디
   * @example lydian@lydianlabs.kr
   */
  teacherId: string;
  /** @example demo-vod.learnex.kr/admin */
  tKey: string;
  code: string;
}

export interface TeachersControllerReadEvaluationParams {
  /**
   * 검색시작일
   * @example 2023-09-01
   */
  searchingStartDate: string;
  /**
   * 검색종료일
   * @example 2023-09-01
   */
  searchingEndDate: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'teacherEvaluationScore';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'teacherEvaluationScore' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface TeachersControllerReadTeacherBreaktimeParams {
  /** 강사 index */
  teacherIdx?: number;
  /** 0,1,2,3,4,5,6 */
  week?: string;
}

export interface TeachersControllerReadTeachersParams {
  /**
   * 강사상태 0:대기,8:정지, 9:퇴사
   * @example all
   */
  teacherStatus: 'all' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  /**
   * 검색컬럼명
   * @example all
   */
  searchingKey: 'all' | 'teacherId' | 'teacherName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?:
    | 'teacherId'
    | 'teacherName'
    | 'teacherEmail'
    | 'teacherPhone1'
    | 'teacherSex'
    | 'teacherAge'
    | 'teacherPhotoFilePath'
    | 'teacherStatus'
    | 'teacherRole';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface StudentsControllerReadStudentReivewParams {
  /** 특정 학생 것만 조회 */
  studentIdx?: number;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface StudentsControllerReadStudentsParams {
  /** 상품인덱스 */
  vodProductIdx?: string;
  /** 상품인덱스가 있을때만 보냄, 0:수강중, 1:수강종료 */
  vodLessonStatus?: string;
  /** 가입경로 */
  joinPath?: string;
  /** 회원상태 0:대기,8:정지,9:탈퇴 */
  studentStatus?: 'all' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  /** 검색컬럼명 */
  searchingKey:
    | 'all'
    | 'studentId'
    | 'studentName'
    | 'studentPhone1'
    | 'studentPhone2'
    | 'customInfo1'
    | 'customInfo2'
    | 'customInfo3';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?:
    | 'studentIdx'
    | 'studentId'
    | 'studentName'
    | 'studentPhone1'
    | 'studentPhone2'
    | 'customInfo1'
    | 'customInfo2'
    | 'customInfo3'
    | 'studentPhoneAd'
    | 'studentStatus';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface StudentsControllerReadStudents2Params {
  /** 상품인덱스 */
  vodProductIdx: string;
}

export interface StudentsControllerReadPaymentStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface StudentsControllerDownStudentsParams {
  /** 상품인덱스 */
  vodProductIdx?: string;
  /** 상품인덱스가 있을때만 보냄, 0:수강중, 1:수강종료 */
  vodLessonStatus?: string;
  /** 가입경로 */
  joinPath?: string;
  /** 회원상태 0:대기,8:정지,9:탈퇴 */
  studentStatus?: 'all' | '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  /** 검색컬럼명 */
  searchingKey:
    | 'all'
    | 'studentId'
    | 'studentName'
    | 'studentPhone1'
    | 'studentPhone2'
    | 'customInfo1'
    | 'customInfo2'
    | 'customInfo3';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?:
    | 'studentIdx'
    | 'studentId'
    | 'studentName'
    | 'studentPhone1'
    | 'studentPhone2'
    | 'customInfo1'
    | 'customInfo2'
    | 'customInfo3'
    | 'studentPhoneAd'
    | 'studentStatus';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadTrafficStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface VodControllerReadTranscodeStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface VodControllerReadLessonStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface VodControllerGetUploadUrlParams {
  /** 입력한 제목을 컨텐츠의 제목으로 강제지정합니다. 이 값을 보내지 않거나 빈 값으로 보내면 기본적으로 파일명이 제목으로 사용됩니다. */
  title: string;
  /** 암호화 업로드 여부 0: false, 1: true */
  is_encryption_upload: '0' | '1';
  /** 패스스로우 여부 0: false, 1: true */
  is_passthrough?: '0' | '1';
}

export interface VodControllerGetTranscodingParams {
  kollus_ufkey: string;
}

export interface VodControllerGetHyflexListParams {
  /** 1, 2, 3 */
  teacherIndexes?: string;
  /** 검색 시작일 */
  startDate?: string;
  /** 검색 종료일 */
  endDate?: string;
}

export interface VodControllerGetKollusContentParams {
  /** sorting 컬럼명 */
  sortBy?: 'original_file_name' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadReferenceParams {
  /** 챕터클릭후 자료추가 버튼을 누르면 이 값을 넣고 조회한다.(vodCategoryCodeL2) */
  vodCategoryCode?: string;
  /** 강의클릭후 자료추가 버튼을 누르면 이 값을 넣고 조회한다. */
  vodContentIdx?: string;
  /** 교재,자료,과제 택1 */
  vodProductFileCategory?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodProductFileName';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadVodCategoryParams {
  /** 카테고리 레벨(0은 전체) */
  categoryLevel: 0 | 1 | 2;
  /** 카테고리 코드 */
  categoryCode?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodCategoryName';
  /** 검색값 */
  searchingVal?: string;
}

export interface VodControllerReadVodCategoryL1Params {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodCategoryName' | 'vodCategoryCode';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadVodContentsParams {
  /** 카테고리 코드 */
  categoryCode?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodContentTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'vodContentTitle' | 'vodCategoryNameL1' | 'vodCategoryNameL2';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadVodProductsParams {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodProductName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'vodProductName' | 'vodProductNameForStudents' | 'vodProductPrice' | 'vodProductStatus';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadVodProducts2Params {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodProductName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'vodProductName' | 'vodProductNameForStudents' | 'vodProductPrice' | 'vodProductStatus';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadVodLessonsParams {
  liveGroup?: string;
  vodProductIdx?: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt' | 'startDate' | 'endDate';
  /** 검색 시작일 */
  searchingStartDate?: string;
  /** 검색 종료일 */
  searchingEndDate?: string;
  /** 레슨상태 0:수강,8:보류,9:종강 */
  lessonStatus: 'all' | '0' | '8' | '9';
  /** 검색컬럼명 */
  searchingKey: 'all' | 'studentIdx' | 'studentId' | 'studentName' | 'studentPhone' | 'productName';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface VodControllerReadVodLessons2Params {
  liveGroup?: string;
  vodProductIdx?: string;
  /** 검색컬럼명 */
  searchingDateKey: 'all' | 'createAt' | 'startDate' | 'endDate';
  /** 검색 시작일 */
  searchingStartDate?: string;
  /** 검색 종료일 */
  searchingEndDate?: string;
  /** 레슨상태 0:수강,8:보류,9:종강 */
  lessonStatus: 'all' | '0' | '8' | '9';
  /** 검색컬럼명 */
  searchingKey: 'all' | 'studentIdx' | 'studentId' | 'studentName' | 'studentPhone' | 'productName';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface VodControllerReadAttendanceParams {
  /** 상품 INDEX */
  vodProductIdx: string;
  /** 레슨 INDEX */
  vodLessonIdx?: string;
  /**
   * 검색시작일
   * @example 2023-09-01
   */
  searchingStartDate?: string;
  /**
   * 검색종료일
   * @example 2023-09-01
   */
  searchingEndDate?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'studentIdx' | 'studentId' | 'studentName' | 'studentPhone1';
  /** 검색값 */
  searchingVal?: string;
  /** 분반 INDEX */
  vodClassIdx?: string;
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface VodControllerRequestChangeScheduleParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** vodScheduleChangeIdx */
  vodScheduleChangeIdx?: number;
  /** student, teacher */
  isRequester?: string;
  /** 0,1,2 (0 요청 1 승인 2 거부) */
  isConfirm?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadEvaluationParams {
  /**
   * 검색시작일
   * @example 2023-09-01
   */
  searchingStartDate: string;
  /**
   * 검색종료일
   * @example 2023-09-01
   */
  searchingEndDate: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodEvaluationScore';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'vodEvaluationScore' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadEvaluationStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface VodControllerReadQuestionCategoryParams {
  /** 안보내면 L1 조회, 보내면, 보내면 L2를 조회함 */
  questionCategoryL1Idx?: number;
}

export interface VodControllerReadQuestionParams {
  /** 시험지에 등록된 자료를 분류하기 위해 이 값을 넣음 */
  questionsIdx?: number;
  /** @example 1,2,3,4~ */
  questionIndexes?: string;
  /**
   * 난이도 0,1,2~10
   * @example 0,1,2,3,4,5,6,7,8,9,10
   */
  questionDifficulty: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'questionTilte' | 'tags';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'questionIdx' | 'questionTilte';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface VodControllerReadQuestionsParams {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'questionsTilte';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'questionsIdx' | 'questionsTilte';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface PaymentControllerDownPaymentParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** 결제상태 0:결제창호출, 1:결제완료 */
  searchingStatus: 'all' | '0' | '1';
  /** 검색컬럼명 */
  searchingKey:
    | 'all'
    | 'studentIdx'
    | 'studentId'
    | 'studentName'
    | 'studentPhone1'
    | 'vodProductIdx'
    | 'vodProductName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'studentId' | 'studentName' | 'studentPhone1';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface PaymentControllerReadPaymentsParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** 결제상태 0:결제창호출, 1:결제완료 */
  searchingStatus: 'all' | '0' | '1';
  /** 검색컬럼명 */
  searchingKey:
    | 'all'
    | 'studentIdx'
    | 'studentId'
    | 'studentName'
    | 'studentPhone1'
    | 'vodProductIdx'
    | 'vodProductName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'studentId' | 'studentName' | 'studentPhone1';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface PaymentControllerReadPaymentStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface NoticeControllerReadNoticesParams {
  /** 학생페이지 노출여부 1:보임, 0:안보임 */
  isVisible: 'all' | '0' | '1';
  /** 학생페이지 노출여부 1:보임, 0:안보임 */
  isPop: 'all' | '0' | '1';
  /** 공지카테고리 IDX */
  noticeCategoryIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'creatAt' | 'noticeTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'noticeTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface FaqControllerReadFaqsParams {
  /** 학생페이지 노출여부 1:보임, 0:안보임 */
  isVisible: 'all' | '0' | '1';
  /** FAQ카테고리 IDX */
  faqCategoryIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'creatAt' | 'faqTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'faqTitle' | 'creatAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface QnaControllerReadQnasParams {
  /** 학생 Idx */
  studentIdx?: string;
  /** QNA카테고리 Idx */
  qnaCategoryIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'creatAt' | 'qnaTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'qnaTitle' | 'creatAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface QnaControllerReadQnaStatisticsParams {
  /**
   * 검색 년/월
   * @example 2023/2023-02
   */
  searchingDate: string;
}

export interface CouponControllerReadCouponGroupParams {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'couponName' | 'studentName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'couponExpiredDate' | 'createAt' | 'couponName' | 'studentName';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface CouponControllerReadCouponParams {
  /** sorting 컬럼명 */
  sortBy?: 'couponUsedDate' | 'studentName';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  couponGroupCode: string;
}

export interface SystemControllerReadAdminStatisticsParams {
  /**
   * 검색 월
   * @example 2023-02
   */
  searchingDate: string;
}

export interface LiveControllerCheckTeachersParams {
  /**
   * 개강일
   * @format date-time
   * @example 2023-03-01
   */
  startDate: string;
  /**
   * 요일, 시작시간(0:일~6:토)(2:0800,4:1300)
   * @example 2:0800,4:1300
   */
  weekAndTime: string;
  /**
   * 수업당 진행시간
   * @example 5
   */
  durationPerLesson: number;
  /**
   * 구매수
   * @example 4
   */
  totalQuantity: number;
}

export interface MessageControllerReadMessageParams {
  /** 수신번호 */
  recipientNo: string;
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface MessageControllerReadAlimtalkTemplateParams {
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface BoardControllerReadBoardCategoryParams {
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface BoardControllerReadBoardsParams {
  /** 학생페이지 노출여부 1:보임, 0:안보임 */
  isVisible: 'all' | '0' | '1';
  /** boardClassIdx */
  boardClassIdx?: string;
  /** boardCategoryIdx */
  boardCategoryIdx?: string;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'creatAt' | 'boardTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'boardTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpStudentControllerWpCouponParams {
  /** 쿠폰상태(초기값은 not use) */
  status: 'all' | 'not use' | 'use' | 'expired';
}

export interface WpVodControllerGetDashboardParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpVodControllerReadVodCategoryParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** 카테고리 레벨(0은 전체) */
  categoryLevel: 0 | 1 | 2;
  /** 카테고리 코드 */
  categoryCode?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodCategoryName';
  /** 검색값 */
  searchingVal?: string;
}

export interface WpVodControllerGetProductsParams {
  /** 강사 IDX */
  teacherIdx?: number;
  /** 검색값 */
  searchingVal?: string;
  /** 별점: all(전체),2(이상),3(이상),4(이상) */
  vodEvaluationScore?: 'all' | '2' | '3' | '4';
  /** 상품 난이도 1:Beginner,2:Intermediate,3:Expert (다중선택) */
  vodProductLevel?: 'all' | '1' | '2' | '3';
  /** VOD 상품카테고리IDX (다중선택) */
  vodProductCategoryIdx?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpVodControllerGetProductParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  vodProductIdx: string;
}

export interface WpVodControllerGetReviewsParams {
  /**
   * 별점 몇개 이상만 보여줄지
   * @example 1
   */
  overEvaluationScore?: number;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpVodControllerGetReviewParams {
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
  /** @example demo-vod.learnex.kr */
  sKey: string;
  vodProductIdx: string;
}

export interface WpVodControllerGetCategorysParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpVodControllerGetTeachersParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpVodControllerGetTeachers2Params {
  /** 강사 IDX */
  teacherIdx?: number;
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpVodControllerReadLessonsParams {
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodProductName';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'paid' | 'viewed';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
}

export interface WpVodControllerDownloadLessonFileParams {
  fileGroupIdx: string;
  vodLessonIdx: string;
}

export interface WpVodControllerGetReferenceParams {
  /** 챕터로 조회 */
  vodCategoryCode?: number;
  /** 강의로 조회 */
  vodContentIdx?: number;
  vodLessonIdx: string;
}

export interface WpVodControllerTeacherReivewParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** 특정 강사 조회 */
  teacherIdx?: number;
  /** 특정 학생 것만 조회 */
  studentIdx?: number;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpVodControllerGetUrlParams {
  vodScheduleIdx: string;
  vodLessonIdx: string;
}

export interface WpVodControllerGetRequestChangeScheduleParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** vodScheduleChangeIdx */
  vodScheduleChangeIdx?: number;
  /** student, teacher */
  isRequester?: string;
  /** 0,1,2 (0 요청 1 승인 2 거부) */
  isConfirm?: string;
  /** sorting 컬럼명 */
  sortBy?: 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpPaymentControllerReadPaymentParams {
  /**
   * 검색 기간(시작일)
   * @example 2023-02-01
   */
  searchingStartDate: string;
  /**
   * 검색 기간(종료일)
   * @example 2023-02-01
   */
  searchingEndDate: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'vodProductName';
  /** 검색값 */
  searchingVal?: string;
  /**
   * page
   * @example 1
   */
  page: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize: number;
}

export interface WpNoticeControllerReadWpNoticeCategoryParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpNoticeControllerReadWpNoticesParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** 학생페이지 노출여부 1:보임, 0:안보임 */
  isPop: 'all' | '0' | '1';
  /** 공지카테고리 IDX */
  noticeCategoryIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'createAt' | 'noticeTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'noticeTitle' | 'createAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpNoticeControllerReadNoticeParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  noticeIdx: string;
}

export interface WpFaqControllerReadWpFaqCategoryParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpFaqControllerReadWpFaqsParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** FAQ카테고리 IDX */
  faqCategoryIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'createAt' | 'faqTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'faqTitle' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpFaqControllerReadFaqParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  faqIdx: string;
}

export interface WpQnaControllerReadWpQnaCategoryParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpQnaControllerReadWpQnasParams {
  /** QNA카테고리 Idx */
  qnaCategoryIdx?: string;
  /** 학생IDX */
  studentIdx?: string;
  /** 상품IDX */
  vodProductIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'createAt' | 'qnaTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'qnaTitle' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpQnaControllerReadWpQnasBeforeLoignParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'createAt' | 'qnaTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'qnaTitle' | 'createAt';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpQnaControllerReadFaqBeforeLoignParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  qnaIdx: string;
}

export interface WpBoardControllerReadWpBoardClassParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
}

export interface WpBoardControllerReadWpBoardCategoryParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** boardClassIdx */
  boardClassIdx: number;
}

export interface WpBoardControllerReadWpBoardsParams {
  /** boardClassIdx */
  boardClassIdx: string;
  /** boardCategoryIdx */
  boardCategoryIdx?: string;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'creatAt' | 'boardTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'boardTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpBoardControllerReadWpBoardsBfParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  /** boardClassIdx */
  boardClassIdx: string;
  /** boardCategoryIdx */
  boardCategoryIdx?: string;
  /** vodProductIdx */
  vodProductIdx?: string;
  /** 검색컬럼명 */
  searchingKey: 'all' | 'creatAt' | 'boardTitle';
  /** 검색값 */
  searchingVal?: string;
  /** sorting 컬럼명 */
  sortBy?: 'boardTitle' | 'creatAt' | 'vCount';
  /**
   * sorting
   * @example DESC
   */
  sortOrder?: 'DESC' | 'ASC';
  /**
   * page
   * @example 1
   */
  page?: number;
  /**
   * rows per page
   * @example 25
   */
  pageSize?: number;
}

export interface WpBoardControllerReadWpBoardBfParams {
  /** @example demo-vod.learnex.kr */
  sKey: string;
  boardIdx: number;
}

export interface WpQuestionsControllerGetQustionsParams {
  questionsHistoryGroup: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        },
        signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
        body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title VOD - API
 * @version 0.0.1
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  vod = {
    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerMaintenance
     * @summary [공통] 유지보수
     * @request POST:/vod/api/user/maintenance
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `void` 서버에러
     */
    usersControllerMaintenance: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, void>({
        path: `/vod/api/user/maintenance`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerDesign
     * @summary [공통] 디자인
     * @request POST:/vod/api/user/design
     * @response `201` `BaseResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `500` `void` 서버에러
     */
    usersControllerDesign: (data: DesignDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, void>({
        path: `/vod/api/user/design`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerTeacherSignup
     * @summary [체험판 가입]
     * @request POST:/vod/api/user/t/signup
     * @response `201` `BaseResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `500` `void` 서버에러
     */
    usersControllerTeacherSignup: (data: SignupDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, void>({
        path: `/vod/api/user/t/signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerTeacherSignin
     * @summary [강사용] 로그인
     * @request POST:/vod/api/user/t/signin
     * @response `201` `SigninResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `500` `void` 서버에러
     */
    usersControllerTeacherSignin: (data: SigninByEmailDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, void>({
        path: `/vod/api/user/t/signin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerTeacherSigninKakao
     * @summary [강사용] 카카오로그인
     * @request POST:/vod/api/user/t/signin/kakao
     * @response `201` `SigninResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `500` `void` 서버에러
     */
    usersControllerTeacherSigninKakao: (data: SigninByKakaoDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, void>({
        path: `/vod/api/user/t/signin/kakao`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerTeacherSigninNaver
     * @summary [강사용] 네이버로그인
     * @request POST:/vod/api/user/t/signin/naver
     * @response `201` `SigninResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `500` `void` 서버에러
     */
    usersControllerTeacherSigninNaver: (data: SigninByNaverDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, void>({
        path: `/vod/api/user/t/signin/naver`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerTeacherChangePasswordMail
     * @summary [강사용] 비밀번호변경 메일발송
     * @request POST:/vod/api/user/t/password
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerTeacherChangePasswordMail: (
      data: TeacherChangePasswordMailDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/t/password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerTeacherCheckChangePasswordMail
     * @summary [강사용] 비밀번호변경 메일발송 코드확인
     * @request GET:/vod/api/user/t/password/{code}
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerTeacherCheckChangePasswordMail: (
      { code, ...query }: UsersControllerTeacherCheckChangePasswordMailParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/t/password/${code}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerTeacherChangingPassword
     * @summary [강사용] 비밀번호변경
     * @request PUT:/vod/api/user/t/password/{code}
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerTeacherChangingPassword: (
      code: string,
      data: TeacherChangingPasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/t/password/${code}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentVerificationPhone
     * @summary [학생용] 번호인증
     * @request POST:/vod/api/user/s/verificationPhone
     * @response `201` `VerificationPhoneResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerStudentVerificationPhone: (
      data: StudentVerificationPhoneDto,
      params: RequestParams = {}
    ) =>
      this.request<VerificationPhoneResponseDto, BaseResponseDto>({
        path: `/vod/api/user/s/verificationPhone`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentSignup
     * @summary [학생용] 가입
     * @request POST:/vod/api/user/s/signup
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerStudentSignup: (data: StudentSignupDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/s/signup`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentCheckMail
     * @summary [학생용] 가입시 이메일 확인코드
     * @request POST:/vod/api/user/s/checkMail
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerStudentCheckMail: (data: StudentCheckMailDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/s/checkMail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentSignupSocial
     * @summary [학생용] 소셜가입
     * @request POST:/vod/api/user/s/signup/social
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerStudentSignupSocial: (
      data: StudentSignupSocialDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/s/signup/social`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentSignin
     * @summary [학생용] 로그인
     * @request POST:/vod/api/user/s/signin
     * @response `201` `SigninResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `500` `void` 서버에러
     */
    usersControllerStudentSignin: (data: SigninByEmailDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, void>({
        path: `/vod/api/user/s/signin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentSigninKakao
     * @summary [학생용] 카카오로그인
     * @request POST:/vod/api/user/s/signin/kakao
     * @response `201` `SigninResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `444` `void` 소셜정보 찾을수 없음. 가입하기로 이동.{ id:소셜고유값, email:소셜이메일}
     * @response `500` `void` 서버에러
     */
    usersControllerStudentSigninKakao: (data: SigninByKakaoDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, void>({
        path: `/vod/api/user/s/signin/kakao`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentSigninNaver
     * @summary [학생용] 네이버 로그인
     * @request POST:/vod/api/user/s/signin/naver
     * @response `201` `SigninResponseDto` 성공
     * @response `403` `void` 아이디/패스워드 에러
     * @response `444` `void` 소셜정보 찾을수 없음. 가입하기로 이동.{ id:소셜고유값, email:소셜이메일}
     * @response `500` `void` 서버에러
     */
    usersControllerStudentSigninNaver: (data: SigninByNaverDto, params: RequestParams = {}) =>
      this.request<SigninResponseDto, void>({
        path: `/vod/api/user/s/signin/naver`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentChangePasswordMail
     * @summary [학생용] 비밀번호변경 메일발송
     * @request POST:/vod/api/user/s/password
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerStudentChangePasswordMail: (
      data: StudentChangePasswordMailDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/s/password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentCheckChangePasswordMail
     * @summary [학생용] 비밀번호변경 메일발송 코드확인
     * @request GET:/vod/api/user/s/password/{code}
     * @response `200` `StudentCheckChangePasswordMailResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerStudentCheckChangePasswordMail: (code: string, params: RequestParams = {}) =>
      this.request<StudentCheckChangePasswordMailResponseDto, BaseResponseDto>({
        path: `/vod/api/user/s/password/${code}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 공통
     * @name UsersControllerStudentChangingPassword
     * @summary [학생용] 비밀번호변경
     * @request PUT:/vod/api/user/s/password/{code}
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    usersControllerStudentChangingPassword: (
      code: string,
      data: StudentChangingPasswordDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/user/s/password/${code}`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags xApi
     * @name XApiControllerStatement
     * @request POST:/vod/api/xapi
     * @response `201` `void`
     */
    xApiControllerStatement: (data: StatementDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/xapi`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerReadMyData
     * @summary 내정보(강사)
     * @request GET:/vod/api/teacher/me
     * @secure
     * @response `200` `TeachersResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerReadMyData: (params: RequestParams = {}) =>
      this.request<TeachersResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerTeacherPhotoFileUpload
     * @summary  강사 사진 업로드
     * @request POST:/vod/api/teacher/photo/upload
     * @secure
     * @response `201` `TeacherFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerTeacherPhotoFileUpload: (
      data: TeacherPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<TeacherFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerReadEvaluation
     * @summary 리뷰 조회
     * @request GET:/vod/api/teacher/reviews
     * @secure
     * @response `200` `TeacherEvaluationResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerReadEvaluation: (
      query: TeachersControllerReadEvaluationParams,
      params: RequestParams = {}
    ) =>
      this.request<TeacherEvaluationResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerUpdateEvaluation
     * @summary 리뷰 수정
     * @request PUT:/vod/api/teacher/reviews/{teacherEvaluationIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerUpdateEvaluation: (
      teacherEvaluationIdx: string,
      data: UpdateTeacherEvaluationAnsDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/reviews/${teacherEvaluationIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerCreateTeacherCategory
     * @summary 강사 카테고리 생성
     * @request POST:/vod/api/teacher/category
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerCreateTeacherCategory: (
      data: CreateTeacherCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerReadTeacherCategroy
     * @summary 강사 카테고리 조회
     * @request GET:/vod/api/teacher/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerReadTeacherCategroy: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/category`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerUpdateTeacherCategory
     * @summary 강사 카테고리 수정
     * @request PUT:/vod/api/teacher/category/{teacherCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerUpdateTeacherCategory: (
      teacherCategoryIdx: string,
      data: UpdateTeacherCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/category/${teacherCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerDeleteTeacherCategory
     * @summary 강사 카테고리 삭제
     * @request DELETE:/vod/api/teacher/category/{teacherCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerDeleteTeacherCategory: (
      teacherCategoryIdx: string,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/category/${teacherCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerCreateTeacherBreaktime
     * @summary 강사 휴게시간 생성
     * @request POST:/vod/api/teacher/breaktime
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerCreateTeacherBreaktime: (
      data: CreateTeacherBreaktimeDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/breaktime`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerReadTeacherBreaktime
     * @summary 강사 휴게시간 조회
     * @request GET:/vod/api/teacher/breaktime
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerReadTeacherBreaktime: (
      query: TeachersControllerReadTeacherBreaktimeParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/breaktime`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerCreateTeacher
     * @summary 강사 생성
     * @request POST:/vod/api/teacher
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerCreateTeacher: (data: CreateTeacherDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerReadTeachers
     * @summary 강사들 조회
     * @request GET:/vod/api/teacher
     * @secure
     * @response `200` `TeachersResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerReadTeachers: (
      query: TeachersControllerReadTeachersParams,
      params: RequestParams = {}
    ) =>
      this.request<TeachersResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerReadTeacher
     * @summary 강사 조회
     * @request GET:/vod/api/teacher/{teacherIdx}
     * @secure
     * @response `200` `TeacherResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerReadTeacher: (teacherIdx: string, params: RequestParams = {}) =>
      this.request<TeacherResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/${teacherIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name TeachersControllerUpdateTeacher
     * @summary 강사 수정
     * @request PUT:/vod/api/teacher/{teacherIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    teachersControllerUpdateTeacher: (
      teacherIdx: string,
      data: UpdateTeacherDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/teacher/${teacherIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerCreateStudentReivew
     * @summary 학생리뷰작성
     * @request POST:/vod/api/student/review
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerCreateStudentReivew: (
      data: CreateStudentEvaluationDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/student/review`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerUpdateStudentReivew
     * @summary 학생리뷰수정
     * @request PUT:/vod/api/student/review
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerUpdateStudentReivew: (
      data: UpdateStudentEvaluationDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/student/review`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerReadStudentReivew
     * @summary 학생리뷰조회
     * @request GET:/vod/api/student/review
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerReadStudentReivew: (
      query: StudentsControllerReadStudentReivewParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/student/review`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerTest
     * @summary pdf -> img + watermark
     * @request POST:/vod/api/student/test
     * @response `201` `void`
     */
    studentsControllerTest: (data: TestFileUploadDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/student/test`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerStudentPhotoFileUpload
     * @summary  학생 사진 업로드
     * @request POST:/vod/api/student/photo/upload
     * @secure
     * @response `201` `StudentFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerStudentPhotoFileUpload: (
      data: StudentPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<StudentFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/student/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerCreateStudent
     * @summary 학생 생성
     * @request POST:/vod/api/student
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerCreateStudent: (data: CreateStudentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/student`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerReadStudents
     * @summary 학생들 조회
     * @request GET:/vod/api/student
     * @secure
     * @response `200` `StudentsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerReadStudents: (
      query: StudentsControllerReadStudentsParams,
      params: RequestParams = {}
    ) =>
      this.request<StudentsResponseDto, BaseResponseDto>({
        path: `/vod/api/student`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerReadStudents2
     * @summary 학생들 조회(상품에 추가)
     * @request GET:/vod/api/student/product
     * @secure
     * @response `200` `StudentsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerReadStudents2: (
      query: StudentsControllerReadStudents2Params,
      params: RequestParams = {}
    ) =>
      this.request<StudentsResponseDto, BaseResponseDto>({
        path: `/vod/api/student/product`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerReadPaymentStatistics
     * @summary 학생들 통계
     * @request GET:/vod/api/student/statistics
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerReadPaymentStatistics: (
      query: StudentsControllerReadPaymentStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/student/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerDownStudents
     * @summary 학생들: 엑셀 다운
     * @request GET:/vod/api/student/download
     * @secure
     * @response `200` `void`
     */
    studentsControllerDownStudents: (
      query: StudentsControllerDownStudentsParams,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/vod/api/student/download`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerReadOneStudent
     * @summary 학생 조회
     * @request GET:/vod/api/student/{studentIdx}
     * @secure
     * @response `200` `OneStudentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerReadOneStudent: (studentIdx: string, params: RequestParams = {}) =>
      this.request<OneStudentResponseDto, BaseResponseDto>({
        path: `/vod/api/student/${studentIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name StudentsControllerUpdateStudent
     * @summary 학생 수정
     * @request PUT:/vod/api/student/{studentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    studentsControllerUpdateStudent: (
      studentIdx: string,
      data: UpdateStudentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/student/${studentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerKollusCallbackUploaded
     * @request POST:/vod/api/vod/callback/uploaded
     * @response `201` `void`
     */
    vodControllerKollusCallbackUploaded: (
      data: KollusCallbackUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/vod/api/vod/callback/uploaded`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerKollusCallbackTranscoded
     * @request POST:/vod/api/vod/callback/transcoded
     * @response `201` `void`
     */
    vodControllerKollusCallbackTranscoded: (
      data: KollusCallbackUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/vod/api/vod/callback/transcoded`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerKollusCallback
     * @request POST:/vod/api/vod/callback
     * @response `201` `void`
     */
    vodControllerKollusCallback: (data: KollusCallbackDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/vod/callback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadTrafficStatistics
     * @summary VOD 트래픽 통계
     * @request GET:/vod/api/vod/statistics/traffic
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadTrafficStatistics: (
      query: VodControllerReadTrafficStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/statistics/traffic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadTranscodeStatistics
     * @summary VOD 트랜스코딩 통계
     * @request GET:/vod/api/vod/statistics/transcode
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadTranscodeStatistics: (
      query: VodControllerReadTranscodeStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/statistics/transcode`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadSpaceStatistics
     * @summary VOD 용량 통계
     * @request GET:/vod/api/vod/statistics/space
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadSpaceStatistics: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/statistics/space`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadLessonStatistics
     * @summary VOD 수업개설 통계
     * @request GET:/vod/api/vod/statistics/vodLesson
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadLessonStatistics: (
      query: VodControllerReadLessonStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/statistics/vodLesson`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetUploadUrl
     * @summary VOD 업로드 URL 획득
     * @request GET:/vod/api/vod
     * @secure
     * @response `200` `VodUploadUrlResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetUploadUrl: (
      query: VodControllerGetUploadUrlParams,
      params: RequestParams = {}
    ) =>
      this.request<VodUploadUrlResponseDto, BaseResponseDto>({
        path: `/vod/api/vod`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetTranscoding
     * @summary VOD 트랜스코딩상태확인
     * @request GET:/vod/api/vod/transcoding
     * @secure
     * @response `200` `VodUploadUrlResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetTranscoding: (
      query: VodControllerGetTranscodingParams,
      params: RequestParams = {}
    ) =>
      this.request<VodUploadUrlResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/transcoding`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetHyflexList
     * @summary hyflex 예정수업들 조회
     * @request GET:/vod/api/vod/hyflex
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetHyflexList: (
      query: VodControllerGetHyflexListParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/hyflex`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetHyflex2
     * @summary hyflex 강의실 입장
     * @request GET:/vod/api/vod/hyflex2/{vodScheduleIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetHyflex2: (vodScheduleIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/hyflex2/${vodScheduleIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetHyflex
     * @summary hyflex 예정수업조회(15분전 조회가능)
     * @request GET:/vod/api/vod/hyflex/{vodScheduleIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetHyflex: (vodScheduleIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/hyflex/${vodScheduleIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateLarahv
     * @summary LARAHV 생성(플래쉬강의 생성)
     * @request POST:/vod/api/vod/larahv
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateLarahv: (data: CreateRoomDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/larahv`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetLarahvContent
     * @summary LARAHV 컨텐츠 목록 조회
     * @request POST:/vod/api/vod/larahv/content
     * @secure
     * @response `200` `LarahvRecordResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetLarahvContent: (data: GetLarahvContentDto, params: RequestParams = {}) =>
      this.request<LarahvRecordResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/larahv/content`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerLarahvToKollus
     * @summary LARAHV to Kollus 이관
     * @request POST:/vod/api/vod/larahv/kollus
     * @secure
     * @response `200` `LarahvRecordResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerLarahvToKollus: (data: LarahvToKollusDto, params: RequestParams = {}) =>
      this.request<LarahvRecordResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/larahv/kollus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetKollusContent
     * @summary Kollus 컨텐츠 목록 조회
     * @request GET:/vod/api/vod/kollus/content
     * @secure
     * @response `200` `VodKollusContentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetKollusContent: (
      query: VodControllerGetKollusContentParams,
      params: RequestParams = {}
    ) =>
      this.request<VodKollusContentResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/content`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteKollusContent
     * @summary Kollus 컨텐츠 삭제
     * @request PUT:/vod/api/vod/kollus/content
     * @secure
     * @response `200` `VodKollusContentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteKollusContent: (data: DeleteKollusContentDto, params: RequestParams = {}) =>
      this.request<VodKollusContentResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/kollus/content`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCategoryFileUpload
     * @summary 강의자료 업로드
     * @request POST:/vod/api/vod/category/photo/upload
     * @secure
     * @response `201` `CategoryFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCategoryFileUpload: (data: CategoryFileUploadDto, params: RequestParams = {}) =>
      this.request<CategoryFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/category/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateReference
     * @summary 강의자료실 파일추가
     * @request POST:/vod/api/vod/reference
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateReference: (data: CreateReferenceDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadReference
     * @summary 강의자료실 조회
     * @request GET:/vod/api/vod/reference
     * @secure
     * @response `200` `ReadReferenceResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadReference: (
      query: VodControllerReadReferenceParams,
      params: RequestParams = {}
    ) =>
      this.request<ReadReferenceResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteReference
     * @summary 강의자료실 삭제
     * @request DELETE:/vod/api/vod/reference
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteReference: (data: DeleteReferenceDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateReferenceConnection1
     * @summary 강의자료-수업 일괄 삭제 및 연결
     * @request PUT:/vod/api/vod/reference/connection1
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateReferenceConnection1: (
      data: UpdateReferenceConnection1Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference/connection1`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateReferenceConnection2
     * @summary 강의자료-챕터 일괄 삭제 및 연결
     * @request PUT:/vod/api/vod/reference/connection2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateReferenceConnection2: (
      data: UpdateReferenceConnection2Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference/connection2`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateReferenceConnection3
     * @summary 강의자료-수업/챕터 추가 연결
     * @request PUT:/vod/api/vod/reference/connection3
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateReferenceConnection3: (
      data: UpdateReferenceConnection3Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference/connection3`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateReferenceDisconnection
     * @summary 강의자료-수업/챕터 연결해제
     * @request DELETE:/vod/api/vod/reference/disconnection/{fileGroupIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateReferenceDisconnection: (fileGroupIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference/disconnection/${fileGroupIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateReference
     * @summary 강의자료실 수정
     * @request PUT:/vod/api/vod/reference/{vodProductFileIdx}
     * @secure
     * @response `200` `ReadReferenceResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateReference: (
      vodProductFileIdx: number,
      data: CreateReferenceDto,
      params: RequestParams = {}
    ) =>
      this.request<ReadReferenceResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reference/${vodProductFileIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateVodCategory
     * @summary VOD 강좌/챕터 추가
     * @request POST:/vod/api/vod/category
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodCategory: (data: CreateVodCategoryDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodCategory
     * @summary VOD 강좌/챕터 조회
     * @request GET:/vod/api/vod/category
     * @secure
     * @response `200` `VodCategoryResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodCategory: (
      query: VodControllerReadVodCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<VodCategoryResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/category`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteVodCategory
     * @summary VOD 강좌/챕터 삭제
     * @request DELETE:/vod/api/vod/category/{vodCategoryCode}
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodCategory: (vodCategoryCode: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/category/${vodCategoryCode}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateVodCategory
     * @summary VOD 강좌/챕터 수정
     * @request PUT:/vod/api/vod/category/{vodCategoryCode}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodCategory: (
      vodCategoryCode: string,
      data: UpdateVodCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/category/${vodCategoryCode}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodCategoryL1
     * @summary VOD 강좌 조회
     * @request GET:/vod/api/vod/category/L1
     * @secure
     * @response `200` `VodCategoryL1ResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodCategoryL1: (
      query: VodControllerReadVodCategoryL1Params,
      params: RequestParams = {}
    ) =>
      this.request<VodCategoryL1ResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/category/L1`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateVodContent
     * @summary 컨텐츠 생성
     * @request POST:/vod/api/vod/content
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodContent: (data: CreateVodContentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodContents
     * @summary 컨텐츠 조회
     * @request GET:/vod/api/vod/content
     * @secure
     * @response `200` `VodContentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodContents: (
      query: VodControllerReadVodContentsParams,
      params: RequestParams = {}
    ) =>
      this.request<VodContentResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateNumbering
     * @summary 컨텐츠 순서변경
     * @request POST:/vod/api/vod/content/numbering
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateNumbering: (data: UpdateNumberingDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content/numbering`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateBlocking
     * @summary 컨텐츠 블럭(모든 사용자 일괄 적용)
     * @request POST:/vod/api/vod/content/block
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateBlocking: (data: UpdateBlockingDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content/block`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodContent
     * @summary 컨텐츠 한개 조회
     * @request GET:/vod/api/vod/content/{vodContentIdx}
     * @secure
     * @response `200` `VodContentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodContent: (vodContentIdx: string, params: RequestParams = {}) =>
      this.request<VodContentResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content/${vodContentIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateVodContent
     * @summary 컨텐츠 수정
     * @request PUT:/vod/api/vod/content/{vodContentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodContent: (
      vodContentIdx: string,
      data: UpdateVodContentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content/${vodContentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerMediaPlayingUrl
     * @summary 미디어 재생 URL 획득
     * @request GET:/vod/api/vod/content1/{ufkey}
     * @secure
     * @response `200` `VodMediaPlayingUrlResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerMediaPlayingUrl: (ufkey: string, params: RequestParams = {}) =>
      this.request<VodMediaPlayingUrlResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/content1/${ufkey}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateVodProductCategory
     * @summary 상품 카테고리 생성
     * @request POST:/vod/api/vod/product/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProductCategory: (
      data: CreateVodProductCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodProductCategory
     * @summary 상품 카테고리 조회
     * @request GET:/vod/api/vod/product/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProductCategory: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateVodProductCategory
     * @summary 상품 카테고리 수정
     * @request PUT:/vod/api/vod/product/category/{vodProductCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodProductCategory: (
      vodProductCategoryIdx: number,
      data: CreateVodProductCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category/${vodProductCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteVodProductCategory
     * @summary 상품 카테고리 삭제
     * @request DELETE:/vod/api/vod/product/category/{vodProductCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodProductCategory: (
      vodProductCategoryIdx: number,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/category/${vodProductCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerVodProductPhotoFileUpload
     * @summary  상품 사진 업로드
     * @request POST:/vod/api/vod/photo/upload
     * @secure
     * @response `201` `VodProductFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerVodProductPhotoFileUpload: (
      data: VodProductPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<VodProductFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateVodProduct
     * @summary 상품 생성
     * @request POST:/vod/api/vod/product
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodProduct: (data: CreateVodProductDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodProducts
     * @summary 상품들 조회
     * @request GET:/vod/api/vod/product
     * @secure
     * @response `200` `VodProductsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProducts: (
      query: VodControllerReadVodProductsParams,
      params: RequestParams = {}
    ) =>
      this.request<VodProductsResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodProducts2
     * @summary 상품들 조회2
     * @request GET:/vod/api/vod/product2
     * @secure
     * @response `200` `VodProductsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProducts2: (
      query: VodControllerReadVodProducts2Params,
      params: RequestParams = {}
    ) =>
      this.request<VodProductsResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product2`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodProduct
     * @summary 상품 조회
     * @request GET:/vod/api/vod/product/{vodProductIdx}
     * @secure
     * @response `200` `VodProductResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodProduct: (vodProductIdx: number, params: RequestParams = {}) =>
      this.request<VodProductResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/${vodProductIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateProduct
     * @summary 상품 수정
     * @request PUT:/vod/api/vod/product/{vodProductIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateProduct: (
      vodProductIdx: string,
      data: UpdateVodProductDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/product/${vodProductIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateVodClass
     * @summary 분반 생성
     * @request POST:/vod/api/vod/class
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodClass: (data: CreateVodClassDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/class`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodClass
     * @summary 분반 조회
     * @request GET:/vod/api/vod/class/{vodProductIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodClass: (vodProductIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/class/${vodProductIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateVodClass
     * @summary 분반 수정
     * @request PUT:/vod/api/vod/class/{vodClassIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodClass: (
      vodClassIdx: string,
      data: UpdateVodClassDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/class/${vodClassIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteVodClass
     * @summary 분반 삭제
     * @request DELETE:/vod/api/vod/class/{vodClassIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodClass: (vodClassIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/class/${vodClassIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCreateVodLesson
     * @summary 레슨 생성
     * @request POST:/vod/api/vod/lesson
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCreateVodLesson: (data: CreateVodLessonDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodLessons
     * @summary 레슨들 조회
     * @request GET:/vod/api/vod/lesson
     * @secure
     * @response `200` `VodLessonsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodLessons: (
      query: VodControllerReadVodLessonsParams,
      params: RequestParams = {}
    ) =>
      this.request<VodLessonsResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteVodLesson
     * @summary 레슨 삭제
     * @request DELETE:/vod/api/vod/lesson
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteVodLesson: (data: DeleteVodLessonDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodLessons2
     * @summary 레슨들 조회2
     * @request GET:/vod/api/vod/lesson2
     * @secure
     * @response `200` `VodLessonsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodLessons2: (
      query: VodControllerReadVodLessons2Params,
      params: RequestParams = {}
    ) =>
      this.request<VodLessonsResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson2`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadAttendance
     * @summary 출석부 조회
     * @request GET:/vod/api/vod/lesson/attendance
     * @secure
     * @response `200` `VodLessonResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadAttendance: (
      query: VodControllerReadAttendanceParams,
      params: RequestParams = {}
    ) =>
      this.request<VodLessonResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/attendance`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateLessonClass
     * @summary 출석부 분반할당
     * @request POST:/vod/api/vod/lesson/attendance/class
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateLessonClass: (data: UpdateLessonClassDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/attendance/class`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateAttendance
     * @summary 출석부 수정
     * @request PUT:/vod/api/vod/lesson/attendance/{vodScheduleIdx}
     * @secure
     * @response `200` `VodLessonResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateAttendance: (
      vodScheduleIdx: string,
      data: UpdateAttendanceDto,
      params: RequestParams = {}
    ) =>
      this.request<VodLessonResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/attendance/${vodScheduleIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCheckLive
     * @summary LIVE 강사/수업 확인
     * @request POST:/vod/api/vod/check/live
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCheckLive: (data: CheckLiveDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/check/live`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerAddStudents
     * @summary 상품에 학생추가
     * @request POST:/vod/api/vod/lesson/addStudents
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerAddStudents: (data: SetLiveDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/addStudents`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCheckAddSchedule
     * @summary Live 추가전 체크
     * @request POST:/vod/api/vod/lesson/checkAddSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCheckAddSchedule: (data: CheckAddLiveScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/checkAddSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerAddSchedule
     * @summary Live 추가
     * @request POST:/vod/api/vod/lesson/addSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerAddSchedule: (data: AddLiveScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/addSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerCheckChangeSchedule
     * @summary 수업일 변경 요청전 체크(1:1만 가능)
     * @request POST:/vod/api/vod/lesson/checkChangeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerCheckChangeSchedule: (data: CheckChangeScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/checkChangeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerChangeSchedule
     * @summary 수업일 변경 요청(1:1만 가능)
     * @request POST:/vod/api/vod/lesson/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerChangeSchedule: (data: RequestChangeScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/changeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerRequestChangeSchedule
     * @summary 수업일 변경 요청 내역
     * @request GET:/vod/api/vod/lesson/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerRequestChangeSchedule: (
      query: VodControllerRequestChangeScheduleParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/changeSchedule`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerConfirmChangeSchedule
     * @summary 수업일 변경 승인/거부
     * @request PUT:/vod/api/vod/lesson/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerConfirmChangeSchedule: (
      data: ConfirmChangeScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/changeSchedule`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerSubSchedule
     * @summary Live 삭제
     * @request POST:/vod/api/vod/lesson/subSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerSubSchedule: (data: SubLiveScheduleDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/subSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadVodLesson
     * @summary 레슨 조회
     * @request GET:/vod/api/vod/lesson/{vodLessonIdx}
     * @secure
     * @response `200` `VodLessonResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadVodLesson: (vodLessonIdx: string, params: RequestParams = {}) =>
      this.request<VodLessonResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/${vodLessonIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateVodLesson
     * @summary 레슨 수정
     * @request PUT:/vod/api/vod/lesson/{vodLessonIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodLesson: (
      vodLessonIdx: string,
      data: UpdateVodLessonDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/lesson/${vodLessonIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateVodSchedule
     * @summary 스케쥴 수정(block)
     * @request PUT:/vod/api/vod/schedule/{vodScheduleIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateVodSchedule: (
      vodScheduleIdx: string,
      data: UpdateVodScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/schedule/${vodScheduleIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadEvaluation
     * @summary 리뷰 조회
     * @request GET:/vod/api/vod/reviews
     * @secure
     * @response `200` `VodEvaluationResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadEvaluation: (
      query: VodControllerReadEvaluationParams,
      params: RequestParams = {}
    ) =>
      this.request<VodEvaluationResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadEvaluationStatistics
     * @summary 리뷰 통계
     * @request GET:/vod/api/vod/statistics/reviews
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadEvaluationStatistics: (
      query: VodControllerReadEvaluationStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/statistics/reviews`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteEvaluation
     * @summary 리뷰 삭제
     * @request PUT:/vod/api/vod/reviews/{vodEvaluationIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteEvaluation: (vodEvaluationIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/reviews/${vodEvaluationIdx}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerInsertQuestionCategoryL1
     * @summary 문제(퀴즈) 카테고리 L1 입력
     * @request POST:/vod/api/vod/question/category/l1
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerInsertQuestionCategoryL1: (
      data: InsertQuestionCategoryL1Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/category/l1`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerInsertQuestionCategoryL2
     * @summary 문제(퀴즈) 카테고리 L2 입력
     * @request POST:/vod/api/vod/question/category/l2
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerInsertQuestionCategoryL2: (
      data: InsertQuestionCategoryL2Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/category/l2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadQuestionCategory
     * @summary 문제(퀴즈) 카테고리 조회
     * @request GET:/vod/api/vod/question/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadQuestionCategory: (
      query: VodControllerReadQuestionCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/category`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateQuestionCategoryL1
     * @summary 문제(퀴즈) 카테고리 L1 수정
     * @request PUT:/vod/api/vod/question/category/l1/{questionCategoryL1Idx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateQuestionCategoryL1: (
      questionCategoryL1Idx: string,
      data: InsertQuestionCategoryL1Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/category/l1/{questionCategoryL1Idx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteQuestionCategoryL1
     * @summary 문제(퀴즈) 카테고리 L1 삭제
     * @request DELETE:/vod/api/vod/question/category/l1/{questionCategoryL1Idx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteQuestionCategoryL1: (
      questionCategoryL1Idx: string,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/category/l1/{questionCategoryL1Idx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateQuestionCategoryL2
     * @summary 문제(퀴즈) 카테고리 L2 수정
     * @request PUT:/vod/api/vod/question/category/l2/{questionCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateQuestionCategoryL2: (
      questionCategoryL2Idx: string,
      questionCategoryIdx: string,
      data: UpdateQuestionCategoryL2Dto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/category/l2/${questionCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteQuestionCategoryL2
     * @summary 문제(퀴즈) 카테고리 L2 삭제
     * @request DELETE:/vod/api/vod/question/category/l2/{questionCategoryL2Idx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteQuestionCategoryL2: (
      questionCategoryL2Idx: string,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/category/l2/{questionCategoryL2Idx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerQuestionPhotoFileUpload
     * @summary 문제(퀴즈) 사진 업로드
     * @request POST:/vod/api/vod/question/photo/upload
     * @secure
     * @response `201` `QuestionFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerQuestionPhotoFileUpload: (
      data: QuestionPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<QuestionFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerInsertQuestion
     * @summary 문제(퀴즈)입력
     * @request POST:/vod/api/vod/question
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerInsertQuestion: (data: InsertQuestionDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadQuestion
     * @summary 문제(퀴즈) 조회
     * @request GET:/vod/api/vod/question
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadQuestion: (
      query: VodControllerReadQuestionParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateQuestion
     * @summary 문제(퀴즈) 수정
     * @request PUT:/vod/api/vod/question
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateQuestion: (data: UpdateQuestionDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerDeleteQuestion
     * @summary 문제(퀴즈) 삭제
     * @request DELETE:/vod/api/vod/question/{questionIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerDeleteQuestion: (
      questionIdx: string,
      data: DeleteQuestionDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/question/${questionIdx}`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerGetAutoQuestions
     * @summary 문제집(시험지) 자동 등록 문제리스트업
     * @request POST:/vod/api/vod/questions/auto
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerGetAutoQuestions: (data: GetAutoQuestionsDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/questions/auto`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerInsertQuestions
     * @summary 문제집(시험지) 생성
     * @request POST:/vod/api/vod/questions
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerInsertQuestions: (data: InsertQuestionsDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/questions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateQuestions
     * @summary 문제집(시험지) 수정
     * @request PUT:/vod/api/vod/questions
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateQuestions: (data: UpdateQuestionsDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/questions`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerReadQuestions
     * @summary 문제집(시험지) 조회
     * @request GET:/vod/api/vod/questions
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerReadQuestions: (
      query: VodControllerReadQuestionsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/questions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateQuestionsConnection
     * @summary 문제집(시험지)-문제 일괄 삭제 및 연결
     * @request PUT:/vod/api/vod/questions/connection
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateQuestionsConnection: (
      data: UpdateQuestionsConnectionDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/questions/connection`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name VodControllerUpdateQuestionsDisconnection
     * @summary 문제집(시험지)-문제 연결해제
     * @request DELETE:/vod/api/vod/questions/disconnection/{questionsGroupIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    vodControllerUpdateQuestionsDisconnection: (
      questionsGroupIdx: number,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/vod/questions/disconnection/${questionsGroupIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name PaymentControllerPaypleHook
     * @summary 페이플 훅
     * @request POST:/vod/api/payment/payple/hook
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerPaypleHook: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/payment/payple/hook`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name PaymentControllerPaypleLink
     * @summary 페이플 링크 결제
     * @request POST:/vod/api/payment/payple/link
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerPaypleLink: (data: PaypleLinkDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/payment/payple/link`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name PaymentControllerDownPayment
     * @summary 결제 결과: 엑셀 다운
     * @request GET:/vod/api/payment/download
     * @secure
     * @response `200` `void`
     */
    paymentControllerDownPayment: (
      query: PaymentControllerDownPaymentParams,
      params: RequestParams = {}
    ) =>
      this.request<void, any>({
        path: `/vod/api/payment/download`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name PaymentControllerReadPayments
     * @summary 결제 결과 조회
     * @request GET:/vod/api/payment
     * @secure
     * @response `200` `PaymentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerReadPayments: (
      query: PaymentControllerReadPaymentsParams,
      params: RequestParams = {}
    ) =>
      this.request<PaymentResponseDto, BaseResponseDto>({
        path: `/vod/api/payment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name PaymentControllerReadPaymentStatistics
     * @summary 결제 결과 통계
     * @request GET:/vod/api/payment/statistics
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerReadPaymentStatistics: (
      query: PaymentControllerReadPaymentStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/payment/statistics`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name PaymentControllerRefund
     * @summary 환불
     * @request PUT:/vod/api/payment/{paymentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    paymentControllerRefund: (paymentIdx: string, data: RefundDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/payment/${paymentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerNoticePhotoFileUpload
     * @summary  NOTICE 사진 업로드
     * @request POST:/vod/api/notice/photo/upload
     * @secure
     * @response `201` `NoticeFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerNoticePhotoFileUpload: (
      data: NoticePhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<NoticeFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/notice/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerCreateNoticeCategory
     * @summary 공지 카테고리 생성
     * @request POST:/vod/api/notice/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerCreateNoticeCategory: (
      data: CreateNoticeCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/notice/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerReadNoticeCategory
     * @summary 공지 카테고리 조회
     * @request GET:/vod/api/notice/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerReadNoticeCategory: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/notice/category`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerUpdateNoticeCategory
     * @summary 공지 카테고리 수정
     * @request PUT:/vod/api/notice/category/{noticeCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerUpdateNoticeCategory: (
      noticeCategoryIdx: string,
      data: CreateNoticeCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/notice/category/${noticeCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerDeleteNoticeCategory
     * @summary 공지 카테고리 삭제
     * @request DELETE:/vod/api/notice/category/{noticeCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerDeleteNoticeCategory: (noticeCategoryIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/notice/category/${noticeCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerCreateNotice
     * @summary 공지 생성
     * @request POST:/vod/api/notice
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerCreateNotice: (data: CreateNoticeDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/notice`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerReadNotices
     * @summary 공지들 조회
     * @request GET:/vod/api/notice
     * @secure
     * @response `200` `NoticesResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerReadNotices: (
      query: NoticeControllerReadNoticesParams,
      params: RequestParams = {}
    ) =>
      this.request<NoticesResponseDto, BaseResponseDto>({
        path: `/vod/api/notice`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerReadNotice
     * @summary 공지 조회
     * @request GET:/vod/api/notice/{noticeIdx}
     * @secure
     * @response `200` `NoticeResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerReadNotice: (noticeIdx: string, params: RequestParams = {}) =>
      this.request<NoticeResponseDto, BaseResponseDto>({
        path: `/vod/api/notice/${noticeIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name NoticeControllerUpdateNotice
     * @summary 공지 수정
     * @request PUT:/vod/api/notice/{noticeIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    noticeControllerUpdateNotice: (
      noticeIdx: string,
      data: UpdateNoticeDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/notice/${noticeIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerNoticePhotoFileUpload
     * @summary  FAQ 사진 업로드
     * @request POST:/vod/api/faq/photo/upload
     * @secure
     * @response `201` `FaqFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerNoticePhotoFileUpload: (data: FaqPhotoFileUploadDto, params: RequestParams = {}) =>
      this.request<FaqFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/faq/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerCreateFaqCategory
     * @summary FAQ 카테고리 생성
     * @request POST:/vod/api/faq/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerCreateFaqCategory: (data: CreateFaqCategoryDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/faq/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerReadFaqCategory
     * @summary FAQ 카테고리 조회
     * @request GET:/vod/api/faq/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerReadFaqCategory: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/faq/category`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerUpdateFaqCategory
     * @summary FAQ 카테고리 수정
     * @request PUT:/vod/api/faq/category/{faqCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerUpdateFaqCategory: (
      faqCategoryIdx: string,
      data: CreateFaqCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/faq/category/${faqCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerDeleteFaqCategory
     * @summary FAQ 카테고리 삭제
     * @request DELETE:/vod/api/faq/category/{faqCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerDeleteFaqCategory: (faqCategoryIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/faq/category/${faqCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerCreateFaq
     * @summary FAQ 생성
     * @request POST:/vod/api/faq
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerCreateFaq: (data: CreateFaqDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/faq`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerReadFaqs
     * @summary FAQ들 조회
     * @request GET:/vod/api/faq
     * @secure
     * @response `200` `FaqsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerReadFaqs: (query: FaqControllerReadFaqsParams, params: RequestParams = {}) =>
      this.request<FaqsResponseDto, BaseResponseDto>({
        path: `/vod/api/faq`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerReadFaq
     * @summary FAQ 조회
     * @request GET:/vod/api/faq/{faqIdx}
     * @secure
     * @response `200` `FaqResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerReadFaq: (faqIdx: string, params: RequestParams = {}) =>
      this.request<FaqResponseDto, BaseResponseDto>({
        path: `/vod/api/faq/${faqIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name FaqControllerUpdateFaq
     * @summary FAQ 수정
     * @request PUT:/vod/api/faq/{faqIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    faqControllerUpdateFaq: (faqIdx: string, data: UpdateFaqDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/faq/${faqIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerQnaPhotoFileUpload
     * @summary  QNA 사진 업로드
     * @request POST:/vod/api/qna/photo/upload
     * @secure
     * @response `201` `QnaFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerQnaPhotoFileUpload: (data: QnaPhotoFileUploadDto, params: RequestParams = {}) =>
      this.request<QnaFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerCreateQnaCategory
     * @summary QNA 카테고리 생성
     * @request POST:/vod/api/qna/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerCreateQnaCategory: (data: CreateQnaCategoryDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerReadQnaCategory
     * @summary QNA 카테고리 조회
     * @request GET:/vod/api/qna/category
     * @secure
     * @response `200` `QnaCategoryResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerReadQnaCategory: (params: RequestParams = {}) =>
      this.request<QnaCategoryResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/category`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerUpdateQnaCategory
     * @summary QNA 카테고리 수정
     * @request PUT:/vod/api/qna/category/{qnaCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerUpdateQnaCategory: (
      qnaCategoryIdx: string,
      data: CreateQnaCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/category/${qnaCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerDeleteQnaCategory
     * @summary QNA 카테고리 삭제
     * @request DELETE:/vod/api/qna/category/{qnaCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerDeleteQnaCategory: (qnaCategoryIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/category/${qnaCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerReadQnas
     * @summary QNA들 조회
     * @request GET:/vod/api/qna
     * @secure
     * @response `200` `QnasResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerReadQnas: (query: QnaControllerReadQnasParams, params: RequestParams = {}) =>
      this.request<QnasResponseDto, BaseResponseDto>({
        path: `/vod/api/qna`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerReadQnaStatistics
     * @summary QNA 통계
     * @request GET:/vod/api/qna/statistics/qna
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerReadQnaStatistics: (
      query: QnaControllerReadQnaStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/statistics/qna`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerReadQna
     * @summary QNA 조회
     * @request GET:/vod/api/qna/{qnaIdx}
     * @secure
     * @response `200` `QnaResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerReadQna: (qnaIdx: string, params: RequestParams = {}) =>
      this.request<QnaResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/${qnaIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerUpdateFaq
     * @summary QNA 답변
     * @request PUT:/vod/api/qna/{qnaIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerUpdateFaq: (qnaIdx: string, data: UpdateQnaDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/${qnaIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name QnaControllerDeleteQna
     * @summary QNA 삭제
     * @request DELETE:/vod/api/qna/{qnaIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    qnaControllerDeleteQna: (qnaIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/qna/${qnaIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerCreateCoupon
     * @summary 쿠폰생성
     * @request POST:/vod/api/coupon
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerCreateCoupon: (data: CreateCouponDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerReadCouponGroup
     * @summary 쿠폰 그룹 조회
     * @request GET:/vod/api/coupon/group
     * @secure
     * @response `200` `CouponGroupResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerReadCouponGroup: (
      query: CouponControllerReadCouponGroupParams,
      params: RequestParams = {}
    ) =>
      this.request<CouponGroupResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerDeleteCouponGroup
     * @summary 쿠폰 그룹 삭제
     * @request DELETE:/vod/api/coupon/group/{couponGroupCode}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerDeleteCouponGroup: (couponGroupCode: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group/${couponGroupCode}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerReadCoupon
     * @summary 쿠폰 그룹 한개 조회
     * @request GET:/vod/api/coupon/group/{couponGroupCode}
     * @secure
     * @response `200` `CouponResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerReadCoupon: (
      { couponGroupCode, ...query }: CouponControllerReadCouponParams,
      params: RequestParams = {}
    ) =>
      this.request<CouponResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group/${couponGroupCode}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerAddCoupon
     * @summary 쿠폰 그룹에 쿠폰 수량 추가
     * @request PUT:/vod/api/coupon/group/{couponGroupCode}
     * @secure
     * @response `200` `CouponResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerAddCoupon: (
      couponGroupCode: string,
      data: AddCouponDto,
      params: RequestParams = {}
    ) =>
      this.request<CouponResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/group/${couponGroupCode}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerPutCoupon
     * @summary 쿠폰 한개 할당(철회시 studentIdx: null)
     * @request PUT:/vod/api/coupon/one/{couponIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerPutCoupon: (
      couponIdx: string,
      data: UpdateCouponDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/one/${couponIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerDeleteCoupon
     * @summary 쿠폰 한개 삭제
     * @request DELETE:/vod/api/coupon/one/{couponIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerDeleteCoupon: (couponIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/one/${couponIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerCreateCouponAuto
     * @summary 자동 쿠폰 생성
     * @request POST:/vod/api/coupon/auto
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerCreateCouponAuto: (data: CreateCouponAutoDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/auto`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerReadCouponAuto
     * @summary 자동 쿠폰 조회
     * @request GET:/vod/api/coupon/auto
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerReadCouponAuto: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/auto`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name CouponControllerPutCouponAuto
     * @summary 자동 쿠폰 수정
     * @request PUT:/vod/api/coupon/auto/{couponAutoIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    couponControllerPutCouponAuto: (
      couponAutoIdx: string,
      data: UpdateCouponAutoDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/coupon/auto/${couponAutoIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerReadEtcStatistics
     * @summary 기타 통계
     * @request GET:/vod/api/system/statistics/etc
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerReadEtcStatistics: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/etc`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerReadAdminStatistics
     * @summary 고객 정보 및 통계
     * @request GET:/vod/api/system/statistics/admin
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    systemControllerReadAdminStatistics: (
      query: SystemControllerReadAdminStatisticsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/system/statistics/admin`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerOverCron
     * @summary traffic, space notification cron (매일 9시)
     * @request GET:/vod/api/system/cron/notifications
     * @response `200` `void`
     */
    systemControllerOverCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/notifications`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerExpiredCron
     * @summary expired date cron (매일 1시)
     * @request GET:/vod/api/system/cron/expired
     * @response `200` `void`
     */
    systemControllerExpiredCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/expired`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerExpiredMessageCron
     * @summary today expired date auto message cron (매일 11시)
     * @request GET:/vod/api/system/cron/expiredMessage
     * @response `200` `void`
     */
    systemControllerExpiredMessageCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/expiredMessage`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerProgressRate1MessageCron
     * @summary progress rate 1 auto message cron (매일 9시)
     * @request GET:/vod/api/system/cron/progressRate1Message
     * @response `200` `void`
     */
    systemControllerProgressRate1MessageCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/progressRate1Message`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerProgressRate2MessageCron
     * @summary progress rate 2 auto message cron (매일 10시)
     * @request GET:/vod/api/system/cron/progressRate2Message
     * @response `200` `void`
     */
    systemControllerProgressRate2MessageCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/progressRate2Message`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerHyflexToKollusCron
     * @summary hyflex to kollus cron (매시 30분)
     * @request GET:/vod/api/system/cron/hyflex2kollus
     * @response `200` `void`
     */
    systemControllerHyflexToKollusCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/hyflex2kollus`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerScheduledNotificationCron
     * @summary 스케쥴 알림 (10분에 한번)
     * @request GET:/vod/api/system/cron/scheduledNotification
     * @response `200` `void`
     */
    systemControllerScheduledNotificationCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/scheduledNotification`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerBbbHook
     * @summary bbb hook
     * @request POST:/vod/api/system/hook/bbb
     * @response `201` `void`
     */
    systemControllerBbbHook: (data: BbbHookDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/hook/bbb`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name SystemControllerExpiredRequestChangeScheduleCron
     * @summary expiredRequestChangeSchedule cron (매시)
     * @request GET:/vod/api/system/cron/expiredRequestChangeSchedule
     * @response `200` `void`
     */
    systemControllerExpiredRequestChangeScheduleCron: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/vod/api/system/cron/expiredRequestChangeSchedule`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name LiveControllerCheckTeachers
     * @summary 시작일/횟수/시간으로 강사 조회.
     * @request GET:/vod/api/live/checkTeachers
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    liveControllerCheckTeachers: (
      query: LiveControllerCheckTeachersParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/live/checkTeachers`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerUpdateAccesskey
     * @summary 센딧 AccessKey 등록
     * @request PUT:/vod/api/message/accesskey
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerUpdateAccesskey: (data: UpdateAccesskeyDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/accesskey`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerReadProfile
     * @summary 센딧 승인된 발신번호/발신프로필 가져오기
     * @request GET:/vod/api/message/profile
     * @secure
     * @response `200` `ReadProfileResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadProfile: (params: RequestParams = {}) =>
      this.request<ReadProfileResponseDto, BaseResponseDto>({
        path: `/vod/api/message/profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerUpdateProfile
     * @summary 시스템에서 사용할 발신번호/발신프로필 등록
     * @request PUT:/vod/api/message/profile
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerUpdateProfile: (data: UpdateProfileDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/profile`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerReadMessage
     * @summary 메시지 발신기록
     * @request GET:/vod/api/message
     * @secure
     * @response `200` `ReadMessageResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadMessage: (
      query: MessageControllerReadMessageParams,
      params: RequestParams = {}
    ) =>
      this.request<ReadMessageResponseDto, BaseResponseDto>({
        path: `/vod/api/message`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerSendMessage
     * @summary 메시지 발송
     * @request POST:/vod/api/message/send
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSendMessage: (data: SendMessageDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/send`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerCreateAlimtalkTemplate
     * @summary 센딧 알림톡 템플릿 등록하기
     * @request POST:/vod/api/message/alimtalk/template
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerCreateAlimtalkTemplate: (
      data: CreateAlimtalkTemplateDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/alimtalk/template`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerReadAlimtalkTemplate
     * @summary 센딧 알림톡 템플릿 가져오기
     * @request GET:/vod/api/message/alimtalk/template
     * @secure
     * @response `200` `ReadAlimtalkTemplateResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerReadAlimtalkTemplate: (
      query: MessageControllerReadAlimtalkTemplateParams,
      params: RequestParams = {}
    ) =>
      this.request<ReadAlimtalkTemplateResponseDto, BaseResponseDto>({
        path: `/vod/api/message/alimtalk/template`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerSaveMessage
     * @summary 메시지 수정/저장
     * @request POST:/vod/api/message/save
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSaveMessage: (data: SaveMessageDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/save`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name MessageControllerSavedMessage
     * @summary 저장된 메시지 조회
     * @request GET:/vod/api/message/save
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    messageControllerSavedMessage: (params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/message/save`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name DesignControllerDesignPhotoFileUpload
     * @summary 사진 업로드
     * @request POST:/vod/api/design/photo/upload
     * @secure
     * @response `201` `DesignFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    designControllerDesignPhotoFileUpload: (
      data: DesignPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<DesignFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/design/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name DesignControllerSaveDesign
     * @summary 디자인 생성/수정
     * @request POST:/vod/api/design
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    designControllerSaveDesign: (data: SaveDesignDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/design`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name DesignControllerReadDesign
     * @summary 디자인 가져오기
     * @request GET:/vod/api/design
     * @secure
     * @response `201` `DesignResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    designControllerReadDesign: (params: RequestParams = {}) =>
      this.request<DesignResponseDto, BaseResponseDto>({
        path: `/vod/api/design`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerBoardFileUpload
     * @summary  게시판 사진 업로드
     * @request POST:/vod/api/board/file/upload
     * @secure
     * @response `201` `BoardFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerBoardFileUpload: (data: BoardFileUploadDto, params: RequestParams = {}) =>
      this.request<BoardFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/board/file/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerCreateBoardClass
     * @summary 게시판 생성
     * @request POST:/vod/api/board/class
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateBoardClass: (data: CreateBoardClassDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerReadBoardClass
     * @summary 게시판 조회
     * @request GET:/vod/api/board/class
     * @secure
     * @response `200` `BoardClassResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadBoardClass: (params: RequestParams = {}) =>
      this.request<BoardClassResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerUpdateBoardClass
     * @summary 게시판 수정(게시판 숨김여부 포함)
     * @request PUT:/vod/api/board/class/{boardClassIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateBoardClass: (
      boardClassIdx: number,
      data: UpdateBoardClassDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class/${boardClassIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerDeleteBoardClass
     * @summary 게시판 삭제
     * @request DELETE:/vod/api/board/class/{boardClassIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeleteBoardClass: (boardClassIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/class/${boardClassIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerCreateBoardCategory
     * @summary 게시글 카테고리 생성
     * @request POST:/vod/api/board/category
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateBoardCategory: (
      data: CreateBaordCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerReadBoardCategory
     * @summary 게시글 카테고리 조회
     * @request GET:/vod/api/board/category
     * @secure
     * @response `200` `BoardCategoryResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadBoardCategory: (
      query: BoardControllerReadBoardCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BoardCategoryResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerUpdateBoardCategory
     * @summary 게시글 카테고리 수정
     * @request PUT:/vod/api/board/category/{boardCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateBoardCategory: (
      boardCategoryIdx: number,
      data: UpdateBaordCategoryDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category/${boardCategoryIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerDeleteBoardCategory
     * @summary 게시글 카테고리 삭제
     * @request DELETE:/vod/api/board/category/{boardCategoryIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeleteBoardCategory: (boardCategoryIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/category/${boardCategoryIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerCreateBoard
     * @summary 게시글 생성
     * @request POST:/vod/api/board/board
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateBoard: (data: CreateBoardDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/board`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerReadBoards
     * @summary 게시글 조회
     * @request GET:/vod/api/board/board
     * @secure
     * @response `200` `BoardResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadBoards: (
      query: BoardControllerReadBoardsParams,
      params: RequestParams = {}
    ) =>
      this.request<BoardResponseDto, BaseResponseDto>({
        path: `/vod/api/board/board`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerReadBoard
     * @summary 게시글 상세 조회
     * @request GET:/vod/api/board/board/{boardIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerReadBoard: (boardIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/board/${boardIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerUpdateBoard
     * @summary 게시글 수정
     * @request PUT:/vod/api/board/board/{boardIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateBoard: (
      boardIdx: string,
      data: UpdateBoardDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/board/${boardIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerDeleteBoard
     * @summary 게시글 삭제
     * @request DELETE:/vod/api/board/board/{boardIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerDeleteBoard: (boardIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/board/${boardIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerCreateComment
     * @summary 댓글 생성
     * @request POST:/vod/api/board/commnet
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateComment: (data: CreateCommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/commnet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerUpdateComment
     * @summary 댓글 수정
     * @request PUT:/vod/api/board/commnet/{boardCommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateComment: (
      boardCommentIdx: string,
      data: UpdateCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/commnet/${boardCommentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerCreateCocomment
     * @summary 대댓글 생성
     * @request POST:/vod/api/board/cocommnet
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerCreateCocomment: (data: CreateCocommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/cocommnet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 강사
     * @name BoardControllerUpdateCocomment
     * @summary 대댓글 수정
     * @request PUT:/vod/api/board/cocommnet/{boardCocommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    boardControllerUpdateCocomment: (
      boardCocommentIdx: string,
      data: UpdateCocommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/board/cocommnet/${boardCocommentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpMyData
     * @summary 내정보(학생)
     * @request GET:/vod/api/wp/student
     * @secure
     * @response `200` `WpMyDataResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpMyData: (params: RequestParams = {}) =>
      this.request<WpMyDataResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpAddNaverLogin
     * @summary 네이버로그인 추가
     * @request POST:/vod/api/wp/student/social/naver
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpAddNaverLogin: (data: WpAddNaverLoginDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/social/naver`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpAddKakaoLogin
     * @summary 카카오로그인 추가
     * @request POST:/vod/api/wp/student/social/kakao
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpAddKakaoLogin: (data: WpAddKakaoLoginDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/social/kakao`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpChangePassword
     * @summary 비밀번호 변경
     * @request POST:/vod/api/wp/student/password
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpChangePassword: (data: WpChangePasswordDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerStudentPhotoFileUpload
     * @summary 사진 업로드
     * @request POST:/vod/api/wp/student/photo/upload
     * @secure
     * @response `201` `WpFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerStudentPhotoFileUpload: (
      data: WpPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<WpFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/photo/upload`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpUpdateMyData
     * @summary 내 정보 수정
     * @request PUT:/vod/api/wp/student/photo
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpUpdateMyData: (data: WpUpdateMyDataDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/photo`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpDelete
     * @summary 탈퇴
     * @request POST:/vod/api/wp/student/delete
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpDelete: (data: WpDeleteDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/delete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpCouponRegistration
     * @summary 쿠폰등록
     * @request POST:/vod/api/wp/student/coupon
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpCouponRegistration: (
      data: WpCouponRegistrationDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/coupon`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpStudentControllerWpCoupon
     * @summary 쿠폰조회
     * @request GET:/vod/api/wp/student/coupon
     * @secure
     * @response `201` `WpCouponResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpStudentControllerWpCoupon: (
      query: WpStudentControllerWpCouponParams,
      params: RequestParams = {}
    ) =>
      this.request<WpCouponResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/student/coupon`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetDashboard
     * @summary 랜딩페이지 대시보드(총학생수, 총강의수, 수업개설수)
     * @request GET:/vod/api/wp/vod/landing/dashboard
     * @response `201` `WpVodDashboardResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetDashboard: (
      query: WpVodControllerGetDashboardParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodDashboardResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/dashboard`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerReadVodCategory
     * @summary VOD 강좌/챕터 조회
     * @request GET:/vod/api/wp/vod/category
     * @response `200` `WpVodCategoryResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadVodCategory: (
      query: WpVodControllerReadVodCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodCategoryResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/category`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetProducts
     * @summary 상품보기
     * @request GET:/vod/api/wp/vod/landing/products
     * @response `201` `WpVodProductsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetProducts: (
      query: WpVodControllerGetProductsParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodProductsResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/products`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetProduct
     * @summary 상품상세보기
     * @request GET:/vod/api/wp/vod/landing/products/{vodProductIdx}
     * @response `201` `WpVodProductResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetProduct: (
      { vodProductIdx, ...query }: WpVodControllerGetProductParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodProductResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/products/${vodProductIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetProductChapter
     * @summary 상품챕터보기
     * @request GET:/vod/api/wp/vod/landing/productChapter/{vodProductIdx}
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetProductChapter: (vodProductIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/productChapter/${vodProductIdx}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetReviews
     * @summary 상품리뷰들보기
     * @request GET:/vod/api/wp/vod/landing/reviews
     * @response `201` `WpVodReviewResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetReviews: (
      query: WpVodControllerGetReviewsParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodReviewResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/reviews`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetReview
     * @summary 상품리뷰보기
     * @request GET:/vod/api/wp/vod/landing/reviews/{vodProductIdx}
     * @response `201` `WpVodReviewResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetReview: (
      { vodProductIdx, ...query }: WpVodControllerGetReviewParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodReviewResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/reviews/${vodProductIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetCategorys
     * @summary 랜딩페이지-카테고리별 대시보드
     * @request GET:/vod/api/wp/vod/landing/categorys
     * @response `201` `WpVodCategoriesResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetCategorys: (
      query: WpVodControllerGetCategorysParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodCategoriesResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/categorys`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetTeachers
     * @summary 랜딩페이지-강사소개
     * @request GET:/vod/api/wp/vod/landing/teachers
     * @response `201` `WpVodTeachersResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetTeachers: (
      query: WpVodControllerGetTeachersParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodTeachersResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/teachers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetTeachers2
     * @summary 강사소개2
     * @request GET:/vod/api/wp/vod/landing/teachers2
     * @response `201` `WpVodTeachersResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetTeachers2: (
      query: WpVodControllerGetTeachers2Params,
      params: RequestParams = {}
    ) =>
      this.request<WpVodTeachersResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/landing/teachers2`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerReadLessons
     * @summary 마이페이지-내강의
     * @request GET:/vod/api/wp/vod/mypage/lesson
     * @secure
     * @response `200` `WpVodMyLessonsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadLessons: (
      query: WpVodControllerReadLessonsParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodMyLessonsResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/lesson`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerReadLesson
     * @summary 마이페이지-내강의-강의듣기
     * @request GET:/vod/api/wp/vod/mypage/lesson/{vodLessonIdx}
     * @secure
     * @response `200` `WpVodMyLessonResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerReadLesson: (vodLessonIdx: string, params: RequestParams = {}) =>
      this.request<WpVodMyLessonResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/lesson/${vodLessonIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerDownloadLessonFile
     * @summary 마이페이지-내강의-강의듣기-강의자료다운로드
     * @request GET:/vod/api/wp/vod/mypage/download/{vodLessonIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerDownloadLessonFile: (
      { vodLessonIdx, ...query }: WpVodControllerDownloadLessonFileParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/download/${vodLessonIdx}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetReference
     * @summary 자료실 조회
     * @request GET:/vod/api/wp/vod/mypage/reference/{vodLessonIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetReference: (
      { vodLessonIdx, ...query }: WpVodControllerGetReferenceParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/reference/${vodLessonIdx}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerCreateReivew
     * @summary 마이페이지-내강의-강의듣기-리뷰작성
     * @request POST:/vod/api/wp/vod/mypage/review/product
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCreateReivew: (data: CreateVodEvaluationDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/review/product`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerUpdateReivew
     * @summary 마이페이지-내강의-강의듣기-리뷰수정
     * @request PUT:/vod/api/wp/vod/mypage/review/product
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerUpdateReivew: (data: CreateVodEvaluationDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/review/product`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerDeleteReivew
     * @summary 마이페이지-내강의-강의듣기-리뷰삭제
     * @request PUT:/vod/api/wp/vod/mypage/review/product/{vodEvaluationIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerDeleteReivew: (vodEvaluationIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/review/product/${vodEvaluationIdx}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerCreateTeacherReivew
     * @summary 마이페이지-내강의-강의듣기-강사리뷰작성
     * @request POST:/vod/api/wp/vod/mypage/review/teacher
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCreateTeacherReivew: (
      data: CreateTeacherEvaluationDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/review/teacher`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerUpdateTeacherReivew
     * @summary 마이페이지-내강의-강의듣기-강사리뷰수정
     * @request PUT:/vod/api/wp/vod/mypage/review/teacher
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerUpdateTeacherReivew: (
      data: UpdateTeacherEvaluationDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/review/teacher`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerTeacherReivew
     * @summary 강사리뷰목록(로그인X)
     * @request GET:/vod/api/wp/vod/mypage/review/teacher
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerTeacherReivew: (
      query: WpVodControllerTeacherReivewParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/review/teacher`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerTeacherReivew2
     * @summary 강사리뷰상세(내것만조회가능
     * @request GET:/vod/api/wp/vod/mypage/review/teacher/{teacherEvaluationIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerTeacherReivew2: (teacherEvaluationIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/review/teacher/${teacherEvaluationIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetUrl
     * @summary 마이페이지-내강의-강의듣기-URL획득
     * @request GET:/vod/api/wp/vod/mypage/lesson/url/{vodLessonIdx}
     * @secure
     * @response `200` `WpVodMyLessonUrlResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetUrl: (
      { vodLessonIdx, ...query }: WpVodControllerGetUrlParams,
      params: RequestParams = {}
    ) =>
      this.request<WpVodMyLessonUrlResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/lesson/url/${vodLessonIdx}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetLiveUrl
     * @summary live 스케쥴 url 획득
     * @request GET:/vod/api/wp/vod/mypage/schedule/url/{vodScheduleIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetLiveUrl: (vodScheduleIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/schedule/url/${vodScheduleIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerCreateWishlist
     * @summary 찜하기
     * @request POST:/vod/api/wp/vod/mypage/wishlist
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCreateWishlist: (data: CreateWishlistDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/wishlist`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetWishlist
     * @summary 찜목록 조회
     * @request GET:/vod/api/wp/vod/mypage/wishlist
     * @secure
     * @response `200` `WishlistResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetWishlist: (params: RequestParams = {}) =>
      this.request<WishlistResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/wishlist`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerDeleteWishlist
     * @summary 찜삭제
     * @request DELETE:/vod/api/wp/vod/mypage/wishlist/{vodProductIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerDeleteWishlist: (vodProductIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/mypage/wishlist/${vodProductIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetLarahvContent
     * @summary LARAHV 녹화 조회
     * @request POST:/vod/api/wp/vod/larahv
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetLarahvContent: (data: LarahvContentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/larahv`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerCheckChangeLive
     * @summary 남은 수업 LIVE 강사/수업 변경전 체크(1:1만 가능)
     * @request POST:/vod/api/wp/vod/check/changeLive
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckChangeLive: (data: CheckChangeLiveDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/check/changeLive`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerChangeLive
     * @summary 남은 수업 LIVE 강사/수업 변경(1:1만 가능)
     * @request POST:/vod/api/wp/vod/set/changeLive
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerChangeLive: (data: ChangeLiveDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/set/changeLive`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerCheckChangeSchedule
     * @summary 수업일 변경 요청전 체크(1:1만 가능)
     * @request POST:/vod/api/wp/vod/check/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerCheckChangeSchedule: (
      data: CheckChangeScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/check/changeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerRequestChangeSchedule
     * @summary 수업일 변경 요청(1:1만 가능)
     * @request POST:/vod/api/wp/vod/request/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerRequestChangeSchedule: (
      data: RequestChangeScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/request/changeSchedule`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerGetRequestChangeSchedule
     * @summary 수업일 변경 요청 내역
     * @request GET:/vod/api/wp/vod/request/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerGetRequestChangeSchedule: (
      query: WpVodControllerGetRequestChangeScheduleParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/request/changeSchedule`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpVodControllerConfirmChangeSchedule
     * @summary 수업일 변경 승인/거부
     * @request PUT:/vod/api/wp/vod/request/changeSchedule
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpVodControllerConfirmChangeSchedule: (
      data: ConfirmChangeScheduleDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/vod/request/changeSchedule`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerCheckPayment
     * @summary 금액확인
     * @request POST:/vod/api/wp/payment/check
     * @secure
     * @response `200` `WpCheckPaymentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerCheckPayment: (data: WpCheckPaymentDto, params: RequestParams = {}) =>
      this.request<WpCheckPaymentResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/check`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerCheckLive
     * @summary LIVE 강사/수업 확인
     * @request POST:/vod/api/wp/payment/check/live
     * @secure
     * @response `200` `WpCheckPaymentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerCheckLive: (data: WpBeforeLiveDto, params: RequestParams = {}) =>
      this.request<WpCheckPaymentResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/check/live`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerBeforePayment
     * @summary 결제전
     * @request POST:/vod/api/wp/payment/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerBeforePayment: (data: WpBeforePaymentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/payment`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerAfterPayment
     * @summary 결제후
     * @request PUT:/vod/api/wp/payment/payment
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerAfterPayment: (data: WpAfterPaymentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/payment`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerCancelPayment
     * @summary 결제취소시
     * @request POST:/vod/api/wp/payment/paymentCancel
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerCancelPayment: (data: WpCancelPaymentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/paymentCancel`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerAfterZeroPayment
     * @summary 0원 결제후
     * @request PUT:/vod/api/wp/payment/payment/{PCD_PAY_OID}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerAfterZeroPayment: (pcdPayOid: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/payment/${pcdPayOid}`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerReadPayment
     * @summary 결제내역
     * @request GET:/vod/api/wp/payment
     * @secure
     * @response `200` `WpPaymentResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerReadPayment: (
      query: WpPaymentControllerReadPaymentParams,
      params: RequestParams = {}
    ) =>
      this.request<WpPaymentResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpPaymentControllerRefund
     * @summary 취소요청
     * @request POST:/vod/api/wp/payment/refund
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpPaymentControllerRefund: (data: WpRefundDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/payment/refund`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpNoticeControllerReadWpNoticeCategory
     * @summary 공지 카테고리 조회(로그인X)
     * @request GET:/vod/api/wp/notice/categeory
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpNoticeControllerReadWpNoticeCategory: (
      query: WpNoticeControllerReadWpNoticeCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/notice/categeory`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpNoticeControllerReadWpNotices
     * @summary 공지들 조회
     * @request GET:/vod/api/wp/notice
     * @response `200` `WpNoticesResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpNoticeControllerReadWpNotices: (
      query: WpNoticeControllerReadWpNoticesParams,
      params: RequestParams = {}
    ) =>
      this.request<WpNoticesResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/notice`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpNoticeControllerReadNotice
     * @summary 공지 조회
     * @request GET:/vod/api/wp/notice/{noticeIdx}
     * @response `200` `WpNoticeResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpNoticeControllerReadNotice: (
      { noticeIdx, ...query }: WpNoticeControllerReadNoticeParams,
      params: RequestParams = {}
    ) =>
      this.request<WpNoticeResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/notice/${noticeIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpFaqControllerReadWpFaqCategory
     * @summary FAQ 카테고리 조회(로그인X)
     * @request GET:/vod/api/wp/faq/categeory
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpFaqControllerReadWpFaqCategory: (
      query: WpFaqControllerReadWpFaqCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/faq/categeory`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpFaqControllerReadWpFaqs
     * @summary FAQ들 조회
     * @request GET:/vod/api/wp/faq
     * @response `200` `WpFaqsResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpFaqControllerReadWpFaqs: (
      query: WpFaqControllerReadWpFaqsParams,
      params: RequestParams = {}
    ) =>
      this.request<WpFaqsResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/faq`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpFaqControllerReadFaq
     * @summary FAQ 조회
     * @request GET:/vod/api/wp/faq/{faqIdx}
     * @response `200` `WpFaqResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpFaqControllerReadFaq: (
      { faqIdx, ...query }: WpFaqControllerReadFaqParams,
      params: RequestParams = {}
    ) =>
      this.request<WpFaqResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/faq/${faqIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerQnaPhotoFileUpload
     * @summary  QNA 사진 업로드
     * @request POST:/vod/api/wp/qna/photo/upload
     * @response `201` `WpQnaFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerQnaPhotoFileUpload: (
      data: WpQnaPhotoFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<WpQnaFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/photo/upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerReadWpQnaCategory
     * @summary QNA 카테고리 조회(로그인X)
     * @request GET:/vod/api/wp/qna/categeory
     * @response `200` `WpQnaCategoryResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerReadWpQnaCategory: (
      query: WpQnaControllerReadWpQnaCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<WpQnaCategoryResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/categeory`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerReadWpQnas
     * @summary QNA들 조회
     * @request GET:/vod/api/wp/qna/signin
     * @secure
     * @response `200` `WpQnasResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerReadWpQnas: (
      query: WpQnaControllerReadWpQnasParams,
      params: RequestParams = {}
    ) =>
      this.request<WpQnasResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/signin`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerCreateQna
     * @summary QNA 입력
     * @request POST:/vod/api/wp/qna/signin
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerCreateQna: (data: CreateQnaDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/signin`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerReadFaq
     * @summary QNA 조회
     * @request GET:/vod/api/wp/qna/signin/{qnaIdx}
     * @secure
     * @response `200` `WpQnaResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerReadFaq: (qnaIdx: string, params: RequestParams = {}) =>
      this.request<WpQnaResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/signin/${qnaIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerUpdateFaq
     * @summary QNA 수정
     * @request PUT:/vod/api/wp/qna/signin/{qnaIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerUpdateFaq: (qnaIdx: string, data: CreateQnaDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/signin/${qnaIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerDeleteQna
     * @summary QNA 삭제
     * @request DELETE:/vod/api/wp/qna/signin/{qnaIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerDeleteQna: (qnaIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/signin/${qnaIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerCreateQnaBeforeLogin
     * @summary QNA 입력(로그인X)
     * @request POST:/vod/api/wp/qna
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerCreateQnaBeforeLogin: (
      data: CreateQnaBeforeLoginDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerReadWpQnasBeforeLoign
     * @summary QNA들 조회(로그인X)
     * @request GET:/vod/api/wp/qna
     * @response `200` `WpQnasResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerReadWpQnasBeforeLoign: (
      query: WpQnaControllerReadWpQnasBeforeLoignParams,
      params: RequestParams = {}
    ) =>
      this.request<WpQnasResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQnaControllerReadFaqBeforeLoign
     * @summary QNA 조회(로그인X)
     * @request GET:/vod/api/wp/qna/{qnaIdx}
     * @response `200` `WpQnaResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQnaControllerReadFaqBeforeLoign: (
      { qnaIdx, ...query }: WpQnaControllerReadFaqBeforeLoignParams,
      params: RequestParams = {}
    ) =>
      this.request<WpQnaResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/qna/${qnaIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerBoardPhotoFileUpload
     * @summary 게시글 사진 업로드
     * @request POST:/vod/api/wp/board/photo/upload
     * @response `201` `WpBoardFileUploadResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerBoardPhotoFileUpload: (
      data: WpBoardFileUploadDto,
      params: RequestParams = {}
    ) =>
      this.request<WpBoardFileUploadResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/photo/upload`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerReadWpBoardClass
     * @summary 게시판 조회(로그인X)
     * @request GET:/vod/api/wp/board/class
     * @response `200` `WpBoardClassResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpBoardClass: (
      query: WpBoardControllerReadWpBoardClassParams,
      params: RequestParams = {}
    ) =>
      this.request<WpBoardClassResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/class`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerReadWpBoardCategory
     * @summary 게시판 카테고리 조회(로그인X)
     * @request GET:/vod/api/wp/board/categeory
     * @response `200` `WpBoardCategoryResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpBoardCategory: (
      query: WpBoardControllerReadWpBoardCategoryParams,
      params: RequestParams = {}
    ) =>
      this.request<WpBoardCategoryResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/categeory`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerReadWpBoards
     * @summary 게시글 조회
     * @request GET:/vod/api/wp/board/signin/board
     * @secure
     * @response `200` `WpBoardResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpBoards: (
      query: WpBoardControllerReadWpBoardsParams,
      params: RequestParams = {}
    ) =>
      this.request<WpBoardResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/board`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerCreateWpBoard
     * @summary 게시글 생성
     * @request POST:/vod/api/wp/board/signin/board
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerCreateWpBoard: (data: CreateWpBoardDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/board`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerReadBoard
     * @summary 게시글 상세 조회
     * @request GET:/vod/api/wp/board/signin/board/{boardIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadBoard: (boardIdx: number, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/board/${boardIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerUpdateWpBoard
     * @summary 게시글 수정
     * @request PUT:/vod/api/wp/board/signin/board/{boardIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerUpdateWpBoard: (
      boardIdx: string,
      data: UpdateWpBoardDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/board/${boardIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerDeleteWpBoard
     * @summary 게시글 삭제
     * @request DELETE:/vod/api/wp/board/signin/board/{boardIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerDeleteWpBoard: (boardIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/signin/board/${boardIdx}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerCreateWpComment
     * @summary 댓글 생성
     * @request POST:/vod/api/wp/board/commnet
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerCreateWpComment: (data: CreateWpCommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/commnet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerUpdateWpComment
     * @summary 댓글 수정
     * @request PUT:/vod/api/wp/board/commnet/{boardCommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerUpdateWpComment: (
      boardCommentIdx: string,
      data: UpdateWpCommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/commnet/${boardCommentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerCreateWpCocomment
     * @summary 대댓글 생성
     * @request POST:/vod/api/wp/board/cocommnet
     * @secure
     * @response `201` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerCreateWpCocomment: (data: CreateWpCocommentDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/cocommnet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerUpdateWpCocomment
     * @summary 대댓글 수정
     * @request PUT:/vod/api/wp/board/cocommnet/{boardCocommentIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerUpdateWpCocomment: (
      boardCocommentIdx: string,
      data: UpdateWpCocommentDto,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/cocommnet/${boardCocommentIdx}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerReadWpBoardsBf
     * @summary 게시글 조회(로그인X)
     * @request GET:/vod/api/wp/board/bf/board
     * @response `200` `WpBoardResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpBoardsBf: (
      query: WpBoardControllerReadWpBoardsBfParams,
      params: RequestParams = {}
    ) =>
      this.request<WpBoardResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/bf/board`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerCreateWpBoardBf
     * @summary 게시글 생성(로그인X)
     * @request POST:/vod/api/wp/board/bf/board
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerCreateWpBoardBf: (data: CreateWpBoardBfDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/bf/board`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpBoardControllerReadWpBoardBf
     * @summary 게시글 상세 조회(로그인X)
     * @request GET:/vod/api/wp/board/bf/board/{boardIdx}
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpBoardControllerReadWpBoardBf: (
      { boardIdx, ...query }: WpBoardControllerReadWpBoardBfParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/board/bf/board/${boardIdx}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQuestionsControllerGetQustionIndexes
     * @summary questionsIdx로 questionIdx 조회
     * @request GET:/vod/api/wp/questions/questions/{questionsIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuestionsControllerGetQustionIndexes: (questionsIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/questions/questions/${questionsIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQuestionsControllerGetQustion
     * @summary questionIdx 조회
     * @request GET:/vod/api/wp/questions/question/{questionIdx}
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuestionsControllerGetQustion: (questionIdx: string, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/questions/question/${questionIdx}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQuestionsControllerSetQustions
     * @summary 시험 제출하기
     * @request POST:/vod/api/wp/questions/questions
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuestionsControllerSetQustions: (data: SetQuestionsDto, params: RequestParams = {}) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/questions/questions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags 학생
     * @name WpQuestionsControllerGetQustions
     * @summary 시험 제출 결과
     * @request GET:/vod/api/wp/questions/questions
     * @secure
     * @response `200` `BaseResponseDto` 성공
     * @response `500` `BaseResponseDto` 서버에러
     */
    wpQuestionsControllerGetQustions: (
      query: WpQuestionsControllerGetQustionsParams,
      params: RequestParams = {}
    ) =>
      this.request<BaseResponseDto, BaseResponseDto>({
        path: `/vod/api/wp/questions/questions`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
}
