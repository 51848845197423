import { useState, useEffect, useRef } from 'react';
// @mui
import { useMutation } from "@tanstack/react-query";
import { Container, Stack, Typography, Button, Box ,TextField, InputAdornment,Drawer,Pagination, Grid, List, ListItem, Divider} from '@mui/material';
// config
// _mock
import { _courses } from 'src/_mock';
// components
//
import { useNavigate,useParams } from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import { createBrowserHistory } from "history";
import { ICourseFiltersProps } from 'src/types/course';
import {WpVodControllerGetProductsParams, WpVodControllerGetTeachers2Params, WpVodControllerTeacherReivewParams} from "src/generated"
import Image from 'src/components/image';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BadgeIcon from '@mui/icons-material/Badge';
import SchoolIcon from '@mui/icons-material/School';
import ArticleIcon from '@mui/icons-material/Article';
import StarIcon from '@mui/icons-material/Star';
import Carousel, { CarouselArrows, CarouselDots } from 'src/components/carousel';
import { useTheme } from '@mui/material/styles';
import TestimonialItemTeacher from 'src/sections/testimonial/e-commerce/TestimonialItemTeacher';
import { publicApi } from "../../../react-query";
import SelectProductModal from '../teacher/item/SelectProductModal';


// ----------------------------------------------------------------------
const fetcherTeachers = (params: WpVodControllerGetTeachers2Params) => publicApi.vod.wpVodControllerGetTeachers2(params);
const fetcherCourses = (params: WpVodControllerGetProductsParams) => publicApi.vod.wpVodControllerGetProducts(params);
const fetcherReview = (params: WpVodControllerTeacherReivewParams) =>
  publicApi.vod.wpVodControllerTeacherReivew(params);
const defaultValues = {
  filterDuration: [],
  filterCategories: [],
  filterRating: "all",
  filterFee: [],
  filterLevel: ["all"],
  filterLanguage: [],
};

export default function ElearningTeacherView() {
  /* eslint-disable */
  const [TeacherIdx,  setTeacherIdx] = useState<any>();
  const [TeacherData,  setTeacherData] = useState<any[]>([]);
  const [Open, setOpen] = useState<any>(false);
  const [CourseList, setCourseList] = useState<any>([]);
  const navigate = useNavigate();
  const [TeacherAvg, setTeacherAvg] = useState<any>("");
  const [TeacherCount, setTeacherCount] = useState<any>("");
  const [ReviewList, setReviewList] = useState<any>("");
  const isMdUp = useResponsive('up', 'md');
  const carouselRef = useRef<Carousel | null>(null);

  const {data : LengthList ,mutate: mutateCourses} = useMutation((params : WpVodControllerGetProductsParams) => fetcherCourses(params), {
    onSuccess: (e : any) => {
      // console.log(e)
      if (e.data.header.isSuccess) {
        console.log(e)
        // setPageSize(e.data.body.total)
        let list : any = []
        e.data.body.data.map((v :any )=>{
          list.push({id : v.vodProductIdx , 
            createdAt: new Date(),
            slug : v.vodProductNameForStudents,
            coverImg: v.vodProductPhotoFilePath,
            category : v.vodProductCategory,
            description : v.vodProductInfo,
            price : v.vodProductPrice , 
            level : v.vodProductLevel === 1 ? "Beginner" : v.vodProductLevel === 2 ? "Intermediate" : "Expert", 
            priceSale : 0,
            ratings : v.vodEvaluationScore ,
            reviews : v.vodEvaluationCount , 
            quizzes : 1 ,
            students : v.studentCount ,
            usetimer : v.isVodProductTimer,
            time : v.vodProductTimer,
            // resources : 100,
            // totalHours : Number(v.vodDuration.slice(0,2)) + Math.ceil(Number(v.vodDuration.slice(3,5))/60),
            totalHours : `${Number(v.vodDuration.split(":")[0])}시간 ${Number(v.vodDuration.split(":")[1])}분 `,
            teachers : [
              {
                id : 1 ,
                name : v.teacherName,
                // role : "teacher",
                picture : v.teacherPhotoFilePath,
                // courses : 46,
                // reviews : 100,
                // student : 40,
                // rating : 3.5

              }
            ],
            isLive :{
              isLive : v.isLive,
              liveCount : v.liveCount,
              liveLimit : v.liveLimit,
              liveWeek : v.liveWeek,
              liveDuration : v.liveDuration,
            }
          // languages: ['Russian', 'Spanish', 'English'],
          // skills: ['Photography', 'Design', 'Art', 'History', 'Museums', 'Creativity', 'Art History'],
          // learnList: [
          //   'A fermentum in morbi pretium aliquam adipiscing donec tempus.',
          //   'Vulputate placerat amet pulvinar lorem nisl.',
          //   'Consequat feugiat habitant gravida quisque elit bibendum id adipiscing sed.',
          //   'Etiam duis lobortis in fames ultrices commodo nibh.',
          //   'Fusce neque. Nulla neque dolor, sagittis eget, iaculis quis, molestie non, velit.',
          //   'Curabitur a felis in nunc fringilla tristique. Praesent congue erat at massa.',
          // ],
          })
        })
        console.log(list)
        setCourseList(list)
      } else {
        if(e.data.header.resultCode == 403){
        
          navigate("/auth/duplicated")
        }
        // message.error(e.data.header.resultMessage);
      }
    },
    onError: (e) => {
      console.log("login onError : ", e);
    },
  });

  useEffect(() => {
    const url = window.location.href;
      const params = new URLSearchParams(new URL(url).search);
      const TeacherIdx = params.get('id');
      setTeacherIdx(TeacherIdx) 
      mutateReview({
        sKey : sessionStorage.getItem('workingEnv') === "Build"? window.location.host : sessionStorage.getItem('workingEnv') as string,
        teacherIdx : Number(TeacherIdx),

      })
  }, []);

  const { mutate: mutateReview } = useMutation(
    (params: WpVodControllerTeacherReivewParams) => fetcherReview(params),
    {
      onSuccess: (e: any) => {
        console.log(e)
        if (e.data.header.isSuccess) {
       
          setTeacherAvg(e.data.body.data.summary[0]?.teacherEvaluationAvg === undefined ? "0.0" : e.data.body.data.summary[0]?.teacherEvaluationAvg)
          setTeacherCount(e.data.body.data.summary[0]?.teacherEvaluationCount  === undefined ? "0" :e.data.body.data.summary[0]?.teacherEvaluationCount)
          setReviewList(e.data.body.data.detail?.length  === 0 ? []:e.data.body.data.detail)
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  useEffect(()=>{
    mutateTeachers({
      teacherIdx : TeacherIdx,
      sKey : sessionStorage.getItem('workingEnv') === "Build"? window.location.host : sessionStorage.getItem('workingEnv') as string,
      page : 1,
      pageSize : 9
    })
  },[TeacherIdx])
  
  const {data : Teachers ,mutate: mutateTeachers} = useMutation((params : WpVodControllerGetTeachers2Params) => fetcherTeachers(params), {
    onSuccess: (e : any) => {
      // console.log(e)
      if (e.data.header.isSuccess) {
        console.log(e)
        setTeacherData(e.data.body.data)
      } else {
        if(e.data.header.resultCode == 403){
          navigate("/auth/duplicated")
        }
      }
    },
    onError: (e) => {
      console.log("login onError : ", e);
    },
  });

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
    console.log()
  };
  const theme = useTheme();

  const carouselSettings = {
    dots: !isMdUp,
    arrows: false,
    slidesToShow: ReviewList?.length === 1 ? 1 : ReviewList?.length === 2?  2  : 3,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    ...CarouselDots({
      sx: {
        mt: 8,
      },
    }),
    responsive: [
      {
        // Down md
        breakpoint: theme.breakpoints.values.md,
        settings: { slidesToShow: 2, slidesToScroll: 3 },
      },
      {
        // Down sm
        breakpoint: theme.breakpoints.values.sm,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  return (
    <Container sx={{py: 2}}>
      <Typography
        variant="h3"
        sx={{
          display: 'block',
          mb: { xs: 2, md: 5 },
        }}
      >
        {TeacherData[0]?.teacherName} Teacher 소개
      </Typography>

      <Grid
        container
        spacing={3}
        // alignItems={{ md: 'center' }}
        pb={3}
        // justifyContent={{ md: 'space-between' }}
      >
        <Grid item xs={12} sm={4} lg={3} display={"flex"} justifyContent={"center"} alignItems={"center"}p={"20px !important"} >
          <Image
            alt="about"
            src={TeacherData[0]?.teacherPhotoFilePath === null || TeacherData[0]?.teacherPhotoFilePath === "" ? '/assets/images/portrait/portrait_1.jpg' :Teachers?.data.body.data[0].teacherPhotoFilePath}
            // ratio="1/1"
            sx={{ 
              borderRadius: 2, 
              aspectRatio: '3 / 4', // 원하는 비율로 변경
              width: '100%',
              height: 'auto'
            }}
          />
        </Grid>

        <Grid item xs={12} sm={8} lg={9}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            {TeacherData[0]?.teacherName}
          </Typography>
          
          <Stack direction="row" alignItems="center">
            {TeacherData[0]?.teacherCategoryGroupInformation?.map((v :any,i :any)=>{
              return <Typography variant="overline" sx={{ color: 'primary.main', ml: 1, mr: 0.5, mb: 4 }}>{i === 0 ? "": " / "} {v.teacherCategoryInformation.teacherCategoryName }</Typography>
            })}
          </Stack>

          <Stack
            direction={{ xs: 'column', md: 'column' }}
            spacing={1}
            // sx={{ mt: { xs: 3, md: 3} }}
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" sx={{ ml: 1, mr: 0.5, color: '#637381!important' }}>
                나이 : {TeacherData[0]?.teacherAge}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Typography variant="body2" sx={{ ml: 1, mr: 0.5, color: '#637381!important' }}>
                성별 : {TeacherData[0]?.teacherSex === "m"?"남성" : "여성"}
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems="center" gap={1}>
                <StarIcon color="warning" />
                <Typography fontWeight={700}>{TeacherAvg}</Typography>
                <Typography>({TeacherCount} reviews)</Typography>
              </Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" sx={{ ml: 1, mr: 0.5, mt: 3, color: '#637381!important' }}>
                {TeacherData[0]?.teacherComment}
              </Typography>
            </Stack>
            
          </Stack>
          
      </Grid>

    </Grid>

    <Divider />

    <Grid
      container
      spacing={2}
      alignItems={{ md: 'center' }}
      justifyContent={{ md: 'space-between' }}
    >
      <Grid item xs={12}>
        <Stack 
          // direction={{ xs: 'column', md: 'row' }}
          sx={{ mt: { xs: 3, md: 3 }, display:"flex", alignItems:"center" }}
        >
          <Button 
            size="large"
            variant="soft" 
            fullWidth
            color="success"
            sx={{ maxWidth:"250px" }}
            onClick={()=>{
              mutateCourses({
                teacherIdx : TeacherData[0]?.teacherIdx,
                sKey : sessionStorage.getItem('workingEnv') === "Build"? window.location.host : sessionStorage.getItem('workingEnv') as string,
              })
              setOpen(true)
            }}
          >
            수업 구매하기
          </Button>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 5, md: 10 }}
          sx={{ mt: { xs: 4, md: 6 } }}
        >
          <Stack 
            spacing={3}
            sx={{
              "& div" : {
                "& p": {
                    marginTop : 0,
                    "& span": {
                      color: '#637381!important'
                    }
                }
              }
            }}
          >
            <Typography fontWeight={700} sx={{display:'flex'}}>
              <BusinessCenterIcon sx={{mr: 1}} /> Experience of Teaching
            </Typography>
            <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
            <div dangerouslySetInnerHTML={{ __html: TeacherData[0]?.teacherExperience as string }}/>
          </Stack>

        </Stack>
      </Grid> 

      <Grid item xs={12} >
        <Stack 
          spacing={3}
          sx={{
            "& div" : {
              "& p": {
                marginTop : 0,
                "& span": {
                  color: '#637381!important'
                }
              }
            }
          }}
        >
          <Typography fontWeight={700} sx={{display:'flex'}}>
            <BadgeIcon sx={{mr: 1}} /> Certification
          </Typography>
          <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
          <div dangerouslySetInnerHTML={{ __html: TeacherData[0]?.teacherCertification as string }}/>
        </Stack>
      </Grid> 

      <Grid item xs={12}>
        <Stack 
          spacing={3}
          sx={{
            "& div" : {
              "& p": {
                marginTop : 0,
                "& span": {
                  color: '#637381!important'
                }
              }
            }
          }}
        >
            <Typography fontWeight={700} sx={{display:'flex'}}>
              <SchoolIcon sx={{mr: 1}} /> Degree
            </Typography>
            <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
            <div dangerouslySetInnerHTML={{ __html: TeacherData[0]?.teacherDegree as string }}/>
        </Stack>
      </Grid> 

      <Grid item xs={12} pb={5}>
        <Stack spacing={3}>
          <Typography fontWeight={700} sx={{display:'flex'}}>
            <ArticleIcon sx={{mr: 1}} /> More Information
          </Typography>
          <Box sx={{ width: 24, height: 3, bgcolor: 'primary.main' }} />
          <List sx={{ mt:'11px !important' }}>
            <ListItem sx={{ pl:'1px !important', color: '#637381!important' }}>
            Email : {TeacherData[0]?.teacherId}
            </ListItem>
            <ListItem sx={{ py:'2px !important', pl:'1px !important', color: '#637381!important' }}>
            Phone : {TeacherData[0]?.teacherPhone1}
            </ListItem>
          </List>    
        </Stack>
      </Grid> 
      <Container
      sx={{
        pt: 8,
        pb: { xs: 8, md: 10 },
      }}
    >
          <Stack
        direction={{ xs: 'column', md: 'row' }}
        alignItems={{ md: 'flex-end' }}
        sx={{
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
   <Stack spacing={3} flexGrow={1}>
          <Typography variant="h3">리뷰</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            수강생들의 수업 후기
          </Typography>
        </Stack>
        {isMdUp && (
          <CarouselArrows
            spacing={2}
            justifyContent="center"
            onNext={handleNext}
            onPrev={handlePrev}
          />
        )}
      </Stack>

      {ReviewList?.length === 0 ? 
      (
        <Box display="flex" flexDirection={'column'} sx={{pb:0}}>
              <Image
                alt="comingsoon"
                src="/assets/illustrations/illustration_comingsoon.svg"
                sx={{
                  my: 0,
                  mx: 'auto',
                  maxWidth: 320,
                }}
              />
                <span style={{ fontSize: 16, fontWeight: 400, margin: 'auto' ,color:"#9599a7"}}>
                등록된 리뷰가 없습니다.
              </span>
            </Box>

)

      :
      <Carousel ref={carouselRef} {...carouselSettings}>
      
        {ReviewList?.map((testimonial :any) => (
        <Box 
        sx={{
          px: 2,
          pt: { xs: 1, md: 10 },
          pb: { xs: 0, md: 15 },
        }} 
        //  sx={{
        //   display: 'grid',
        //   gap: { xs: 3, md: 4 },
        //   gridTemplateColumns: {
        //     xs: 'repeat(1, 1fr)',
        //     md: 'repeat(3, 1fr)',
        //   },
        // }}
        >
            <TestimonialItemTeacher testimonial={testimonial} />
          </Box>
        ))}
      </Carousel>
      }
    </Container>
    </Grid>
    <SelectProductModal Open={Open} setOpen={setOpen} data={CourseList} teacherIdx={TeacherData[0]?.teacherIdx} teacherName={TeacherData[0]?.teacherName} photo={TeacherData[0]?.teacherPhotoFilePath}/>
  </Container>
);
}





// ----------------------------------------------------------------------
