// @mui
import {
  Box,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';

// hooks
// utils
// routes
// config
// components

// ----------------------------------------------------------------------


export default function BannerImage({img ,link} : any) {
  /* eslint-disable */
  const [Open, setOpen] = useState(true)
  const isMdUp = useResponsive('up', 'lg');

   return (
    <div
    style={{
      display: Open ? 'flex' : "none",
      justifyContent: 'center',
      position: 'fixed',
      bottom: '1%',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 9999,
    }}
  >
      <div
          style={{ width: isMdUp ? '70%' : "100%", display: 'flex', justifyContent: 'flex-end', marginBottom: '4px' }}
          >
          <IconButton
            sx={{ width: '24px', height: '24px', border: '1px solid #A67BF7' }}
         
            onClick={()=>{
              setOpen(false)
              sessionStorage.setItem("bannerShow" , "false")
            }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // position: 'fixed',
        // bottom: '1%',
        flexDirection: isMdUp ? "row" :"column",
        width: isMdUp ? '70%' : "98%",
        height: '80px',
        backgroundColor: '#FF0014',
        borderRadius: '8px',
        zIndex: 9999,
        boxShadow: '0px 0px 2px #919EAB',
        // '&:hover': {
        //   bgcolor: 'primary.dark',
        // },
      }}
    >
      <img src={img} alt='' style={{width : "100%" ,height:"100%"}}  onClick={()=>{
            window.open(link,"_blank")
          }}/>
    </Box>
  </div>
  );
}

