import * as React from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import {
  Divider,
  Stack,
  Card,
  Typography,
  Box,
  Link,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// utils
import { fCurrency, fShortenNumber } from 'src/utils/formatNumber';
// types
import { ICourseProps } from 'src/types/course';
// components
import { useMutation } from '@tanstack/react-query';
import useResponsive from 'src/hooks/useResponsive';

import Image from 'src/components/image';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsCart, BsCartFill } from 'react-icons/bs';
import { privateApi } from 'src/react-query';
import { CreateWishlistDto } from 'src/generated';
import Timer from 'src/components/timer/Timer';
// import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

type Props = {
  course: any;
  vertical?: boolean;
  length?: number;
};
const fetcherMyWish = () => privateApi.vod.wpVodControllerGetWishlist();
const fetcherAddMyWish = (params: CreateWishlistDto) =>
  privateApi.vod.wpVodControllerCreateWishlist(params);
const fetcherDelMyWish = (vodProductIdx: string) =>
  privateApi.vod.wpVodControllerDeleteWishlist(vodProductIdx);

export default function ElearningCourseItemCard({ course, vertical, length }: Props) {
  /* eslint-disable */
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const navigate = useNavigate();
  const isMdUp = useResponsive('up', 'md');
  const isSmUp = useResponsive('up', 'sm');
  const [Check, setCheck] = React.useState<any>();

  const {
    id,
    slug,
    level,
    price,
    ratings,
    reviews,
    teachers,
    students,
    coverImg,
    category,
    priceSale,
    bestSeller,
    totalHours,
    usetimer,
    time,
    description,
    isLive,

  } = course;
  const [wishList, setwishList] = useState<any[]>([]);
  const [DateValue, setDateValue] = useState<any[]>([]);

  useEffect(() => {
    setDateValue(course.isLive?.liveWeek === null ? [] : course.isLive?.liveWeek?.split(','));

    // console.log(teachers)
  }, [course]);
  const { mutate: mutateMyWish } = useMutation(() => fetcherMyWish(), {
    onSuccess: (e) => {
      // console.log(e)
      let list: any = [];
      if (e.data.header.isSuccess) {
        console.log(e.data.body.data);

        e.data.body.data.map((v: any) => {
          list.push(v.vodProductIdx);
        });
        console.log(list);
        setwishList(list);
      } else {
        if (e.data.header.resultCode == 403) {
          navigate('/auth/duplicated');
        }
        // message.error(e.data.header.resultMessage);
      }
    },
    onError: (e) => {
      console.log('login onError : ', e);
    },
  });
  const { mutate: AddWishList } = useMutation(
    (params: CreateWishlistDto) => fetcherAddMyWish(params),
    {
      onSuccess: (e) => {
        // console.log(e)
        let list: any = [];
        if (e.data.header.isSuccess) {
          console.log(e.data);
          setMessage('추가되었습니다.');
          setOpen(true);
          mutateMyWish();
        } else {
          if (e.data.header.resultCode == 403) {
            if (
              e.data.header.resultMessage ===
              '동일한 아이디로 중복 로그인하여, 자동으로 로그아웃 되었습니다.'
            ) {
              navigate('/auth/duplicated');
            }
          }

          setMessage(
            e.data.header.resultMessage === 'Unauthorized'
              ? '로그인이 필요합니다'
              : e.data.header.resultMessage
          );
          setOpen(true);
          // message.error(e.data.header.resultMessage);
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );
  const { mutate: DelWishList } = useMutation(
    (vodProductIdx: string) => fetcherDelMyWish(vodProductIdx),
    {
      onSuccess: (e) => {
        // console.log(e)
        let list: any = [];
        if (e.data.header.isSuccess) {
          console.log(e.data);
          setMessage('제거되었습니다.');
          setOpen(true);
          mutateMyWish();
        } else {
          if (e.data.header.resultCode == 403) {
            navigate('/auth/duplicated');
          }
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  useEffect(() => {
    mutateMyWish();
  }, []);
  return (
    <Card
      sx={{
        display: { sm: 'flex' },
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        ...(vertical && {
          flexDirection: 'column',
        }),
        margin: 'auto',
        width: length === 1 ? { sm: '33%' } : length === 2 ? { sm: '70%' } : { sm: '100%' },
      }}
    >
      <Box sx={{ flexShrink: { sm: 0 } }}>
        <Image
          alt={slug}
          src={coverImg === null ? '/assets/images/course/course_1.jpg' : coverImg}
          sx={{
            cursor: 'pointer',
            height: { md: 200, xs: 150 },
            objectFit: 'cover',
            width: { sm: 150 },
            ...(vertical && {
              width: { sm: 1 },
            }),
          }}
          onClick={() => {
            console.log(id);
            window.sessionStorage.setItem('Product', id);
            navigate(`/e-learning/course?id=${id}`);
          }}
        />
      </Box>

      {bestSeller && (
        <Label
          color="warning"
          variant="filled"
          sx={{ top: 12, left: 12, position: 'absolute', textTransform: 'uppercase' }}
        >
          Best Seller
        </Label>
      )}

      <Stack sx={{ p: 2 }} width={'100%'}>
        {/* 카테고리 가격 제목 */}
        <Stack
        // spacing={{
        //   xs: 3,
        //   sm: vertical ? 1 : 1,
        // }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
          <TextMaxLine variant="overline" sx={{ color: 'primary.main' }} line={1}>

              {category}
          </TextMaxLine>

            {/* <Typography variant="overline" sx={{ color: 'primary.main' }}>
            </Typography> */}

            <Typography variant="body1" sx={{ display: { xs: 'none' } }}>
              {priceSale > 0 && (
                <Box
                  component="span"
                  sx={{ mr: 0.5, color: 'text.disabled', textDecoration: 'line-through' }}
                >
                  {priceSale} 원
                </Box>
              )}
              {price.toString().slice(0, -3)}원
           
            </Typography>
          </Stack>
          <Stack></Stack>
        </Stack>
        <Stack display={"flex"} flexDirection={"column"}sx={{ mb: 1 }} spacing={0}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link component={RouterLink} to={`${paths.eLearning.course}?id=${id}`} color="inherit">
              <TextMaxLine
                persistent={true}
                variant="body2"
                fontWeight={700}
                line={isMdUp ? 1 : 2}
                onClick={() => {
                  console.log(id);
                  window.sessionStorage.setItem('Product', id);
                }}
              >
                {slug}
              </TextMaxLine>
            </Link>
            {/* {
              wishList.includes(id) ?        
            <BsCartFill style={{cursor :"pointer" , color : "red"}} onClick={()=>{
              DelWishList(id)
            }}></BsCartFill>
:          
  <BsCart style={{cursor :"pointer"}}
  onClick={()=>{
    // DelWishList(id)
    AddWishList({
      vodProductIdx : Number(id)
      })
      }}
      ></BsCart>
      
      } */}
            {/* <AiFillHeart style={{cursor :"pointer" , color : "red"}}></AiFillHeart>
            <AiOutlineHeart style={{cursor :"pointer"}}></AiOutlineHeart> */}
          </div>
            <Box display={'flex'} sx={{color:"red"}} minHeight={20}>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.isLive ? 'Live/' : ''}
                </Typography>
      
                <Box display={'flex'}>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {DateValue.length !== 0 ? `(` : ''}
                  </Typography>
      
                  {DateValue?.map((v,i) => (
                    <>
                    {i !== 0 && <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                     ,
                    </Typography>}
                    <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                      {v === '1'
                        ? '월'
                        : v === '2'
                        ? '화'
                        : v === '3'
                        ? '수'
                        : v === '4'
                        ? '목'
                        : v === '5'
                        ? '금'
                        : v === '6'
                        ? '토'
                        : '일'}
                    </Typography>
                    </>
                  ))}
                  <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {DateValue.length !== 0 ? `)` : ''}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.liveCount ? `/${isLive.liveCount}회` : ''}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.liveLimit ? `/1:${isLive.liveLimit}` : ''}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.liveDuration ? `/ ${isLive.liveDuration}분` : ''}
                </Typography>
              </Box>

          {/* <TextMaxLine
              variant="body2"
              color="text.secondary"
              sx={{
                ...(vertical && {
                  display: { sm: 'none' },
                }),
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: description as string }}/>

          
            </TextMaxLine> */}
        </Stack>
        <Stack sx={{ mb: 1 }} spacing={1}>
       
              <Typography
              
                variant="body2"
                fontWeight={700}
              
              >
                {fCurrency(price)}
               
              </Typography>
         


       
        </Stack>

        <Stack
          spacing={{ md: 1.5, xs: 1 }}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={{ color: 'text.disabled', mb: 0.5 }}
          // divider={<Divider orientation="vertical" sx={{ height: 20, my: 'auto' }} />}
        >
          <Stack spacing={0.5} direction="row" alignItems="center">
            <Stack direction="row" alignItems="center">
              <Iconify icon="carbon:star-filled" sx={{ color: 'warning.main' }} />
              <Box sx={{ typography: 'body2', pt: 0.3, pl: 0.5 }}>
                {/* {Number.isInteger(ratings) ? `${5}.0` : ratings} */}
                {ratings}
              </Box>
            </Stack>
            {reviews && (
              <Typography variant="body2" sx={{ color: 'text.secondary', display: { xs: 'none' } }}>
                ({fShortenNumber(reviews)} 개)
              </Typography>
            )}
          </Stack>

          <Stack direction="row" sx={{ typography: 'subtitle2', pt: 0.3 }}>
            {students === 0 ? '0' : fShortenNumber(students)}
            <Box component="span" typography="body2" sx={{ md: { ml: 0.5 } }}>
              명 수강중
            </Box>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 2.5 } }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{ typography: 'body2', display: { xs: 'none' } }}
          >
            <Iconify icon="carbon:time" sx={{ mr: 1 }} /> {`${totalHours} 시간`}
          </Stack>

          <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
            <Iconify
              icon={
                (level === 'Beginner' && 'carbon:skill-level-basic') ||
                (level === 'Intermediate' && 'carbon:skill-level-intermediate') ||
                'carbon:skill-level-advanced'
              }
              sx={{ mr: 1, width: 15, ml: 0.4 }}
            />
            {level}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ mt: 1}}>
          <Avatar sx={{ width: 20, height: 20 }} src={teachers[0]?.picture} />

          <Typography variant="body2" sx={{ ml: 1, mr: 0.5 }}>
            {teachers[0]?.name}
          </Typography>

          {teachers?.length > 1 && (
            <Link underline="always" color="text.secondary" variant="body2">
              + {teachers?.length - 1} teachers
            </Link>
          )}
        </Stack>
        {usetimer === 1 && <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 2.5 } }}
        >
          <Timer time={time} timeleftcheck={setCheck} label={false}/>

        </Stack>}
        <Divider
          sx={{
            borderStyle: 'dashed',
            display: { sm: 'none' },
            ...(vertical && {
              display: 'block',
            }),
            my: usetimer === 1 ? 0.5 :2.4,
          }}
        />
        <Stack direction="row" sx={{ gap: 1, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="soft"
            size="medium"
            color="success"
            disabled={usetimer === 1 &&Check?.length===0}

            sx={{ px: { md: 5 } }}
            onClick={() => {
              if (sessionStorage.getItem('x-at') !== null) {
                window.sessionStorage.setItem('Product', id);
                navigate('/e-commerce/onecheckout');
              } else {
                setOpen2(true);
              }
            }}
          >
            
            {isMdUp && <Typography variant="subtitle2">           {sessionStorage.getItem('Dns') === "oheng.learnex.kr" ? "수업 참여하기" :  "구매하기"}
</Typography>}
            {!isMdUp && <Typography variant="subtitle2">           {sessionStorage.getItem('Dns') === "oheng.learnex.kr" ? "참여" :  "구매"}
</Typography>}
          </Button>
          <Button
            variant={'soft'}
            color={wishList.includes(id) ? 'primary' : 'inherit'}
            size="medium"
            sx={{ display: 'flex', justifyContent: 'center', gap: 1 }}
            // <Button variant='soft'size='medium' sx={wishList.includes(id) ?  {display:"flex", justifyContent:"center", gap: 1, backgroundColor: "rgba(250, 84, 28, 0.32)"}: {display:"flex", justifyContent:"center", gap: 1}}
            onClick={() => {
              wishList.includes(id)
                ? DelWishList(id)
                : AddWishList({
                    vodProductIdx: Number(id),
                  });
            }}
          >
            <BsCartFill size={18} style={{ cursor: 'pointer' }}></BsCartFill>
            {/* {
              wishList.includes(id) ?        
            <BsCartFill style={{cursor :"pointer" , color : "orange"}} ></BsCartFill>
:          
  <BsCartFill size={18}
  style={{cursor :"pointer"}}
 

  ></BsCartFill>

            } */}
          </Button>
        </Stack>

        {/* <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 2.5 } }}
        >
          <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
            <Iconify icon="carbon:time" sx={{ mr: 1 }} /> {`${totalHours} 시간`}
          </Stack>

          <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
            <Iconify
              icon={
                (level === 'Beginner' && 'carbon:skill-level-basic') ||
                (level === 'Intermediate' && 'carbon:skill-level-intermediate') ||
                'carbon:skill-level-advanced'
              }
              sx={{ mr: 1 }}
            />
            {level}
          </Stack>
        </Stack> */}
      </Stack>
      <Dialog
        fullWidth
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <DialogContent >
          <div
            style={{height: 90, display: 'flex', justifyContent: 'center', alignItems: 'flex-end' }}
          >
            <Typography variant="h5" sx={{pt: 5}}>{Message}</Typography>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              // console.log("ss")
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => {
          setOpen2(false);
        }}
        open={open2}
      >
        <DialogContent>
          <div
            style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography variant="h5">로그인이 필요합니다</Typography>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen2(false);
              // console.log("ss")
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}