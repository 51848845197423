import * as React from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import {
  Divider,
  Stack,
  Card,
  Typography,
  Box,
  Link,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// utils
import { fCurrency, fShortenNumber } from 'src/utils/formatNumber';
// types
import { ICourseProps } from 'src/types/course';
// components
import { useMutation } from '@tanstack/react-query';
import useResponsive from 'src/hooks/useResponsive';

import Image from 'src/components/image';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsCart, BsCartFill } from 'react-icons/bs';
import { privateApi, publicApi } from 'src/react-query';
import { CreateWishlistDto, WpVodControllerGetProductsParams } from 'src/generated';
import Timer from 'src/components/timer/Timer';
import SelectProductModal from './SelectProductModal';
// import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

type Props = {
  teacher: any;
  vertical?: boolean;
  length?: number;
};
const fetcherCourses = (params: WpVodControllerGetProductsParams) => publicApi.vod.wpVodControllerGetProducts(params);


export default function ElearningCourseItemCard({ teacher, vertical, length }: Props) {
  /* eslint-disable */
  const [Open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const navigate = useNavigate();
  const isMdUp = useResponsive('up', 'md');
  const isSmUp = useResponsive('up', 'sm');
  const [Check, setCheck] = React.useState<any>();
  const [CourseList, setCourseList] = React.useState<any>([]);
  const {data : LengthList ,mutate: mutateCourses} = useMutation((params : WpVodControllerGetProductsParams) => fetcherCourses(params), {
    onSuccess: (e : any) => {
      // console.log(e)
      if (e.data.header.isSuccess) {
        console.log(e)
        // setPageSize(e.data.body.total)
        let list : any = []
        e.data.body.data.map((v :any )=>{
          list.push({id : v.vodProductIdx , 
            createdAt: new Date(),
            slug : v.vodProductNameForStudents,
            coverImg: v.vodProductPhotoFilePath,
            category : v.vodProductCategory,
            description : v.vodProductInfo,
            price : v.vodProductPrice , 
            level : v.vodProductLevel === 1 ? "Beginner" : v.vodProductLevel === 2 ? "Intermediate" : "Expert", 
            priceSale : 0,
            ratings : v.vodEvaluationScore ,
            reviews : v.vodEvaluationCount , 
            quizzes : 1 ,
            students : v.studentCount ,
            usetimer : v.isVodProductTimer,
            time : v.vodProductTimer,
            // resources : 100,
            // totalHours : Number(v.vodDuration.slice(0,2)) + Math.ceil(Number(v.vodDuration.slice(3,5))/60),
            totalHours : `${Number(v.vodDuration.split(":")[0])}시간 ${Number(v.vodDuration.split(":")[1])}분 `,
            teachers : [
              {
                id : 1 ,
                name : v.teacherName,
                // role : "teacher",
                picture : v.teacherPhotoFilePath,
                // courses : 46,
                // reviews : 100,
                // student : 40,
                // rating : 3.5

              }
            ],
            isLive :{
              isLive : v.isLive,
              liveCount : v.liveCount,
              liveLimit : v.liveLimit,
              liveWeek : v.liveWeek,
              liveDuration : v.liveDuration,
            }
          // languages: ['Russian', 'Spanish', 'English'],
          // skills: ['Photography', 'Design', 'Art', 'History', 'Museums', 'Creativity', 'Art History'],
          // learnList: [
          //   'A fermentum in morbi pretium aliquam adipiscing donec tempus.',
          //   'Vulputate placerat amet pulvinar lorem nisl.',
          //   'Consequat feugiat habitant gravida quisque elit bibendum id adipiscing sed.',
          //   'Etiam duis lobortis in fames ultrices commodo nibh.',
          //   'Fusce neque. Nulla neque dolor, sagittis eget, iaculis quis, molestie non, velit.',
          //   'Curabitur a felis in nunc fringilla tristique. Praesent congue erat at massa.',
          // ],
        })
      })
      console.log(list)
      setCourseList(list)
      } else {
        if(e.data.header.resultCode == 403){
          navigate("/auth/duplicated")
        }
        // message.error(e.data.header.resultMessage);
      }
    },
    onError: (e) => {
      console.log("login onError : ", e);
    },
  });
  
  return (
    <Card
      sx={{
        display: { sm: 'flex' },
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        ...(vertical && {
          flexDirection: 'column',
        }),
        margin: 'auto',
        // width: length === 1 ? { sm: '33%' } : length === 2 ? { sm: '70%' } : { sm: '100%' },
      }}
    >
      <Box sx={{ flexShrink: { sm: 0 } }}>
        <Image
          alt={teacher?.teacherName}
          src={teacher?.teacherPhotoFilePath === null || teacher?.teacherPhotoFilePath === "" ? '/assets/images/portrait/portrait_1.jpg' : teacher?.teacherPhotoFilePath}
          sx={{
            cursor: 'pointer',
            height: { md: 200, xs: 150 },
            objectFit: 'cover',
            width: { sm: 150 },
            ...(vertical && {
              width: { sm: 1 },
            }),
          }}
          onClick={() => {
            
          }}
        />
      </Box>

      <Stack sx={{ p: 2 }} width={'100%'}>
        {/* 카테고리 가격 제목 */}
        <Stack
        // spacing={{
        //   xs: 3,
        //   sm: vertical ? 1 : 1,
        // }}
        >
          <Stack direction="row" alignItems="center" sx={{my:{xs:0.5, md: vertical ? -1.5 : 0}}}>
            <TextMaxLine
              persistent={true}
              variant="body2"
              // fontWeight={700}
              line={1}
              onClick={() => {
              }}
            >
              {/* {teacher?.teacherComment} */}
              {teacher?.teacherCategoryGroupInformation?.map((v :any,i :any)=>{                
                return <Typography variant="overline" sx={{ color: 'primary.main' }}>{i === 0 ? "": " / "}{v.teacherCategoryInformation.teacherCategoryName }</Typography>
              })}
            </TextMaxLine>
          </Stack>
        </Stack>
        
        <Stack sx={{ mt: 1 }} spacing={1}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link component={RouterLink} to={`${paths.eLearning.teacher}?id=${teacher?.teacherIdx}`} color="inherit">
              <TextMaxLine
                persistent={true}
                variant="body2"
                fontWeight={700}
                line={isMdUp ? 1 : 2}
                onClick={() => {
                }}
              >
                {teacher?.teacherName}         
              </TextMaxLine>
            </Link>
          </div>
        </Stack>

        <Stack
          spacing={{ md: 1.5, xs: 1 }}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          sx={{ color: 'text.disabled', mb: 0.5 }}
          // divider={<Divider orientation="vertical" sx={{ height: 20, my: 'auto' }} />}
        >
        </Stack>

        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 2.5 } }}
        >
        </Stack>
        
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" sx={{ color: '#637381' }}>
            나이 : {teacher?.teacherAge}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Typography variant="body2" sx={{ color: '#637381' }}>
            성별 : {teacher?.teacherSex === "m"?"남성" : "여성"}
          </Typography>
        </Stack>
        
        <TextMaxLine
          sx={{ color: '#637381' }}
          persistent={true}
          variant="body2"
          // fontWeight={700}
          line={2}
          onClick={() => {
          }}
        >
          {teacher?.teacherComment}
        </TextMaxLine>
        
        {/* <Stack direction="row" alignItems="center">
          <Typography variant="body2" sx={{ ml: 1, mr: 0.5 }}>
          </Typography>

        </Stack> */}
        {/* <Stack alignItems="start" width={"100%"} minHeight={30} maxHeight={30} sx={{overflowY : "hidden"}}>
          <div dangerouslySetInnerHTML={{ __html: teacher?.teacherComment2 as string }}/>
        </Stack> */}
        
        {/* <Divider
          sx={{
            borderStyle: 'dashed',
            display: { sm: 'none' },
            ...(vertical && {
              display: 'block',
            }),
            my: 2.4,
          }}
        /> */}

        <Stack direction="column" sx={{ gap: 1 ,mt :1, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="outlined"
            size="medium"
            color="inherit"
            sx={{ px: { md: 5 } }}
            onClick={() => {
              navigate(`${paths.eLearning.teacher}?id=${teacher?.teacherIdx}`)
            }}
          >
            {isMdUp && <Typography variant="subtitle2">자세히 보기</Typography>}
            {!isMdUp && <Typography variant="subtitle2"> 자세히 </Typography>}
          </Button>

          <Button
            variant="soft"
            size="medium"
            color="success"
            sx={{ px: { md: 5 } }}
            onClick={() => {
              // navigate(`${paths.eLearning.teacher}?id=${teacher?.teacherIdx}`)
              mutateCourses({
                teacherIdx : teacher?.teacherIdx,
                      sKey : sessionStorage.getItem('workingEnv') === "Build"? window.location.host : sessionStorage.getItem('workingEnv') as string,
              })
              setOpen(true)
            }}
          >
            {isMdUp && <Typography variant="subtitle2">수업 구매하기</Typography>}
            {!isMdUp && <Typography variant="subtitle2"> 구매 </Typography>}
          </Button>
        </Stack>
      </Stack>
      <SelectProductModal Open={Open} setOpen={setOpen} data={CourseList} teacherIdx={teacher?.teacherIdx} teacherName={teacher?.teacherName} photo={teacher?.teacherPhotoFilePath}/>
    </Card>
  );
}