// @mui
import {
  Box,
  Link,
  Stack,
  Button,
  AppBar,
  Toolbar,
  Container,
  Typography,
  Grid,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';

// hooks
// utils
// routes
// config
// components

// ----------------------------------------------------------------------

export default function BannerTimer({label,time,link}:any) {
  /* eslint-disable */
  const isMdUp = useResponsive('up', 'lg');

  const [Open, setOpen] = useState(true)
  const calculateTimeLeft = () => {
    const difference = +new Date(time) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24))  < 10 ?  `0`+Math.floor(difference / (1000 * 60 * 60 * 24)): Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24)  < 10 ? `0`+Math.floor((difference / (1000 * 60 * 60)) % 24): Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60 ) < 10 ? `0`+Math.floor((difference / 1000 / 60) % 60 ): Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60) < 10 ? `0`+Math.floor((difference / 1000) % 60) : Math.floor((difference / 1000) % 60)
      };
    }
    else {
      timeLeft=[]
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      // console.log(timeLeft)
    }, 1000);

    return () => clearTimeout(timer);
  });
  return (
    <>
      {/* <FlipClockCountdown to={new Date().getTime() + 24 * 3600 * 1000 + 5000} /> */}

      <div
        style={{
          display: Open ? 'flex' : "none",
          justifyContent: 'center',
          position: 'fixed',
          bottom: '1%',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 9999,
        }}
      >
        <div
          style={{ width: isMdUp ? '1400px' : "100%", display: 'flex', justifyContent: 'flex-end', marginBottom: '4px' }}
        >
          <IconButton
            sx={{ width: '24px', height: '24px', border: '1px solid #A67BF7' }}
            onClick={()=>{
              setOpen(false)
              sessionStorage.setItem("bannerShow" , "false")
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: isMdUp ? "row" :"column",
            width: isMdUp ? '1400px' : "98%",
            height: isMdUp ? '48px' : "62px",
            backgroundColor: '#FF0014',
            borderRadius: '8px',
            // zIndex: 9999,
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
            gap: 1,
            '&:hover': {
              boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 12px 40px 0 rgba(0,0,0,0.19)',
              // bgcolor: 'error.dark',
            },
          }}
        >
          <Typography fontWeight={'700'} color={'#FFFFFF'} fontSize={isMdUp ? '20px' : "15px"} lineHeight={isMdUp ? '20px' : "15px"}>
           {label}
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <Stack
              sx={{
                backgroundColor: 'black',
                padding: isMdUp ?'2px 4px 2px 4px' : '1px 2px 1px 2px',
                borderRadius: '4px',
                boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
              }}
            >
              <Typography
                fontFamily="DS-Digital"
                color="#FFFFFF"
                fontWeight={'400'}
                fontSize={isMdUp ?'24px' : "17px"}
                letterSpacing={'3px'}
                align="center"
                lineHeight={isMdUp ?'28px' : "15px"}
                paddingBottom={0.5}
                paddingLeft={0.2}

              >
                {timeLeft.length !== 0? timeLeft.days : "00"}
              </Typography>
            </Stack>
            {/* <Typography fontWeight={'600'} color={'#FFFFFF'} fontSize={'32px'} >
            :
          </Typography> */}
            <Typography fontWeight={'600'} color={'#FFFFFF'} fontSize={isMdUp ?'20px' : "15px"}>
              일
            </Typography>
            <Stack
              sx={{
                backgroundColor: 'black',
                padding: isMdUp ?'2px 4px 2px 4px' : '1px 2px 1px 2px',
                borderRadius: '4px',
                boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
              }}
            >
              <Typography
                fontFamily="DS-Digital"
                color="#FFFFFF"
                fontWeight={'400'}
                fontSize={isMdUp ?'24px' : "17px"}
                letterSpacing={'3px'}
                align="center"
                paddingLeft={0.2}

                lineHeight={isMdUp ?'28px' : "15px"}
                paddingBottom={0.5}
              >
                              {timeLeft.length !== 0? timeLeft.hours : "00"}
                                {/* {timeLeft.hours} */}

              </Typography>
            </Stack>
            <Typography fontWeight={'600'} color={'#FFFFFF'} fontSize={isMdUp ?'20px' : "15px"}>
              :
            </Typography>
            <Stack
              sx={{
                backgroundColor: 'black',
                padding: isMdUp ?'2px 4px 2px 4px' : '1px 2px 1px 2px',
                borderRadius: '4px',
                boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
              }}
            >
              <Typography
                fontFamily="DS-Digital"
                color="#FFFFFF"
                fontWeight={'400'}
                fontSize={isMdUp ?'24px' : "17px"}
                letterSpacing={'3px'}
                align="center"
                lineHeight={isMdUp ?'28px' : "15px"}
                paddingLeft={0.2}
                paddingBottom={0.5}
              >
                                                     {timeLeft.length !== 0? timeLeft.minutes : "00"}


              </Typography>
            </Stack>
            <Typography fontWeight={'600'} color={'#FFFFFF'} fontSize={isMdUp ?'20px' : "16px"}>
              :
            </Typography>
            <Stack
              sx={{
                backgroundColor: 'black',
                padding: isMdUp ?'2px 4px 2px 4px' : '1px 2px 1px 2px',
                borderRadius: '4px',
                boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
              }}
            >
              <Typography
                fontFamily="DS-Digital"
                color="#FFFFFF"
                fontWeight={'400'}
                fontSize={isMdUp ?'24px' : "17px"}
                letterSpacing={'3px'}
                align="center"
                lineHeight={isMdUp ?'28px' : "15px"}
                paddingBottom={0.5}
                paddingLeft={0.2}

              >                              {timeLeft.length !== 0? timeLeft.seconds : "00"}

                                {/* {timeLeft.seconds} */}

              </Typography>
            </Stack>
            <Typography fontWeight={'600'} color={'#FFFFFF'} fontSize={isMdUp ?'20px' : "13px"}>
              남았습니다.
            </Typography>
          <Button variant="contained" color="inherit" size={isMdUp ? "small" : "small"} sx={{ fontSize: isMdUp ?'16px' : "13px" ,padding: isMdUp ?'18px' : "0px" }}
          onClick={()=>{
            window.open(link,"_blank")
          }}
          >
            {isMdUp ? "강의 구매하기" :"구매하기"}
          </Button>
          </Stack>
        </Box>
      </div>
    </>
  );
}
