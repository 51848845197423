import { useEffect,useState } from 'react';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider} from 'react-helmet-async';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from 'src/routes';
// theme
import ThemeProvider from 'src/theme';
// components
import ScrollToTop from 'src/components/scroll-to-top';
import { ThemeSettings, SettingsProvider } from 'src/components/settings';
import MotionLazyContainer from 'src/components/animate/MotionLazyContainer';
// import { useMutation } from "@tanstack/react-query";
import { QueryClientProvider,useMutation } from "@tanstack/react-query";
import {DesignDto} from "src/generated"
import axios from 'axios';
import { queryClient,publicApi } from "./react-query";
import { DNS } from './config-global';

// ----------------------------------------------------------------------

export default function App() {
      /* eslint-disable */
  const [workingEnv, setWorkingEnv] = useState(999);
  

  useEffect(()=>{
    console.log(DNS.dns)
    if(workingEnv === 1000){
      sessionStorage.setItem('workingEnv', "test-vod.learnex.kr")
    }
    if(workingEnv ===0){
      sessionStorage.setItem('workingEnv', "demo-vod.learnex.kr")
    }
    if(workingEnv === 1){
      sessionStorage.setItem('workingEnv', "sejongedu.kr")
    }
    // if(workingEnv ===2){
    //   sessionStorage.setItem('workingEnv', "www.hotworld.co.kr")
    // }
    if(workingEnv ===2){
      sessionStorage.setItem('workingEnv', "hotworld.learnex.kr")
    }
    if(workingEnv ===3){
      sessionStorage.setItem('workingEnv', "abc2024.learnex.kr")
    }
    if(workingEnv ===4){
      sessionStorage.setItem('workingEnv', "bookclub.learnex.kr")
    }
    if(workingEnv ===5){
      sessionStorage.setItem('workingEnv', "masada.learnex.kr")
    }
    if(workingEnv === 999){
      sessionStorage.setItem('workingEnv', "Build")
    }
 
    
    axios.post("https://demo-vod.learnex.kr/vod/api/user/design",{
    // axios.post("https://test-vod.learnex.kr/vod/api/user/design",{
    
    sKey : sessionStorage.getItem('workingEnv') === "Build"? window.location.host : sessionStorage.getItem('workingEnv') as string,
   
    }).then((res)=>{
      console.log(res)
      console.log(res.data.body.data)
      sessionStorage.setItem("logoMoImg",res.data.body.data[0].logoMoImg)
      sessionStorage.setItem("logoPcImg",res.data.body.data[0].logoPcImg)
      sessionStorage.setItem("designType",res.data.body.data[0].designType)
      sessionStorage.setItem("homeImg",res.data.body.data[0].homeImg)
      sessionStorage.setItem("homeText",res.data.body.data[0].homeText)
      sessionStorage.setItem("homeVisible",res.data.body.data[0].homeVisible)
      sessionStorage.setItem("categoryVisible",res.data.body.data[0].categoryVisible)
      sessionStorage.setItem("courseVisible",res.data.body.data[0].courseVisible)
      sessionStorage.setItem("footer",res.data.body.data[0].footerInfo)
      sessionStorage.setItem("postVisible",res.data.body.data[0].postVisible)
      sessionStorage.setItem("workVisible",res.data.body.data[0].workVisible)
      sessionStorage.setItem("workImg",res.data.body.data[0].workImg)
      sessionStorage.setItem("webTitle",res.data.body.data[0].webTitle)
      sessionStorage.setItem("reiviewVisible",res.data.body.data[0].reiviewVisible)
      sessionStorage.setItem("teacherVisible",res.data.body.data[0].teacherVisible)
      sessionStorage.setItem("downVisible",res.data.body.data[0].downVisible)
      sessionStorage.setItem("homeHeader",res.data.body.data[0].homeHeader === null ? "" : res.data.body.data[0].homeHeader)
      sessionStorage.setItem("categoryHeader",res.data.body.data[0].categoryHeader  === null ? "" : res.data.body.data[0].categoryHeader)
      sessionStorage.setItem("courseHeader",res.data.body.data[0].courseHeader  === null ? "" : res.data.body.data[0].courseHeader)
      sessionStorage.setItem("workHeader",res.data.body.data[0].workHeader  === null ? "" : res.data.body.data[0].workHeader)
      sessionStorage.setItem("teacherHeader",res.data.body.data[0].teacherHeader  === null ? "" : res.data.body.data[0].teacherHeader)
      sessionStorage.setItem("postHeader",res.data.body.data[0].postHeader  === null ? "" : res.data.body.data[0].postHeader)
      sessionStorage.setItem("reviewHeader",res.data.body.data[0].reiviewHeader  === null ? "" : res.data.body.data[0].reiviewHeader)
      sessionStorage.setItem("downHeader",res.data.body.data[0].downHeader  === null ? "" : res.data.body.data[0].downHeader)


      sessionStorage.setItem("banner1Url",res.data.body.data[0].banner1Url === null ? "" : res.data.body.data[0].banner1Url)
      sessionStorage.setItem("banner1Visible",res.data.body.data[0].banner1Visible  === null ? "0" : res.data.body.data[0].banner1Visible)
      sessionStorage.setItem("banner1Contents",res.data.body.data[0].banner1Contents  === null ? "" : res.data.body.data[0].banner1Contents)
      sessionStorage.setItem("banner1Counter",res.data.body.data[0].banner1Counter  === null ? "" : res.data.body.data[0].banner1Counter)
      sessionStorage.setItem("banner1Type",res.data.body.data[0].banner1Type  === null ? "" : res.data.body.data[0].banner1Type)
      sessionStorage.setItem("banner2Url",res.data.body.data[0].banner2Url === null ? "" : res.data.body.data[0].banner2Url)
      sessionStorage.setItem("banner2Visible",res.data.body.data[0].banner2Visible  === null ? "0" : res.data.body.data[0].banner2Visible)
      sessionStorage.setItem("banner2Contents",res.data.body.data[0].banner2Contents  === null ? "" : res.data.body.data[0].banner2Contents)
      sessionStorage.setItem("banner2Counter",res.data.body.data[0].banner2Counter  === null ? "" : res.data.body.data[0].banner2Counter)
      sessionStorage.setItem("banner2Type",res.data.body.data[0].banner2Type  === null ? "" : res.data.body.data[0].banner2Type)
      sessionStorage.setItem("banner3Url",res.data.body.data[0].banner3Url === null ? "" : res.data.body.data[0].banner3Url)
      sessionStorage.setItem("banner3Visible",res.data.body.data[0].banner3Visible  === null ? "0" : res.data.body.data[0].banner3Visible)
      sessionStorage.setItem("banner3Contents",res.data.body.data[0].banner3Contents  === null ? "" : res.data.body.data[0].banner3Contents)
      sessionStorage.setItem("banner3Counter",res.data.body.data[0].banner3Counter  === null ? "" : res.data.body.data[0].banner3Counter)
      sessionStorage.setItem("banner3Type",res.data.body.data[0].banner3Type  === null ? "" : res.data.body.data[0].banner3Type)
      sessionStorage.setItem("kakaoUrl",res.data.body.data[0].kakaoUrl  === null ? "" : res.data.body.data[0].kakaoUrl)
      sessionStorage.setItem("kakaoVisible",res.data.body.data[0].kakaoVisible  === null ? "0" : res.data.body.data[0].kakaoVisible)
      sessionStorage.setItem("facebookUrl",res.data.body.data[0].facebookUrl  === null ? "" : res.data.body.data[0].facebookUrl)
      sessionStorage.setItem("facebookVisible",res.data.body.data[0].facebookVisible  === null ? "0" : res.data.body.data[0].facebookVisible)
      sessionStorage.setItem("lineUrl",res.data.body.data[0].lineUrl  === null ? "" : res.data.body.data[0].lineUrl)
      sessionStorage.setItem("lineVisible",res.data.body.data[0].lineVisible  === null ? "0" : res.data.body.data[0].lineVisible)
      sessionStorage.setItem("instarUrl",res.data.body.data[0].instarUrl  === null ? "" : res.data.body.data[0].instarUrl)
      sessionStorage.setItem("instarVisible",res.data.body.data[0].instarVisible  === null ? "0" : res.data.body.data[0].instarVisible)
      sessionStorage.setItem("bandUrl",res.data.body.data[0].bandUrl  === null ? "" : res.data.body.data[0].bandUrl)
      sessionStorage.setItem("bandVisible",res.data.body.data[0].bandVisible  === null ? "0" : res.data.body.data[0].bandVisible)
      sessionStorage.setItem("xUrl",res.data.body.data[0].xUrl  === null ? "" : res.data.body.data[0].xUrl)
      sessionStorage.setItem("xVisible",res.data.body.data[0].xVisible  === null ? "0" : res.data.body.data[0].xVisible)
      let link :any = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = res.data.body.data[0].faviconImg === null ? "/public/favicon/Symbol.png":res.data.body.data[0].faviconImg;
      if( sessionStorage.getItem("refresh") === null){
        sessionStorage.setItem("refresh","1")
  
        window.location.reload()
  
      }
  
    })

  },[])


  // const { mutate: mutateDesign} = useMutation((params : DesignDto) => fetcherDesign(params), {
  //   onSuccess: (e) => {
  //     // console.log(e)
  //     if (e.data.header.isSuccess) {
        
  //       console.log(e.data.body.data)
  //       // setDesign(e.data.body.data)
  //     } else {
  //       // message.error(e.data.header.resultMessage);
  //     }
  //   },
  //   onError: (e) => {
  //     console.log("login onError : ", e);
  //   },
  // });


  return (
    <HelmetProvider>
    
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SettingsProvider>
          {/* <BrowserRouter basename='/vod'> */}
          <BrowserRouter>
            <ScrollToTop />
            <QueryClientProvider client={queryClient} contextSharing>

            <ThemeProvider>
              <ThemeSettings>
                <MotionLazyContainer>
                  <Router />
                </MotionLazyContainer>
              </ThemeSettings>
            </ThemeProvider>
            <ToastContainer />
            </QueryClientProvider>

          </BrowserRouter>
        </SettingsProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}
