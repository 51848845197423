import { useLocation } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import Masonry from '@mui/lab/Masonry';
import {
  Link,
  Stack,
  Button,
  Divider,
  Container,
  TextField,
  Typography,
  IconButton,
  StackProps,
  InputAdornment,
  Unstable_Grid2 as Grid,
} from '@mui/material';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// _mock
import { _socials } from 'src/_mock';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
//
import { pageLinks, navConfig } from '../nav/config-navigation';
import ListDesktop from './ListDesktop';
import ListMobile from './ListMobile';

// ----------------------------------------------------------------------

const StyledAppStoreButton = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  padding: '5px 12px',
  margin: theme.spacing(1),
  color: theme.palette.common.white,
  border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
  background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
  '& .MuiButton-startIcon': {
    marginLeft: 0,
  },
}));

// ----------------------------------------------------------------------

export default function Footer() {
       /* eslint-disable */
  const isMdUp = useResponsive('up', 'md');

  const { pathname } = useLocation();

  const mobileList = navConfig.find((i) => i.title === 'Pages')?.children || [];

  const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

  const renderLists = isMdUp ? desktopList : mobileList;

  const isHome = pathname === '/';

  const simpleFooter = (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      <Logo single />

      <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
        © 2023. All rights reserved
      </Typography>
    </Container>
  );

  const mainFooter = (
    <>
      <Divider />

      <Container
        sx={{
          overflow: 'hidden',
          py: { xs: 5, md: 5 },
        }}
      >
        <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
          <Grid xs={12} md={4}>
            <Stack spacing={{ xs: 3, md: 5 }}>
              <Stack alignItems="flex-start" spacing={3}>
                <Logo />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>

                <div dangerouslySetInnerHTML={{ __html: sessionStorage.getItem("footer") as string }} style={{lineHeight :1}}/>
                  {/* <div style={{display:"flex" ,flexDirection:"column" ,gap : 5}}>

                  <div style={{fontSize : 12 }}>
                    <span>상호명 : 리디안랩스 주식회사</span>
                    <span>&nbsp; | &nbsp;</span>
                    <span>대표 : 김경일</span>
                  </div>
                  <div style={{fontSize : 12}}>
                    <span>사업자등록번호 : 156-87-02452</span>           
                  </div>
                  <div style={{fontSize : 12}}>
                
                    <span>대전광역시 유성구 복용동로 43(복용동, 도안더리브시그니처) F748호</span>
                  </div>
                  <div style={{display :"flex", flexDirection:"column" ,marginTop : 10 , fontSize : 12}}>
                
                  <span>본사 : 042-825-0106</span>
                 
                  </div>
                  <div style={{display :"flex", flexDirection:"column" ,marginTop : 10, fontSize : 12}}>
                  <span>제휴문의 : admin@lydianlabs.kr</span>
                  <span>고객지원 : support@lydianlabs.kr</span>
                  <span>도입문의 : sales@lydianlabs.kr</span>
                  </div>
                  <div style={{display :"flex", flexDirection:"column" ,marginTop : 10, fontSize : 12}}>
                  <span>통신판매업 신고번호 : 2022-대전유성-0744</span>
                  <span>통신판매업 신고기관 : 대전광역시 유성구청</span>
                  <span>호스팅서비스 사업자 : NHN Cloud</span>
                  </div>
                  </div>
                  <span style={{marginTop : 5}}>
                  Copyright ⓒLydianlabs Corp. All Rights Reserved

                  </span> */}

                </Typography>
              </Stack>

              {/* <Stack spacing={1} alignItems="flex-start">
                <Typography variant="h6">Community</Typography>
                <Link variant="body2" sx={{ color: 'text.primary' }}>
                  Documentation
                </Link>
                <Link variant="body2" sx={{ color: 'text.primary' }}>
                  Changelog
                </Link>
                <Link variant="body2" sx={{ color: 'text.primary' }}>
                  Contributing
                </Link>
              </Stack> */}

              {/* <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography variant="h6">구독하기</Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    최근 소식을 받으시려면 이메일을 입력하세요.
                  </Typography>
                </Stack>

                <TextField
                  fullWidth
                  hiddenLabel
                  placeholder="Email address"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button variant="contained" color="inherit" size="large">
                          Subscribe
                        </Button>
                      </InputAdornment>
                    ),
                    sx: { pr: 0.5 },
                  }}
                />
              </Stack> */}

              {/* <Stack spacing={2}>
                <Typography variant="h6">Social</Typography>
                <Stack direction="row" alignItems="center">
                  {_socials.map((social) => (
                    <IconButton key={social.value} color="primary">
                      <Iconify icon={social.icon} />
                    </IconButton>
                  ))}
                </Stack>
              </Stack> */}

              {/* <Stack spacing={2}>
                <Typography variant="h6">Apps</Typography>
                <AppStoreButton />
              </Stack> */}
            </Stack>
          </Grid>

          <Grid xs={12} md={6}>
            {isMdUp ? (
              <Masonry columns={4} spacing={2} defaultColumns={4} defaultSpacing={2}>
                {renderLists.map((list) => (
                  <ListDesktop key={list.subheader} list={list} />
                ))}
              </Masonry>
            ) : (
              <Stack spacing={1.5}>
                {renderLists.map((list) => (
                  <ListMobile key={list.subheader} list={list} />
                ))}
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
      <Divider />
      <Container>
        <Stack
          spacing={2.5}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{ py: 3}}
        >

          <Stack direction="row" spacing={3} justifyContent="left">
           
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          호스팅서비스제공 : 리디안랩스(주) {<br/>}
리디안랩스(주)는 통신판매의 당사자가 아니며, 상품 및 서비스의 정보, 거래조건, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.
          </Typography>
          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center">
            <Link variant="caption" sx={{ color: 'text.secondary' }} href='https://lydianlabs.notion.site/cd8adc322e6847648614ed45076043df?pvs=4' target='_blank'>
            이용약관
            </Link>

            <Link variant="caption" sx={{ color: 'text.secondary' }} href='https://lydianlabs.notion.site/a3a14713b8b9414eaa9e2478d0edaac3?pvs=4' target='_blank'>
            개인정보처리방침
            </Link>
          </Stack>
        </Stack>
      </Container>
    </>
  );

  return <footer>{isHome ? mainFooter : mainFooter}</footer>;
}

// ----------------------------------------------------------------------

function AppStoreButton({ ...other }: StackProps) {
  return (
    <Stack direction="row" flexWrap="wrap" {...other}>
      <StyledAppStoreButton startIcon={<Iconify icon="ri:apple-fill" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download on the
          </Typography>

          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Apple Store
          </Typography>
        </Stack>
      </StyledAppStoreButton>

      <StyledAppStoreButton startIcon={<Iconify icon="logos:google-play-icon" width={28} />}>
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            Download from
          </Typography>
          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Google Play
          </Typography>
        </Stack>
      </StyledAppStoreButton>
    </Stack>
  );
}
