import { Helmet } from 'react-helmet-async';
// sections
import ElearningQuizReviewView from 'src/sections/_e-learning/view/ElearningQuizReviewView';
// ----------------------------------------------------------------------

export default function ElearningMyCourseReviewQuizPage() {
  return (
    <>
      <Helmet>
        <title>퀴즈 결과 | {sessionStorage.getItem("webTitle")}</title>
      </Helmet>

      <ElearningQuizReviewView />
    </>
  );
}
