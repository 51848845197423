import * as React from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import {
  Divider,
  Stack,
  Card,
  Typography,
  Box,
  Link,
  Avatar,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
// routes
import { paths } from 'src/routes/paths';
// utils
import { fCurrency, fShortenNumber } from 'src/utils/formatNumber';
// types
import { ICourseProps } from 'src/types/course';
// components
import { useMutation } from '@tanstack/react-query';

import Image from 'src/components/image';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { BsCart, BsCartFill } from 'react-icons/bs';
import { privateApi } from 'src/react-query';
import { CreateWishlistDto } from 'src/generated';
import Timer from 'src/components/timer/Timer';

// ----------------------------------------------------------------------

type Props = {
  course: any;
  vertical?: boolean;
  length?: number;
  simClicked?: any;
};
const fetcherMyWish = () => privateApi.vod.wpVodControllerGetWishlist();
const fetcherAddMyWish = (params: CreateWishlistDto) =>
  privateApi.vod.wpVodControllerCreateWishlist(params);
const fetcherDelMyWish = (vodProductIdx: string) =>
  privateApi.vod.wpVodControllerDeleteWishlist(vodProductIdx);

export default function ElearningCourseItem({ course, vertical, length, simClicked ,from ="Product" ,teacherIdx ,teacherName, photo}: any) {
  /* eslint-disable */
  const [open, setOpen] = React.useState(false);
  const [Message, setMessage] = React.useState('');
  const [open2, setOpen2] = React.useState(false);
  const [Check, setCheck] = React.useState<any>();
  const [DateValue, setDateValue] = useState<any[]>([]);

  const navigate = useNavigate();
  const {
    id,
    slug,
    level,
    price,
    ratings,
    reviews,
    teachers,
    students,
    coverImg,
    category,
    priceSale,
    bestSeller,
    totalHours,
    usetimer,
    time,
    description,
    isLive,
  } = course;
  const [wishList, setwishList] = useState<any[]>([]);

  const { mutate: mutateMyWish } = useMutation(() => fetcherMyWish(), {
    onSuccess: (e) => {
      // console.log(e)
      let list: any = [];
      if (e.data.header.isSuccess) {
        console.log(e.data.body.data);

        e.data.body.data.map((v: any) => {
          list.push(v.vodProductIdx);
        });
        console.log(list);
        setwishList(list);
      } else {
        if (e.data.header.resultCode == 403) {
          navigate('/auth/duplicated');
        }
        // message.error(e.data.header.resultMessage);
      }
    },
    onError: (e) => {
      console.log('login onError : ', e);
    },
  });
  const { mutate: AddWishList } = useMutation(
    (params: CreateWishlistDto) => fetcherAddMyWish(params),
    {
      onSuccess: (e) => {
        // console.log(e)
        let list: any = [];
        if (e.data.header.isSuccess) {
          console.log(e.data);
          setMessage('추가되었습니다.');
          setOpen(true);
          mutateMyWish();
        } else {
          if (e.data.header.resultCode == 403) {
            if (
              e.data.header.resultMessage ===
              '동일한 아이디로 중복 로그인하여, 자동으로 로그아웃 되었습니다.'
            ) {
              navigate('/auth/duplicated');
            }
          }
          setMessage(
            e.data.header.resultMessage === 'Unauthorized'
              ? '로그인이 필요합니다'
              : e.data.header.resultMessage
          );
          setOpen(true);
          // message.error(e.data.header.resultMessage);
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );
  const { mutate: DelWishList } = useMutation(
    (vodProductIdx: string) => fetcherDelMyWish(vodProductIdx),
    {
      onSuccess: (e) => {
        // console.log(e)
        let list: any = [];
        if (e.data.header.isSuccess) {
          console.log(e.data);
          setMessage('제거되었습니다.');
          setOpen(true);
          mutateMyWish();
        } else {
          if (e.data.header.resultCode == 403) {
            navigate('/auth/duplicated');
          }
        }
      },
      onError: (e) => {
        console.log('login onError : ', e);
      },
    }
  );

  useEffect(() => {
    if (sessionStorage.getItem('x-at') !== null) {
      mutateMyWish();
    }
    // console.log(teachers)
  }, []);
  useEffect(() => {
    setDateValue(course.isLive?.liveWeek === null ? [] : course.isLive?.liveWeek?.split(','));

    // console.log(teachers)
  }, [course]);
  return (
    <Card
      sx={{
        display: { sm: 'flex' },
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
        ...(vertical && {
          flexDirection: 'column',
        }),
        // margin: 'auto',
        width: length === 1 ? { sm: '33%' } : length === 2 ? { sm: '70%' } : { sm: '100%' },
      }}
    >
      <Box sx={{ flexShrink: { sm: 0 }, p: 1 }}>
        <Link component={RouterLink} to={`${paths.eLearning.course}?id=${id}`} color="inherit">
          <Image
            alt={slug}
            src={coverImg === null ? '/assets/images/course/course_1.jpg' : coverImg}
            sx={{
              borderRadius: 2,
              border: '1px solid #f1f1f1',
              cursor: 'pointer',
              height: { md: 320, xs: 250 },
              objectFit: 'cover',
              width: { sm: 240, md: 320 },
              ...(vertical && {
                width: { sm: 1 },
              }),
            }}
            onClick={() => {
              console.log(id);
              // window.sessionStorage.setItem('Product', id);
              // if(window.location.pathname === "/e-learning/course"){
              //   window.location.reload()
              // }
              if (window.location.pathname === '/e-learning/course') {
                simClicked(true);
              }

              window.sessionStorage.setItem('Product', id);
            }}
          />
        </Link>
      </Box>

      {bestSeller && (
        <Label
          color="warning"
          variant="filled"
          sx={{ top: 12, left: 12, position: 'absolute', textTransform: 'uppercase' }}
        >
          Best Seller
        </Label>
      )}

      <Stack
        spacing={1}
        sx={{ p: 3 }}
        // pb: {md: vertical ? 3 : 3 }}}
        width={'100%'}
      >
        <Stack
          spacing={{
            xs: 3,
            sm: vertical ? 3 : 1,
          }}
        >
          {/* 카테고리랑 가격 */}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: { xs: -3, md: vertical ? -1.5 : 0 } }}
          >
            <Typography variant="overline" sx={{ color: 'primary.main' }}>
              {category}
            </Typography>

            {sessionStorage.getItem('Dns') !== 'oheng.learnex.kr' && (
              <Typography variant="h4">
                {priceSale > 0 && (
                  <Box
                    component="span"
                    sx={{ mr: 0.5, color: 'text.disabled', textDecoration: 'line-through' }}
                  >
                    {priceSale.toString().slice(0, -3)}원
                  </Box>
                )}
                {fCurrency(price)}
              </Typography>
            )}
          </Stack>
          {/* 제목 */}
          <Stack spacing={1}>
            <Stack sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link
                component={RouterLink}
                to={`${paths.eLearning.course}?id=${id}`}
                color="inherit"
              >
                <TextMaxLine
                  variant="h6"
                  line={1}
                  onClick={() => {
                    console.log(id);
                    if (window.location.pathname === '/e-learning/course') {
                      simClicked(true);
                    }

                    window.sessionStorage.setItem('Product', id);
                  }}
                >
                  {slug}
                </TextMaxLine>
              </Link>
              <Box display={'flex'} sx={{color:"red"}} minHeight={20}>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.isLive ? 'Live/' : ''}
                </Typography>

                <Box display={'flex'}>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {DateValue.length !== 0 ? `(` : ''}
                  </Typography>

                  {DateValue?.map((v,i) => (
                    <>
                    {i !== 0 && <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                     ,
                    </Typography>}
                    <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                      {v === '1'
                        ? '월'
                        : v === '2'
                        ? '화'
                        : v === '3'
                        ? '수'
                        : v === '4'
                        ? '목'
                        : v === '5'
                        ? '금'
                        : v === '6'
                        ? '토'
                        : '일'}
                    </Typography>
                    </>
                  ))}
                  <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {DateValue.length !== 0 ? `)` : ''}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.liveCount ? `/${isLive.liveCount}회` : ''}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.liveLimit ? `/1:${isLive.liveLimit}` : ''}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontSize: { md: 12, xs: 11 } }}>
                  {isLive.liveDuration ? `/ ${isLive.liveDuration}분` : ''}
                </Typography>
              </Box>
              {/* {
              wishList.includes(id) ?        
            <BsCartFill style={{cursor :"pointer" , color : "red"}} onClick={()=>{
              DelWishList(id)
            }}></BsCartFill>
:          
  <BsCart style={{cursor :"pointer"}}
  onClick={()=>{
    // DelWishList(id)
    AddWishList({
      vodProductIdx : Number(id)
    })
  }}
  ></BsCart>

            } */}
              {/* <AiFillHeart style={{cursor :"pointer" , color : "red"}}></AiFillHeart>
            <AiOutlineHeart style={{cursor :"pointer"}}></AiOutlineHeart> */}
            </Stack>

            {/* <TextMaxLine
              variant="body2"
              color="text.secondary"
              sx={{
                ...(vertical && {
                  display: { sm: 'none' },
                }),
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: description as string }}/>

          
            </TextMaxLine> */}
            {/* <Stack
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                boxSizing: 'inherit',
              }}
            >
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  boxSizing: 'inherit',
                }}
              >
                파이썬 기초 문법 지식을 기반으로 쉬운 예제부터 어려운 예제로 ...
              </Typography>
            </Stack> */}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Avatar sizes="12" src={teachers[0]?.picture} />

          <Typography variant="body2" sx={{ ml: 1, mr: 0.5 }}>
            {teachers[0]?.name}
          </Typography>

          {teachers?.length > 1 && (
            <Link underline="always" color="text.secondary" variant="body2">
              + {teachers?.length - 1} teachers
            </Link>
          )}
        </Stack>
        <Stack
          spacing={1.5}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          divider={<Divider orientation="vertical" sx={{ height: 20, my: 'auto' }} />}
        >
          <Stack spacing={0.5} direction="row" alignItems="center">
            <Iconify icon="carbon:star-filled" sx={{ color: 'warning.main' }} />
            <Box sx={{ typography: 'h6' }}>
              {/* {Number.isInteger(ratings) ? `${5}.0` : ratings} */}
              {ratings}
            </Box>

            {reviews && (
              <Typography variant="body2" sx={{ color: 'text.secondary', pt: 0.3 }}>
                ({fShortenNumber(reviews)} 개)
              </Typography>
            )}
          </Stack>

          {sessionStorage.getItem("Dns") as string !== "sejongedu.kr" && <Stack direction="row" sx={{ typography: 'subtitle2', pt: 0.3 }}>
            {students === 0 ? '0' : fShortenNumber(students)}
            <Box component="span" typography="body2" sx={{ ml: 0.5 }}>
              명이 수강중입니다.
            </Box>
          </Stack>}
        </Stack>

        {/* <Stack direction="row" alignItems="center">
          <Avatar src={teachers[0]?.picture} />

          <Typography variant="body2" sx={{ ml: 1, mr: 0.5 }}>
            {teachers[0]?.name}
          </Typography>

          {teachers?.length > 1 && (
            <Link underline="always" color="text.secondary" variant="body2">
              + {teachers?.length - 1} teachers
            </Link>
          )}
        </Stack> */}

        {/* <Divider
          sx={{
            borderStyle: 'dashed',
            display: { sm: 'none' },
            ...(vertical && {
              display: 'block',
            }),
          }}
        /> */}

        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 2.5 } }}
        >
          <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
            <Iconify icon="carbon:time" sx={{ mr: 1 }} /> {`${totalHours}`}
          </Stack>

          <Stack direction="row" alignItems="center" sx={{ typography: 'body2' }}>
            <Iconify
              icon={
                (level === 'Beginner' && 'carbon:skill-level-basic') ||
                (level === 'Intermediate' && 'carbon:skill-level-intermediate') ||
                'carbon:skill-level-advanced'
              }
              sx={{ mr: 1 }}
            />
            {level}
          </Stack>
        </Stack>
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ color: 'text.disabled', '& > *:not(:last-child)': { mr: 2.5 } }}
        >
          {usetimer === 1 && <Timer time={time} timeleftcheck={setCheck} label={true} />}
        </Stack>
        <Divider
          sx={{
            borderStyle: 'dashed',
            py: usetimer === 1 ? 0.5 : 2.4,
          }}
        />
        <Stack
          direction={'row'}
          sx={{ gap: 2, display: 'flex', justifyContent: 'center', pl: { md: vertical ? 1 : 0 } }}
        >
          <div style={{ width: '80%' }}>
            <Button
              disabled={usetimer === 1 && Check?.length === 0}
              size="large"
              variant="soft"
              color="success"
              style={{ width: '100%' }}
              onClick={() => {
                if (sessionStorage.getItem('x-at') !== null) {
                  window.sessionStorage.setItem('Product', id);
                  if(from === "teacher"){

                    navigate(`/e-commerce/onecheckout?teacherIdx=${teacherIdx}&teacherName=${teacherName}&photo=${photo}`);
                  }
                  else{

                    navigate('/e-commerce/onecheckout');
                  }
                } else {
                  setOpen2(true);
                  // window.sessionStorage.setItem('Product', id);
                  // sessionStorage.setItem("NextPage", '/e-commerce/onecheckout')
                }
              }}
            >
              <Typography>
                {sessionStorage.getItem('Dns') === 'oheng.learnex.kr'
                  ? '수업 참여하기'
                  : '구매하기'}
              </Typography>

              {/* {usetimer} */}
            </Button>
          </div>
          <div style={{ width: '20%' }}>
            <Button
              variant={'soft'}
              color={wishList.includes(id) ? 'primary' : 'inherit'}
              size="large"
              onClick={() => {
                wishList.includes(id)
                  ? DelWishList(id)
                  : AddWishList({
                      vodProductIdx: Number(id),
                    });
              }}
            >
              {' '}
              <BsCartFill size={18} style={{ cursor: 'pointer' }}></BsCartFill>
            </Button>
          </div>
        </Stack>
      </Stack>

      <Dialog
        fullWidth
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      >
        <DialogContent>
          <div
            style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography variant="h5">{Message}</Typography>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              if (Message === '로그인이 필요합니다') {
                navigate('/auth/login-cover');
              }
              // console.log("ss")
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        onClose={() => {
          setOpen2(false);
        }}
        open={open2}
      >
        <DialogContent>
          <div
            style={{
              height: 90,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
              // paddingTop: '50px',
            }}
          >
            <Typography variant="h5">로그인이 필요합니다</Typography>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen2(false);
              sessionStorage.setItem('history', window.location.pathname);

              navigate('/auth/login-cover');
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
