import { Helmet } from 'react-helmet-async';
// sections
import ElearningTeacherView from 'src/sections/_e-learning/view/ElearningTeacherView';

// ----------------------------------------------------------------------

export default function ElearningTeacherPage() {
  return (
    <>
      <Helmet>
        <title>강사 | {sessionStorage.getItem("webTitle")}</title>
      </Helmet>

      <ElearningTeacherView/>
    </>
  );
}
