

// ----------------------------------------------------------------------
import { Typography,Stack,Box } from '@mui/material';
import { useEffect, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';

export default function Timer({time,timeleftcheck,label} :any) {
        /* eslint-disable */

  const isMdUp = useResponsive('up', 'md');

  const calculateTimeLeft = () => {
    const difference = +new Date(time) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24))  < 10 ?  `0`+Math.floor(difference / (1000 * 60 * 60 * 24)): Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24)  < 10 ? `0`+Math.floor((difference / (1000 * 60 * 60)) % 24): Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60 ) < 10 ? `0`+Math.floor((difference / 1000 / 60) % 60 ): Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60) < 10 ? `0`+Math.floor((difference / 1000) % 60) : Math.floor((difference / 1000) % 60)
      };
    }
    else {
      timeLeft=[]
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      console.log(timeLeft)
      timeleftcheck(calculateTimeLeft())
    }, 1000);

    return () => clearTimeout(timer);
  });

  return     <>
  {/* <FlipClockCountdown to={new Date().getTime() + 24 * 3600 * 1000 + 5000} /> */}


    <Box
    color="success"
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // flexDirection: "column",
        
        width:  "100%",
        // height: '80px',
        borderRadius: '8px',
        // boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        gap: 1,
        // '&:hover': {
        //   boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 12px 40px 0 rgba(0,0,0,0.19)',
        //   // bgcolor: 'error.dark',
        // },
      }}
    >
      
      <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
        <Stack
          sx={{
            backgroundColor:timeLeft.length === 0 ? "lightgray" :"black",
            padding:isMdUp ? '4px 2px 4px 5px' :  '2px 1px 2px 5px',
            borderRadius: '4px',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
          }}
        >
          <Typography
            fontFamily="DS-Digital"
            color="#FFFFFF"
            fontWeight={'400'}
            fontSize={isMdUp ? "20px" : "13px"}
            letterSpacing={'3px'}
            align="center"
            lineHeight={"15px"}
            paddingBottom={0.3}

          >
            {timeLeft.length !== 0? timeLeft.days : "00"}
          </Typography>
        </Stack>
        {/* <Typography fontWeight={'600'} color={'#FFFFFF'} fontSize={'32px'} >
        :
      </Typography> */}
        <Typography fontWeight={'600'} color={'#000000'} fontSize={isMdUp ?"16px" :"10px"} pt={0.3}>
          일
        </Typography>
        <Stack
          sx={{
            backgroundColor:timeLeft.length === 0 ? "lightgray" :"black",
            padding:isMdUp ? '4px 2px 4px 5px' :  '2px 1px 2px 5px',
            borderRadius: '4px',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
          }}
        >
          <Typography
            fontFamily="DS-Digital"
            color="#FFFFFF"
            fontWeight={'400'}
            fontSize={isMdUp ? "20px" : "13px"}
            letterSpacing={'3px'}
            align="center"
            lineHeight={"15px"}
            paddingBottom={0.3}

          >
                          {timeLeft.length !== 0? timeLeft.hours : "00"}
                            {/* {timeLeft.hours} */}

          </Typography>
        </Stack>
        <Typography fontWeight={'600'} color={'#000000'} fontSize={isMdUp ?"16px" :"13px"}>
          :
        </Typography>
        <Stack
          sx={{
            backgroundColor:timeLeft.length === 0 ? "lightgray" :"black",
            padding:isMdUp ? '4px 2px 4px 5px' :  '2px 1px 2px 5px',
            borderRadius: '4px',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
          }}
        >
          <Typography
            fontFamily="DS-Digital"
            color="#FFFFFF"
            fontWeight={'400'}
            fontSize={isMdUp ? "20px" : "13px"}
            letterSpacing={'3px'}
            align="center"
            lineHeight={ "15px"}
            paddingBottom={0.3}

          >
                                                 {timeLeft.length !== 0? timeLeft.minutes : "00"}


          </Typography>
        </Stack>
        <Typography fontWeight={'600'} color={'black'} fontSize={isMdUp ?"16px" :"13px"}>
          :
        </Typography>
        <Stack
          sx={{
            backgroundColor:timeLeft.length === 0 ? "lightgray" :"black"
            ,
            padding:isMdUp ? '4px 2px 4px 5px' :  '2px 1px 2px 5px',
            borderRadius: '4px',
            boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25',
          }}
        >
          <Typography
            fontFamily="DS-Digital"
            color="#FFFFFF"
            fontWeight={'400'}
            fontSize={isMdUp ? "20px" : "13px"}
            letterSpacing={'3px'}
            align="center"
            lineHeight={"15px"}
            paddingBottom={0.3}
          >                              {timeLeft.length !== 0? timeLeft.seconds : "00"}

                            {/* {timeLeft.seconds} */}

          </Typography>
        </Stack>
        {label && <Typography fontWeight={'600'} color={'#000000'} fontSize={isMdUp ?"16px" :"13px"} pt={0.3}>
          남았습니다.
        </Typography>
}
     
      </Stack>
    </Box>

</>;
}