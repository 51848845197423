import { m } from 'framer-motion';
// @mui
import { Box, Button, Typography, Stack, Container } from '@mui/material';
// routes
// components
import Iconify from 'src/components/iconify';
import { MotionContainer, varBounce } from 'src/components/animate';
import { useNavigate } from 'react-router-dom';

//

// ----------------------------------------------------------------------

export default function EcommerceOrderCompletedView() {
      /* eslint-disable */
      const navigate = useNavigate();

      return (
    <>
      {/* <EcommerceHeader /> */}

      <Container
        component={MotionContainer}
        sx={{
          textAlign: 'center',
          pt: { xs: 5, md: 10 },
          pb: { xs: 10, md: 20 },
        }}
      >
        <m.div variants={varBounce().in}>
          <Box sx={{ fontSize: 128 }}>🎉</Box>
        </m.div>

        <Stack spacing={1} sx={{ my: 5 }}>
          <Typography variant="h3">답안 제출이 완료되었습니다.</Typography>

          <Typography sx={{ color: 'text.secondary' }}>
           제출된 답안은 내 강의실에서 확인 할 수 있습니다.
          </Typography>
        </Stack>
        <Box display={"flex"} justifyContent={"center"} gap={4}>

        <Button
          size="large"
          color="primary"
          variant="text"
          startIcon={<Iconify icon="carbon:chevron-left" />}
          onClick={()=>{
            navigate("/e-learning/mycourse")

          }}
        >
내 강의실로 돌아가기기
        </Button>
      
        </Box>
      </Container>
    </>
  );
}
